import { type FC } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "@/hooks/translator.hook";
import { getTerminalRoute } from "@/routes/terminal.routes";
import { type TradingAccount } from "@/services/openapi";
import { Button } from "@/shared/ui";

type Props = {
  account: TradingAccount;
};

const TradeButton: FC<Props> = ({ account }) => {
  const { t } = useTranslation();

  return (
    <Button size="sm" data-test="account-trade-btn" asChild>
      <Link to={getTerminalRoute(account.id!)}>{t("accounts.buttons.trade")}</Link>
    </Button>
  );
};

export { TradeButton };
