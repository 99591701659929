import type { FieldValues, UseFormProps } from "react-hook-form";
import { useForm } from "react-hook-form";

/**
 * @deprecated
 */
export const useHookForm = <TFieldValues extends FieldValues = FieldValues, TContext = any>(
  props?: UseFormProps<TFieldValues, TContext>,
) => {
  return useForm({ mode: "onChange", shouldUnregister: true, ...props });
};
