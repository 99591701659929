import type { VariantProps } from "@stitches/react";
import { forwardRef } from "react";

import type { CSS } from "@/styles";
import { styled } from "@/styles/stitches.config";

const StyledButton = styled("button", {
  // Reset
  all: "unset",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
  userSelect: "none",

  boxSizing: "border-box",

  elementTransition: "background border color",

  cursor: "pointer",

  fontWeight: "$bolder",
  fontSize: "14px",
  whiteSpace: "nowrap",
  fontFamily: "$gilroy",

  borderRadius: "24px",

  variants: {
    variant: {
      primary: {
        backgroundColor: "$primary",
        color: "$lite",

        "svg path": {
          fill: "$lite",
        },

        "&:hover": {
          backgroundColor: "$positiveLight",
        },
        "&:disabled": { backgroundColor: "$dark" },
      },
      secondary: {
        backgroundColor: "$bgPrimary",
        border: "1px solid",
        borderColor: "$bgBorder",
        color: "$textMain",

        "svg path": {
          fill: "$textMain",
        },

        "&:hover": {
          borderColor: "$textMain",
        },
      },
      black: {
        backgroundColor: "$bgAdditional",
        color: "$textInverse",

        "svg path": {
          fill: "$textInverse",
          elementTransition: "fill",
        },

        "&:hover": {
          "svg path": {
            fill: "$textSecondary",
          },

          color: "$textSecondary",
        },
      },
      danger: {
        backgroundColor: "$negativeDefault",
        color: "$lite",

        "svg path": {
          fill: "$lite",
        },

        "&:hover": {
          backgroundColor: "$negativeLight",
        },
        "&:disabled": { backgroundColor: "$dark" },
      },
      text: {
        backgroundColor: "transparent",
      },
    },
    wide: {
      true: {
        width: "100%",
      },
    },
    size: {
      small: {
        paddingLeft: "24px",
        paddingRight: "24px",
        height: 48,
      },
      middle: {
        lineHeight: "$1",
        padding: "12px 30px",
        fontSize: "16px",

        "@bp2": {
          padding: "14px 40px",
          fontSize: "18px",
        },
      },
      round: {
        minWidth: "40px",
        minHeight: "40px",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },

  "&:active": {
    transform: "translateY(1px)",
  },
  "&:disabled": {
    opacity: "0.5",
    cursor: "default",
    pointerEvents: "none",
  },

  defaultVariants: {
    variant: "primary",
    size: "middle",
  },
});

export interface ExternalButtonProps
  extends VariantProps<typeof StyledButton>,
    React.ComponentProps<typeof StyledButton> {
  children: React.ReactNode;
  className?: string;
  css?: Omit<CSS, "hideScroll">;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  title?: string;
}

/**
 * @deprecated
 */
export const Button = forwardRef<React.ElementRef<typeof StyledButton>, ExternalButtonProps>(
  ({ children, type = "button", leftIcon, rightIcon, title, ...restProps }, ref) => {
    return (
      <StyledButton {...restProps} title={title} ref={ref} type={type}>
        {leftIcon && leftIcon}
        {children}
        {rightIcon && leftIcon}
      </StyledButton>
    );
  },
);
Button.displayName = "Button";
