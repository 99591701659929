import { type FC, useState } from "react";

import { HookForm, SubmitButton, useControlledField, useHookForm } from "@/app/form";
import { Leverage } from "@/entities/accounts/leverage";
import { useTranslation } from "@/hooks/translator.hook";
import { Dialog, Select } from "@/shared/ui";

enum Fields {
  VALUE = "value",
}

type FormValues = {
  [Fields.VALUE]: string;
};

type Props = {
  onSubmit: (values: FormValues) => Promise<unknown>;
  leverage: number;
  availableLeverages: number[];
  leverageAvailableDefault: number;
};

const ChangeLeverageForm: FC<Props> = ({ onSubmit, leverage, availableLeverages, leverageAvailableDefault }) => {
  const { t } = useTranslation();

  const [initialLeverage] = useState(() => {
    if (availableLeverages.includes(leverage)) {
      return leverage;
    }
    return leverageAvailableDefault;
  });
  const form = useHookForm<FormValues>({
    defaultValues: {
      [Fields.VALUE]: String(initialLeverage),
    },
  });

  const [field] = useControlledField({ name: Fields.VALUE, control: form.control });

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <Dialog.Header title={t("accounts.change-leverage.title")} />
      <Select
        value={field.value}
        onValueChange={field.onChange}
        label={t("accounts.change-leverage.leverage")}
        descriptor={t("accounts.change-leverage.descriptor")}
      >
        {availableLeverages.map(leverage => (
          <Select.Item key={leverage} value={String(leverage)}>
            <Leverage leverage={leverage} />
          </Select.Item>
        ))}
      </Select>
      <Dialog.Buttons>
        <SubmitButton>{t("accounts.change-leverage.action")}</SubmitButton>
      </Dialog.Buttons>
    </HookForm>
  );
};

export { ChangeLeverageForm };
