import { useStore } from "@nanostores/react";
import { useSearchParams } from "react-router-dom";

import { GlobalLoader } from "@/components/loader";
import { $exitDialogOpen, ExitOnboardingDialog, onExitDialog } from "@/features/onboarding/components/exit-dialog";
import { OnboardingHeader } from "@/features/onboarding/components/onboarding-header/header";
import { SumsubContainer } from "@/features/onboarding/components/sumsub/sumsub.container";
import { useKycInfoQuery } from "@/state/server/kyc";
import { useProfileData } from "@/state/server/profile/profile.hooks";

export const SumsubPage = () => {
  const profile = useProfileData();
  const { data: kycInfo } = useKycInfoQuery();
  const [searchParams] = useSearchParams();
  const from = searchParams.get("from");
  const exitDialogOpen = useStore($exitDialogOpen);

  if (!kycInfo) return <GlobalLoader />;

  return (
    <>
      <OnboardingHeader
        email={profile.email!}
        isSurveyCompleted={profile.options!.isSurveyCompleted!}
        isSurveyMandatory={profile.featuresInfo!.mandatorySurvey!}
        from={from}
      />
      <SumsubContainer sumSubInfo={kycInfo.sumSub!} from={from} />
      <ExitOnboardingDialog open={exitDialogOpen} onOpenChange={onExitDialog} />
    </>
  );
};
