import { type ComponentProps, type FC, useCallback } from "react";

import type { TwoFAConfirmInfo, TwoFAStep } from "@/features/two-factor/container";
import { VerifyCodeForm } from "@/features/two-factor/verify-code/form";
import { useVerifyCodeData } from "@/features/two-factor/verify-code/helpers";
import { UserOneTimeCodeType } from "@/services/openapi";
import { Dialog } from "@/shared/ui";
import { useCreateTwoFAMutation } from "@/state/server/auth";
import { useRequestOneTimeCodeMutation, useVerifyOneTimeCodeMutation } from "@/state/server/ot-codes";

type Props = {
  email: string;
  setStep: (step: TwoFAStep) => void;
  setTwoFactorData: (data: TwoFAConfirmInfo) => void;
};

const Enable2FAVerifyContainer: FC<Props> = ({ setStep, setTwoFactorData, email }) => {
  const { countdownActive, isCodeSent, remainingTime, setIsCodeSent, setNextRequestDate } = useVerifyCodeData();

  const { mutateAsync: createTwoFa, isLoading: createLoading } = useCreateTwoFAMutation();
  const { mutate: sendCode, isLoading: requestLoading } = useRequestOneTimeCodeMutation();
  const { mutate: verifyCode, isLoading: verifyLoading } = useVerifyOneTimeCodeMutation();

  const handleCreate = useCallback(
    (token: string) => {
      createTwoFa(undefined, {
        onSuccess: ({ authenticatorUri, sharedKey }) => {
          setTwoFactorData({ oneTimeToken: token, sharedKey, authenticatorUri });
          setStep("confirm");
        },
      });
    },
    [setStep, setTwoFactorData, createTwoFa],
  );

  const handleSend = useCallback(() => {
    sendCode(
      { type: UserOneTimeCodeType.TwoFactorEnable },
      {
        onSuccess: ({ dateNextRequest }) => {
          setNextRequestDate(dateNextRequest!);
          setIsCodeSent(true);
        },
      },
    );
  }, [sendCode, setIsCodeSent, setNextRequestDate]);

  const handleSubmit: ComponentProps<typeof VerifyCodeForm>["onSubmit"] = useCallback(
    ({ code }) => {
      return verifyCode(
        { type: UserOneTimeCodeType.TwoFactorEnable, stringContainer: { value: code } },
        {
          onSuccess: ({ token }) => {
            handleCreate(token!);
          },
        },
      );
    },
    [handleCreate, verifyCode],
  );

  return (
    <Dialog.Content>
      <VerifyCodeForm
        onSubmit={handleSubmit}
        onSend={handleSend}
        isLoading={createLoading || requestLoading || verifyLoading}
        remainingTime={remainingTime}
        email={email}
        countdownActive={countdownActive}
        isCodeSent={isCodeSent}
      />
    </Dialog.Content>
  );
};

export { Enable2FAVerifyContainer };
