import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { HookForm, useControlledField, useHookForm } from "@/app/form";
import { IconShield } from "@/domains/icons";
import { Button, Dialog, InputOTP, Text } from "@/shared/ui";

import { formatRemainingTime } from "./helpers";

enum Fields {
  code = "code",
}

type FormValues = {
  [Fields.code]: string;
};

type Props = {
  onSubmit: (values: FormValues) => void;
  onSend: () => void;
  email: string;
  isLoading: boolean;
  remainingTime: number;
  countdownActive: boolean;
  isCodeSent: boolean;
};

const VerifyCodeForm: FC<Props> = ({
  onSubmit,
  onSend,
  isLoading,
  email,
  remainingTime,
  countdownActive,
  isCodeSent,
}) => {
  const { t } = useTranslation();

  const form = useHookForm({
    defaultValues: {
      [Fields.code]: "",
    },
  });

  const { control } = form;

  const [field, { invalid, errorMessage }] = useControlledField({
    name: Fields.code,
    control,
  });

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <Dialog.Header
        title={isCodeSent ? t("profile-settings.verify-code.sent-title") : t("profile-settings.verify-code.title")}
        centered
        image={
          <Dialog.Image type="icon">
            <Dialog.Icon type="positive">
              <IconShield />
            </Dialog.Icon>
          </Dialog.Image>
        }
      />
      <div className="flex flex-col gap-6">
        <Text color="secondary" variant="S / Regular" align="center">
          {isCodeSent
            ? t("profile-settings.verify-code.sent-description", { email })
            : t("profile-settings.verify-code.description", { email })}
        </Text>
        <InputOTP
          maxLength={6}
          invalid={invalid}
          onComplete={form.handleSubmit(onSubmit)}
          errorMessage={errorMessage}
          {...field}
        />
      </div>
      <Dialog.Buttons>
        <Button pending={isLoading} onClick={onSend} disabled={countdownActive}>
          {t("profile-settings.verify-code.action")}
        </Button>
      </Dialog.Buttons>
      {remainingTime > 0 && (
        <Text color="secondary" align="center" variant="S / Regular" className="mt-4">
          {t("profile-settings.verify-code.send-again", { time: formatRemainingTime(remainingTime) })}
        </Text>
      )}
    </HookForm>
  );
};

export { VerifyCodeForm };
