import { useCallback, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useCookie } from "@/hooks/cookie.hook";

export const analyticsUtmProperty = "Analytics-Utm";
export const analyticsClickProperty = "Analytics-Click";
export const analyticsSubIdProperty = "Analytics-Sub-Id";

export const useMarketingCookies = () => {
  const [, setCookie] = useCookie();
  const [searchParams] = useSearchParams();

  const setMarketingCookies = useCallback(() => {
    const url = window.location.href;
    const utmRegex = /(utm_[^&=]+=[^&]+)+/g;
    const utmResult = url.match(utmRegex);
    const clickIdRegex = /click_id=([^&]+)/;
    const clickIdResult = url.match(clickIdRegex);
    const subId = searchParams.get("subID");

    if (utmResult !== null) {
      setCookie(analyticsUtmProperty, "&" + utmResult.join("&"));
    }

    if (clickIdResult !== null) {
      setCookie(analyticsClickProperty, "&" + clickIdResult[0]);
    }

    if (subId) {
      setCookie(analyticsSubIdProperty, subId);
    }
  }, []);

  useEffect(() => {
    setMarketingCookies();
  }, []);
};
