import type { FC } from "react";

import { AccountCard } from "@/entities/accounts/card";
import { CreateAccountButton } from "@/entities/accounts/create-account/button";
import { DemoAccountCard } from "@/entities/accounts/demo/card";
import { useActiveAccountContext } from "@/layouts/cabinet/active-account.context";
import { AvailabilityStatus, TradingAccountSorting, TradingAccountType } from "@/services/openapi";
import { accountsQueryKeys, useAllAccountsQuery } from "@/state/server/accounts";

import { AccountsCardsWrapper } from "../cards-wrapper";
import { useUpdateAccounts } from "../helpers";

const DemoCardsContainer: FC = () => {
  const { data } = useAllAccountsQuery({
    type: TradingAccountType.Demo,
    status: AvailabilityStatus.Active,
    sorting: TradingAccountSorting.Oldest,
  });

  useUpdateAccounts(
    accountsQueryKeys.accounts({
      type: TradingAccountType.Demo,
      status: AvailabilityStatus.Active,
      sorting: TradingAccountSorting.Oldest,
    }),
  );

  const { activeAccount } = useActiveAccountContext();

  return (
    <AccountsCardsWrapper
      cards={data}
      skeleton={<AccountCard.Skeleton />}
      button={<CreateAccountButton accountType={TradingAccountType.Demo} />}
    >
      {card => (
        <DemoAccountCard key={card.id} account={card} isActive={activeAccount && activeAccount.id === card.id} />
      )}
    </AccountsCardsWrapper>
  );
};

export { DemoCardsContainer };
