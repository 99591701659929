import { type FC } from "react";

import { Skeleton } from "@/shared/ui";

const AccountSummarySkeleton: FC = () => (
  <div className="flex h-7 items-center justify-end gap-4 bg-surface-canvas px-4">
    <Skeleton className="h-3 w-[120px] rounded-sm" />
    <Skeleton className="h-3 w-[120px] rounded-sm" />
    <Skeleton className="h-3 w-[120px] rounded-sm" />
    <Skeleton className="h-3 w-[120px] rounded-sm" />
    <Skeleton className="h-3 w-[120px] rounded-sm" />
  </div>
);

export { AccountSummarySkeleton };
