import { type FC, type ReactNode, useEffect } from "react";

import { socketClient } from "@/services/websocket";

type Props = {
  children: ReactNode;
};

const Cabinet: FC<Props> = ({ children }) => {
  useEffect(() => {
    socketClient.subscribeAccountsBalances();

    return () => {
      socketClient.unsubscribeAccountsBalances();
    };
  }, []);

  return children as JSX.Element;
};

export { Cabinet };
