import dayjs from "dayjs";
import type { FC } from "react";

import { TextBad } from "@/components/text";
import { TransactionStatusBadge } from "@/features/transactions/components/transaction-status-badge";
import {
  getTransactionAmount,
  getTransactionTypeIcon,
} from "@/features/transactions/components/transactions-table/transaction-table-row";
import type { FundsTx } from "@/services/openapi";

type Props = {
  transaction: FundsTx;
  simplify?: boolean;
};

export const TransactionCard: FC<Props> = ({ transaction, simplify }) => {
  const { date, description, status, amounts, type, typeTitle } = transaction;
  return (
    <div className="flex flex-col gap-2 rounded-sm border border-bg-border p-4">
      <div className="flex items-center justify-between">
        <div className="flex">
          <div className="flex items-center gap-2">
            {getTransactionTypeIcon(type!)}
            <TextBad family="roboto" size="1">
              {simplify ? type : typeTitle}
            </TextBad>
          </div>
        </div>
        <TextBad family="roboto" size="1">
          {dayjs(date).format("DD.MM.YYYY HH.mm")}
        </TextBad>
      </div>
      <TextBad className="hidden md:block" family="roboto">
        {description}
      </TextBad>
      <div className="flex items-center justify-between">
        <TextBad weight="bold">{getTransactionAmount(amounts!)}</TextBad>
        {!simplify && <TransactionStatusBadge status={status!} size="small" />}
      </div>
    </div>
  );
};
