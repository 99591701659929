import type { FC } from "react";
import { useCallback } from "react";
import type { FieldErrors } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { HookForm, SubmitButton } from "@/app/form";
import type { AnyObjectType } from "@/constants/types";
import { IconArrowLeft } from "@/domains/icons";
import { createSurveyRequestItems } from "@/features/onboarding/components/survey/survey.helpers";
import { SurveyField } from "@/features/onboarding/components/survey/survey-field";
import { useHookForm } from "@/hooks/form.hook";
import type { SurveyGroup } from "@/services/openapi";
import { Button } from "@/shared/ui";
import { useUpdateSurveyMutation } from "@/state/server/kyc";

import { StepWrapper } from "../step-wrapper";

type Props = {
  surveyGroup: SurveyGroup;
  isLastStep: boolean;
  onSubmit: () => void;
  onBack: () => void;
};

const SurveyContainer: FC<Props> = ({ surveyGroup, isLastStep, onSubmit: onSubmitProps, onBack }) => {
  const { t } = useTranslation();
  const { mutateAsync: updateSurvey } = useUpdateSurveyMutation();
  const form = useHookForm();

  const onSubmit = useCallback(
    (values: AnyObjectType) => {
      return updateSurvey(
        {
          surveyUpdate: {
            surveyGroupId: surveyGroup.id!,
            items: createSurveyRequestItems(values),
          },
        },
        {
          onSuccess: () => {
            onSubmitProps();
          },
        },
      );
    },
    [updateSurvey, onSubmitProps, surveyGroup.id],
  );

  const onInvalid = useCallback((errors: FieldErrors) => {
    const allErrorsKeys = Object.keys(errors);

    document.querySelector(`[name="${allErrorsKeys[0]}"]`)?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <StepWrapper title={surveyGroup.title!} subtitle={surveyGroup.description!}>
      <HookForm form={form} onSubmit={onSubmit} onInvalid={onInvalid} className="flex	h-full flex-col">
        <div className="grid gap-5 whitespace-pre-line">
          {surveyGroup.fields!.map(field => (
            <SurveyField field={field} key={field.id} />
          ))}
        </div>

        <StepWrapper.Footer>
          <Button variant="flat" onClick={onBack} startSection={<IconArrowLeft />} endSection={null}>
            {t("button.back")}
          </Button>
          <div className="w-[200px]">
            <SubmitButton fullWidth>{isLastStep ? t("button.start-trading") : t("button.next")}</SubmitButton>
          </div>
        </StepWrapper.Footer>
      </HookForm>
    </StepWrapper>
  );
};

export { SurveyContainer };
