import type { FC } from "react";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { IconCross } from "@/domains/icons";
import { type TerminalSymbolSignal, TradingCentralSignalType } from "@/services/openapi";
import { cn } from "@/shared/styles";
import { centeredDialogContentStyles, Dialog, OldButton } from "@/shared/ui";
import { IconTrendSignals } from "@/shared/ui/icons";

import { useSignalContext } from "../signal/context";
import { SignalTextFormat } from "../signal/text-format";

type Props = {
  signal: TerminalSymbolSignal;
  symbol: string;
  isSignalChartDialogShown: ReturnType<typeof useSignalContext>["isSignalChartDialogShown"];
  closeSignalChartDialog: ReturnType<typeof useSignalContext>["closeSignalChartDialog"];
  openSignalForm: ReturnType<typeof useSignalContext>["openSignalForm"];
};

const _ChartSignalDialog: FC<Props> = ({
  signal,
  symbol,
  closeSignalChartDialog,
  isSignalChartDialogShown,
  openSignalForm,
}) => {
  const { t } = useTranslation();

  const description = useMemo(() => {
    if (signal.type === TradingCentralSignalType.StrongUp) {
      return t("terminal.chart.signal-popover.strong-up-description", { symbol });
    }
    if (signal.type === TradingCentralSignalType.Up) {
      return t("terminal.chart.signal-popover.up-description", { symbol });
    }
    if (signal.type === TradingCentralSignalType.StrongDown) {
      return t("terminal.chart.signal-popover.strong-down-description", { symbol });
    }
    if (signal.type === TradingCentralSignalType.Down) {
      return t("terminal.chart.signal-popover.down-description", { symbol });
    }
  }, [t, signal.type, symbol]);

  return (
    <Dialog open={isSignalChartDialogShown} onOpenChange={closeSignalChartDialog} modal={false}>
      <Dialog.PrimitiveContent
        className={cn(centeredDialogContentStyles(), "absolute w-[250px] rounded-md bg-surface-elevation-2 p-3 shadow")}
      >
        <div className="flex items-center justify-between gap-1 text-contrast-primary">
          <IconTrendSignals />
          <Dialog.Title className="grow font-gilroy text-[16px] font-semibold leading-[1.2] text-contrast-primary">
            <SignalTextFormat hasAccentText type={signal.type!} />
          </Dialog.Title>
          <Dialog.Close className="outline-none">
            <IconCross />
          </Dialog.Close>
        </div>
        <Dialog.Description className="mb-3 mt-1 font-roboto text-[12px] leading-normal text-contrast-secondary">
          {description}
        </Dialog.Description>
        <Dialog.Close asChild>
          <OldButton variant="tertiary" size="sm" className="w-full" onClick={openSignalForm}>
            {t("terminal.signal.trade-button")}
          </OldButton>
        </Dialog.Close>
      </Dialog.PrimitiveContent>
    </Dialog>
  );
};

const ChartSignalDialog = memo(_ChartSignalDialog);

export { ChartSignalDialog };
