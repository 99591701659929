import { type FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { IconChevronRight, IconMail } from "@/domains/icons";
import { useVerifyCodeData } from "@/features/two-factor/verify-code/helpers";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { ActionList, Dialog } from "@/shared/ui";
import { useResendConfirmationLinkMutation } from "@/state/server/auth";

import { VerifyEmailDialogContent } from "./dialog-content";

type Props = {
  email: string;
};

const VerifyEmailButton: FC<Props> = ({ email }) => {
  const { t } = useTranslation();

  const [opened, { onOpenChange }] = useDisclosure();

  const { countdownActive, remainingTime, setIsCodeSent, setNextRequestDate } = useVerifyCodeData();

  const { mutate: sendCode, isLoading } = useResendConfirmationLinkMutation();

  const handleSend = useCallback(() => {
    sendCode(void 0, {
      onSuccess: ({ dateNextRequest }) => {
        setNextRequestDate(dateNextRequest!);
        setIsCodeSent(true);
      },
    });
  }, [sendCode, setIsCodeSent, setNextRequestDate]);

  return (
    <Dialog
      open={opened}
      onOpenChange={open => {
        if (open && !countdownActive) {
          handleSend();
        }
        onOpenChange(open);
      }}
    >
      <Dialog.Trigger asChild>
        <ActionList.Item
          title={t("profile-settings.details.verify-email")!}
          icon={<IconMail />}
          actionIcon={<IconChevronRight />}
        />
      </Dialog.Trigger>
      <Dialog.Content>
        <VerifyEmailDialogContent
          email={email}
          remainingTime={remainingTime}
          countdownActive={countdownActive}
          isLoading={isLoading}
          onSend={handleSend}
        />
      </Dialog.Content>
    </Dialog>
  );
};

export { VerifyEmailButton };
