import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { HookForm, SubmitButton, useControlledField, useHookForm } from "@/app/form";
import { IconShield } from "@/domains/icons";
import { Dialog, Text, TextNumberInput } from "@/shared/ui";

enum Fields {
  code = "code",
}

type FormValues = {
  [Fields.code]: string;
};

type Props = {
  onSubmit: (values: FormValues) => void;
};

const ChangePasswordVerify2FAForm: FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();

  const form = useHookForm({
    defaultValues: {
      [Fields.code]: "",
    },
  });

  const { control } = form;

  const [field, { invalid, errorMessage, pending }] = useControlledField({
    name: Fields.code,
    control,
    rules: {
      required: t("profile-settings.verify-two-factor.required-error")!,
    },
  });

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <Dialog.Header
        title={t("profile-settings.verify-two-factor.title")}
        centered
        image={
          <Dialog.Image type="icon">
            <Dialog.Icon type="positive">
              <IconShield />
            </Dialog.Icon>
          </Dialog.Image>
        }
      />
      <div className="flex flex-col gap-6">
        <Text color="secondary" variant="S / Regular" align="center">
          {t("profile-settings.verify-two-factor.description")}
        </Text>
        <TextNumberInput
          label={t("profile-settings.verify-two-factor.label")}
          pending={pending}
          invalid={invalid}
          descriptor={errorMessage}
          {...field}
        />
      </div>
      <Dialog.Buttons>
        <SubmitButton>{t("profile-settings.verify-two-factor.action")}</SubmitButton>
      </Dialog.Buttons>
    </HookForm>
  );
};

export { ChangePasswordVerify2FAForm };
