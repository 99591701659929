import { useStore } from "@nanostores/react";
import { produce } from "immer";
import type { FC, ReactNode } from "react";
import { createContext, useContext, useEffect } from "react";
import { useQueryClient } from "react-query";

import {
  AvailabilityStatus,
  type BonusUserPlatformItemsContainer,
  type TradingAccount,
  TradingAccountSorting,
  TradingServerPlatform,
} from "@/services/openapi";
import { $accountsBalancesMessage } from "@/services/websocket";
import {
  accountsQueryKeys,
  useAllAccountsQuery,
  useLastAccountQuery,
  useUpdateLastAccountMutation,
} from "@/state/server/accounts";
import { useBonusesQuery } from "@/state/server/bonuses";

type ContextProps = {
  bonuses: BonusUserPlatformItemsContainer | undefined;
  activeAccount: TradingAccount | undefined;
  accounts: TradingAccount[] | undefined;
  setAccount: (accountId: string) => void;
};

const Context = createContext<ContextProps>(undefined!);

const Provider: FC<{ children: ReactNode }> = ({ children }) => {
  const queryClient = useQueryClient();

  const accountsBalancesMessage = useStore($accountsBalancesMessage);

  const { mutate: updateLastAccount } = useUpdateLastAccountMutation();

  const setAccount = (accountId: string) => {
    updateLastAccount({ id: accountId });
  };

  const { data: bonuses } = useBonusesQuery();
  const { data: activeAccount } = useLastAccountQuery(
    {
      status: AvailabilityStatus.Active,
      platform: TradingServerPlatform.MetaTrader5,
    },
    {
      cacheTime: 0,
      staleTime: 0,
    },
  );
  const { data: accounts } = useAllAccountsQuery({
    isTerminalEnabledOnly: true,
    status: AvailabilityStatus.Active,
    sorting: TradingAccountSorting.Newest,
  });

  useEffect(() => {
    if (!accountsBalancesMessage || !activeAccount) return;

    const accountUpdates = accountsBalancesMessage.a!.find(({ ai }) => ai === activeAccount.id);

    if (accountUpdates) {
      queryClient.setQueryData<TradingAccount>(
        accountsQueryKeys.last({
          status: AvailabilityStatus.Active,
          platform: TradingServerPlatform.MetaTrader5,
        }),
        oldData => {
          return produce(oldData, draft => {
            if (draft) {
              draft.balance = accountUpdates.b;
              draft.credit = accountUpdates.c;
              draft.equity = accountUpdates.e;
              draft.pnL = accountUpdates.p;
              draft.margin = accountUpdates.m;
              draft.marginFree = accountUpdates.mf;
              draft.marginLevel = accountUpdates.ml;
              draft.availableToWithdraw = accountUpdates.aw;
              draft.attributes!.hasOpenPositions = accountUpdates.op;
            }
          })!;
        },
      );
    }
  }, [accountsBalancesMessage]);

  return <Context.Provider value={{ accounts, activeAccount, bonuses, setAccount }}>{children}</Context.Provider>;
};

Provider.displayName = "ActiveAccountContextProvider";

const useActiveAccountContext = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("useActiveAccountContext must be used within a ActiveAccountContextProvider");
  }

  return context;
};

export { Provider as ActiveAccountContextProvider, useActiveAccountContext };
