import { Navigate, useParams, useSearchParams } from "react-router-dom";

import { CabinetPage } from "@/layouts/cabinet/page";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { TradingAccountStatus, TradingAccountType } from "@/services/openapi";
import { useAccountQuery } from "@/state/server/accounts";
import { useWithdrawMethodQuery } from "@/state/server/payment";

import { WithdrawalOptionsForm } from "./components/form/withdrawal-options.form";

const WithdrawalOptionsPage = () => {
  const { id: currentAccountId } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();

  const { data: account } = useAccountQuery(currentAccountId!);
  const { data: paymentMethodsData } = useWithdrawMethodQuery({ accountId: account?.id }, { enabled: !!account });

  const from = searchParams.get("from");

  if (!account || !paymentMethodsData) {
    return <CabinetPage.Loader />;
  }

  if (account.type !== TradingAccountType.Real || account.status !== TradingAccountStatus.Active) {
    return <Navigate to={cabinetRoutes.withdrawal} replace />;
  }

  return <WithdrawalOptionsForm currentAccount={account} paymentMethodsData={paymentMethodsData} from={from} />;
};

export { WithdrawalOptionsPage };
