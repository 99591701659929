import type { FC } from "react";
import { Link } from "react-router-dom";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { getVerificationRoute } from "@/features/onboarding/helpers";
import { useTranslation } from "@/hooks/translator.hook";
import { ExternalKycStatus } from "@/services/openapi";
import { Banner } from "@/shared/ui";

import img from "./assets/verify-promo.png";

type Props = {
  isSurveyCompleted: boolean;
  kycStatus: ExternalKycStatus;
};

const CompleteVerificationBanner: FC<Props> = ({ isSurveyCompleted, kycStatus }) => {
  const { t } = useTranslation();
  const verificationRoute = getVerificationRoute(isSurveyCompleted);

  return (
    <Banner
      text={t("banners.verify.text")}
      bonusImg={img}
      actionButton={
        <Banner.ActionButton onClick={() => track(amplitudeEvents.verify.bannerClick)} asChild>
          {/* // TODO: change text on kyc interrupted */}
          <Link to={verificationRoute}>
            {/* {kycStatus === ExternalKycStatus.Approved ? t("banners.verify.continue") : t("banners.verify.button")} */}
            {t("banners.verify.button")}
          </Link>
        </Banner.ActionButton>
      }
    />
  );
};

export { CompleteVerificationBanner };
