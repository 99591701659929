import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, Link } from "react-router-dom";

import { useLocationPath } from "@/app/hooks";
import { IconShield } from "@/domains/icons";
import { getVerificationRoute } from "@/features/onboarding/helpers";
import { Button, Dialog, Text } from "@/shared/ui";

type Props = {
  isSurveyCompleted: boolean;
};

const DialogContainer: FC<Props> = ({ isSurveyCompleted }) => {
  const { t } = useTranslation();
  const path = useLocationPath();
  const verificationRoute = getVerificationRoute(isSurveyCompleted);

  return (
    <>
      <Dialog.Header
        title={t("deposit.verification.dialog.mandatory.title")}
        centered
        image={
          <Dialog.Image type="icon">
            <Dialog.Icon type="positive">
              <IconShield />
            </Dialog.Icon>
          </Dialog.Image>
        }
      />
      <Text variant="S / Regular" color="primary" align="center">
        {t("deposit.verification.dialog.mandatory.description")}
      </Text>
      <Dialog.Buttons>
        <Button asChild>
          <Link to={{ pathname: verificationRoute, search: createSearchParams({ from: path }).toString() }}>
            {t("deposit.verification.dialog.button-verify")}
          </Link>
        </Button>
      </Dialog.Buttons>
    </>
  );
};

export { DialogContainer };
