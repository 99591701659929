import { type FC, useState } from "react";

import { ChangeAccountPasswordContainer } from "@/entities/accounts/change-password/container";
import { AccountMTLoginDetails } from "@/entities/accounts/mt-details/login-details";
import { type CreateTradingAccountResult } from "@/services/openapi";

import { CreateAccountResultMT5Intro } from "./intro";

type Step = "intro" | "details" | "change-password";

type Props = {
  accountResult: CreateTradingAccountResult;
  onClose: () => void;
  onCloseWithRedirect: () => void;
};

const CreateAccountResultMT5Container: FC<Props> = ({ accountResult, onClose, onCloseWithRedirect }) => {
  const [step, setStep] = useState<Step>("intro");

  return (
    <>
      {step === "intro" && (
        <CreateAccountResultMT5Intro
          accountType={accountResult.type!}
          accountId={accountResult.id!}
          onDetailsSelect={() => setStep("details")}
          onClose={onClose}
        />
      )}
      {step === "details" && (
        <AccountMTLoginDetails
          account={accountResult}
          onPasswordSelect={() => setStep("change-password")}
          type="createAccount"
          pwd={accountResult.pwd!}
        />
      )}
      {step === "change-password" && (
        <ChangeAccountPasswordContainer account={accountResult} onApply={onCloseWithRedirect} />
      )}
    </>
  );
};

export { CreateAccountResultMT5Container };
