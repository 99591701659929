import { useStore } from "@nanostores/react";
import { produce } from "immer";
import { useEffect } from "react";
import { type QueryKey, useQueryClient } from "react-query";

import type { TradingAccountItemsContainer, WebSocketsAccountBalance } from "@/services/openapi";
import { $accountsBalancesMessage } from "@/services/websocket";

const useUpdateAccounts = (queryKey: QueryKey) => {
  const queryClient = useQueryClient();

  const accountsBalancesMessage = useStore($accountsBalancesMessage);

  useEffect(() => {
    if (!accountsBalancesMessage) return;

    if (queryClient.getQueryData(queryKey)) {
      queryClient.setQueryData<TradingAccountItemsContainer>(queryKey, oldData => {
        const balances: Record<string, WebSocketsAccountBalance> = {};

        accountsBalancesMessage.a!.forEach(account => {
          balances[account.ai!] = account;
        });

        return {
          items: oldData!.items!.map(account => {
            const accountUpdates = balances[account.id!];

            if (accountUpdates) {
              return produce(account, draft => {
                draft.balance = accountUpdates.b;
                draft.credit = accountUpdates.c;
                draft.equity = accountUpdates.e;
                draft.pnL = accountUpdates.p;
                draft.margin = accountUpdates.m;
                draft.marginFree = accountUpdates.mf;
                draft.marginLevel = accountUpdates.ml;
                draft.availableToWithdraw = accountUpdates.aw;
                draft.attributes!.hasOpenPositions = accountUpdates.op;
              });
            }
            return account;
          }),
          total: oldData!.total,
        };
      });
    }
  }, [accountsBalancesMessage]);
};

export { useUpdateAccounts };
