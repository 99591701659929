import copyToClipboard from "copy-to-clipboard";
import { useCallback, useState } from "react";

import { postponeCallback } from "@/utils/helpers";

const useCopy = () => {
  const [isCopied, setIsCopied] = useState(false);

  const copy = useCallback((value: string) => {
    try {
      setIsCopied(true);
      copyToClipboard(value);
      return Promise.resolve();
    } catch (error) {
      // TODO: error handling
      console.log(error);
      return Promise.reject();
    } finally {
      postponeCallback(() => setIsCopied(false));
    }
  }, []);
  return { isCopied, copy };
};

export { useCopy };
