import { type FC, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { IconLock } from "@/domains/icons";
import { CreateAccountResultPasswordBlock } from "@/features/create-account/result/password-block";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { type TradingAccount, TradingServerPlatform } from "@/services/openapi";
import { Button, CopyInput, Dialog, ExternalLink, Text, TextInput, UnstyledButton } from "@/shared/ui";
import { usePlatformData } from "@/state/server/platform/platform.hooks";
import { getUserPlatformAndOS } from "@/utils/helpers";

import { AccountMTDetailsQRCodeBlock } from "./qr-code-block";

// FIXME:
const userPlatform = getUserPlatformAndOS().os;

type Props = {
  account: TradingAccount;
  onPasswordSelect: () => void;
} & (
  | {
      type: "createAccount";
      pwd: string | null;
    }
  | { type: "details"; pwd?: never }
);

const AccountMTLoginDetails: FC<Props> = ({ account, onPasswordSelect, type, pwd }) => {
  const { t } = useTranslation();

  const { trading } = usePlatformData();
  const { mT4, mT5 } = trading!;

  const {
    platform,
    serverAddress,
    login,
    serverTerminalAndroidUrl,
    serverTerminalIOsUrl,
    serverTerminalLinuxOsUrl,
    serverTerminalMacOsUrl,
    serverTerminalUrl,
  } = account;

  const isMT5 = platform === TradingServerPlatform.MetaTrader5;

  const version = isMT5 ? "5" : "4";

  const downloadLink = useMemo(() => {
    if (userPlatform === "Android") {
      return serverTerminalAndroidUrl;
    }
    if (userPlatform === "IOS") {
      return serverTerminalIOsUrl;
    }
    if (userPlatform === "Linux") {
      return serverTerminalLinuxOsUrl;
    }
    if (userPlatform === "MacOS") {
      return serverTerminalMacOsUrl;
    }
    if (userPlatform === "Windows") {
      return serverTerminalUrl;
    }
  }, [
    serverTerminalAndroidUrl,
    serverTerminalIOsUrl,
    serverTerminalLinuxOsUrl,
    serverTerminalMacOsUrl,
    serverTerminalUrl,
  ]);

  return (
    <>
      <Dialog.Header title={t("accounts.common.mt-login-details", { version })} />
      <div className="flex flex-col gap-4">
        <CopyInput label={t("accounts.common.server")} value={serverAddress ?? ""} />
        <CopyInput label={t("accounts.common.login")} value={String(login)} />
        {type === "createAccount" ? (
          <CopyInput label={t("accounts.common.mt-password", { version })} value={String(pwd)} />
        ) : (
          <TextInput
            label={t("accounts.common.mt-password", { version })}
            value="********"
            disabled
            endSection={
              <TextInput.IconButton disabled>
                <IconLock />
              </TextInput.IconButton>
            }
          />
        )}
        {type === "createAccount" ? (
          <CreateAccountResultPasswordBlock version={version} onPasswordSelect={onPasswordSelect} />
        ) : (
          <Text variant="S / Regular" color="secondary">
            <Trans
              i18nKey="accounts.mt-details.text"
              values={{ version }}
              components={{
                button: (
                  <UnstyledButton
                    className="typography-S-Medium rounded-sm text-contrast-primary underline outline-none focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-accent-text"
                    onClick={onPasswordSelect}
                  />
                ),
              }}
            />
          </Text>
        )}
      </div>
      <Dialog.Buttons direction="column">
        <Button asChild>
          <Link to={version === "5" ? cabinetRoutes.mt5Terminal : cabinetRoutes.mt4Terminal} target="_blank">
            {t("accounts.buttons.trade-browser")}
          </Link>
        </Button>
        {downloadLink && (
          <Button variant="secondary" asChild>
            <ExternalLink href={downloadLink}>{t("accounts.buttons.download", { version })}</ExternalLink>
          </Button>
        )}
      </Dialog.Buttons>
      {userPlatform !== "Android" && userPlatform !== "IOS" && (
        <AccountMTDetailsQRCodeBlock
          version={version}
          link={(isMT5 ? mT5!.appUniversalUrl : mT4!.appUniversalUrl) || ""}
        />
      )}
    </>
  );
};

export { AccountMTLoginDetails };
