import { useFormContext } from "react-hook-form";

/**
 * @deprecated
 */
export const useField = (name: string) => {
  const { getFieldState, formState } = useFormContext();
  const { isSubmitted, isSubmitting } = formState;

  const { isTouched, error } = getFieldState(name, formState);
  const showError = (isTouched || isSubmitted) && !!error;
  return { showError, isSubmitting, error };
};
