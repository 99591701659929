import { type FC } from "react";

import { AddFundsContainer } from "@/entities/accounts/add-funds/container";
import { Dialog } from "@/shared/ui";

import { useTerminalAccountContext } from "../../contexts/account.context";
import { useLayoutContext } from "../context";

const DemoDepositDialog: FC = () => {
  const { demoDialogOpen, closeDemoDialog } = useLayoutContext();
  const { account } = useTerminalAccountContext();

  return (
    <Dialog open={demoDialogOpen} onOpenChange={closeDemoDialog}>
      <Dialog.Content>
        <AddFundsContainer
          accountId={account.id!}
          accountCurrency={account.currency!}
          currencyDecimalScale={account.digits!}
          balance={account.balance!}
          attributes={account.attributes!}
          onClose={closeDemoDialog}
        />
      </Dialog.Content>
    </Dialog>
  );
};

export { DemoDepositDialog };
