import { Intercom } from "@intercom/messenger-js-sdk";
import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "@/contexts/auth.context";
import { useKycInfoQuery } from "@/state/server/kyc";
import { usePlatformData } from "@/state/server/platform/platform.hooks";
import { useProfileHeaderQuery } from "@/state/server/profile";

const IntercomWidget: FC = () => {
  const { i18n } = useTranslation();
  const { isAuthenticated } = useAuth();
  const { liveChatInfo } = usePlatformData();
  const { data: profile } = useProfileHeaderQuery({ enabled: isAuthenticated });
  const { data: kycData } = useKycInfoQuery({ enabled: isAuthenticated });

  const locale = useMemo(() => {
    if (liveChatInfo && liveChatInfo.groupsIds) {
      const currentLiveChatInfo = liveChatInfo.groupsIds.find(({ locale }) => locale === i18n.resolvedLanguage);

      if (currentLiveChatInfo) {
        return currentLiveChatInfo.locale!;
      }
    }
  }, [i18n.resolvedLanguage, liveChatInfo]);

  Intercom({
    app_id: import.meta.env.VITE_INTERCOM_APP_ID,
    email: profile?.email || void 0,
    user_id: profile?.id,
    name: kycData?.profile?.firstName || void 0,
    phone: profile?.phoneNumber || void 0,
    language_override: locale,
  });

  return null;
};

export { IntercomWidget };
