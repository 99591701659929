import dayjs from "dayjs";
import type { FC } from "react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, ScrollRestoration, useNavigate } from "react-router-dom";

import { logError } from "@/app/libs/sentry";
import { useClientId } from "@/hooks/client-id.hook";
import { CabinetHeader } from "@/layouts/cabinet/header";
import { CabinetPage } from "@/layouts/cabinet/page";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { type PaymentMethods, PaymentMethodsWithdrawOptions, type TradingAccount } from "@/services/openapi";
import { Dialog } from "@/shared/ui";
import { useWithdrawalMutation } from "@/state/server/payment";
import { useProfileData } from "@/state/server/profile/profile.hooks";

import { useWithdrawalInfo } from "../../withdrawal-info.hook";
import { WithdrawalErrorDialogContent } from "../dialogs/error/dialog-content";
import { WithdrawalSuccessDialogContent } from "../dialogs/success/dialog-content";
import { WithdrawalLowBalance } from "../low-balance";
import { WithdrawalNoAccounts } from "../no-accounts";
import { AdditionalFieldsStep } from "./steps/additional-fields/step";
import { AmountStepContainer } from "./steps/amount/container";
import { CheckStepContainer } from "./steps/check/container";
import { PaymentMethodStepContainer } from "./steps/payment-method/container";

type Props = {
  currentAccount: TradingAccount;
  paymentMethodsData: PaymentMethods;
  from: string | null;
};

const WithdrawalOptionsForm: FC<Props> = ({ currentAccount, paymentMethodsData, from }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [errorDialog, setErrorDialog] = useState<{ opened: boolean; errorMessage: string | null }>({
    opened: false,
    errorMessage: null,
  });
  const [successDialog, setSuccessDialog] = useState<{
    opened: boolean;
    value: string | null;
    currency: string | null;
  }>({
    opened: false,
    value: null,
    currency: null,
  });

  const profile = useProfileData();
  const clientId = useClientId();

  const { mutateAsync: withdrawal, isLoading } = useWithdrawalMutation();

  const {
    currentStep,
    currentPaymentMethod,
    additionalFieldsData,
    currentAmount,
    backFromAdditionalFields,
    backFromAmount,
    backFromCheck,
    nextFromAdditionalFields,
    nextFromAmount,
    nextFromPaymentMethod,
    setAdditionalFieldsData,
    setCurrentAmount,
    setCurrentPaymentMethod,
  } = useWithdrawalInfo();

  const onSubmit = useCallback(() => {
    return withdrawal(
      {
        accountId: currentAccount.id,
        amount: currentAmount,
        paymentMethodId: currentPaymentMethod!.id,
        deviceType: "desktop",
        recurringId: currentPaymentMethod!.recurringId!,
        withdrawRequestPayload: {
          fields: additionalFieldsData,
        },
      },
      {
        onSuccess(data) {
          if (data?.error) {
            setErrorDialog({ opened: true, errorMessage: data?.error });

            // Google Analytics
            window.dataLayer?.push({
              event: "gtm_events",
              custom_timestamp: dayjs().valueOf(),
              user_id: profile.id,
              client_id: clientId,
              ec: "attempt_withdraw",
              ea: "error",
              ga4_event_name: "attempt_withdraw",
            });

            logError(data?.error);
            return;
          }

          setSuccessDialog({ opened: true, value: currentAmount, currency: currentAccount.currency! });

          // Google Analytics
          window.dataLayer?.push({
            event: "gtm_events",
            custom_timestamp: dayjs().valueOf(),
            user_id: profile.id,
            client_id: clientId,
            ec: "attempt_withdraw",
            ea: "no_error",
            el: "no_error",
            ga4_event_name: "attempt_withdraw",
          });
        },
        onError: ({ errorMessage, code }) => {
          setErrorDialog({ opened: true, errorMessage });

          // Google Analytics
          window.dataLayer?.push({
            event: "gtm_events",
            custom_timestamp: dayjs().valueOf(),
            user_id: profile.id,
            client_id: clientId,
            ec: "attempt_withdraw",
            ea: "error",
            el: code,
            ga4_event_name: "attempt_withdraw",
          });

          logError(errorMessage);
        },
      },
    );
  }, [
    additionalFieldsData,
    clientId,
    currentAccount.currency,
    currentAccount.id,
    currentAmount,
    currentPaymentMethod,
    profile.id,
    withdrawal,
  ]);

  // check if margin is changed while withdrawal process
  if (!currentAccount.availableToWithdraw) {
    return <Navigate to={cabinetRoutes.withdrawal} />;
  }

  return (
    <>
      <ScrollRestoration key={currentStep} />
      {currentStep === "paymentMethod" &&
        (paymentMethodsData.withdrawOptions === PaymentMethodsWithdrawOptions.LowBalance ? (
          <CabinetPage
            size="sm"
            header={
              <CabinetHeader backButton={from && <CabinetHeader.BackLink to={from} />}>
                <CabinetHeader.Title>{t("cabinet.navigation.withdrawal")}</CabinetHeader.Title>
              </CabinetHeader>
            }
          >
            <WithdrawalLowBalance
              currency={currentAccount.currency!}
              balance={currentAccount.balance!}
              paymentMethods={paymentMethodsData.items!}
              minAmount={paymentMethodsData.withdrawMinNecessaryBalance!}
              decimalScale={currentAccount.digits!}
            />
          </CabinetPage>
        ) : paymentMethodsData.withdrawOptions === PaymentMethodsWithdrawOptions.ZeroBalance ? (
          <CabinetPage
            size="sm"
            header={
              <CabinetHeader backButton={from && <CabinetHeader.BackLink to={from} />}>
                <CabinetHeader.Title>{t("cabinet.navigation.withdrawal")}</CabinetHeader.Title>
              </CabinetHeader>
            }
          >
            <WithdrawalNoAccounts paymentMethods={paymentMethodsData.items!} />
          </CabinetPage>
        ) : (
          <CabinetPage
            size="sm"
            header={
              <CabinetHeader backButton={from && <CabinetHeader.BackLink to={from} />}>
                <CabinetHeader.Title>{t("withdrawal.payment-methods.title")}</CabinetHeader.Title>
              </CabinetHeader>
            }
          >
            <PaymentMethodStepContainer
              account={currentAccount}
              paymentMethodsData={paymentMethodsData}
              currentPaymentMethod={currentPaymentMethod}
              next={nextFromPaymentMethod}
              setPaymentMethod={setCurrentPaymentMethod}
            />
          </CabinetPage>
        ))}
      {currentStep === "amount" && (
        <CabinetPage
          size="sm"
          header={
            <CabinetHeader backButton={<CabinetHeader.BackButton onClick={backFromAmount} />}>
              <CabinetHeader.Title>{t("withdrawal.amount.title")}</CabinetHeader.Title>
            </CabinetHeader>
          }
        >
          <AmountStepContainer
            currentAmount={currentAmount}
            currentAccount={currentAccount}
            currentPaymentMethod={currentPaymentMethod!}
            next={nextFromAmount}
            setCurrentAmount={setCurrentAmount}
          />
        </CabinetPage>
      )}
      {currentStep === "additionalFields" && (
        <CabinetPage
          size="sm"
          header={
            <CabinetHeader backButton={<CabinetHeader.BackButton onClick={backFromAdditionalFields} />}>
              <CabinetHeader.Title>{t("withdrawal.details.title")}</CabinetHeader.Title>
            </CabinetHeader>
          }
        >
          <AdditionalFieldsStep
            currentPaymentMethod={currentPaymentMethod!}
            next={nextFromAdditionalFields}
            setAdditionalFieldsData={setAdditionalFieldsData}
          />
        </CabinetPage>
      )}
      {currentStep === "check" && (
        <CabinetPage
          size="sm"
          header={
            <CabinetHeader backButton={<CabinetHeader.BackButton onClick={backFromCheck} />}>
              <CabinetHeader.Title>{t("payments.check.title")}</CabinetHeader.Title>
            </CabinetHeader>
          }
        >
          <CheckStepContainer
            additionalFieldsData={additionalFieldsData}
            back={backFromCheck}
            currentAccount={currentAccount}
            currentAmount={currentAmount}
            currentPaymentMethod={currentPaymentMethod!}
            onSubmit={onSubmit}
            submitIsloading={isLoading}
          />
        </CabinetPage>
      )}
      <Dialog
        open={successDialog.opened}
        onOpenChange={() => {
          setSuccessDialog({ opened: false, currency: null, value: null });
          navigate(cabinetRoutes.dashboard, { replace: true });
        }}
      >
        <Dialog.Content>
          <WithdrawalSuccessDialogContent currency={successDialog.currency!} value={successDialog.value!} />
        </Dialog.Content>
      </Dialog>
      <Dialog open={errorDialog.opened} onOpenChange={() => setErrorDialog({ opened: false, errorMessage: null })}>
        <Dialog.Content>
          <WithdrawalErrorDialogContent errorMessage={errorDialog.errorMessage} />
        </Dialog.Content>
      </Dialog>
    </>
  );
};

export { WithdrawalOptionsForm };
