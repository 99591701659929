import { type FC } from "react";

import { IconMenu } from "@/domains/icons";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { IconButton, Sheet } from "@/shared/ui";

import { Sidebar } from ".";

const SidebarButton: FC = () => {
  const [opened, { close, onOpenChange }] = useDisclosure();

  return (
    <Sheet open={opened} onOpenChange={onOpenChange}>
      <Sheet.Trigger asChild>
        <IconButton size="sm" variant="flat">
          <IconMenu />
        </IconButton>
      </Sheet.Trigger>
      <Sheet.Content>
        <Sidebar onClose={close} />
      </Sheet.Content>
    </Sheet>
  );
};

export { SidebarButton };
