/**
 * @deprecated
 */
export const ProfileIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.481 4a3 3 0 100 6 3 3 0 000-6zm-5 3a5 5 0 1110 0 5 5 0 01-10 0zm.497 8.92c-1.16.58-1.497 1.196-1.497 1.58 0 .384.337 1 1.497 1.58 1.101.55 2.691.92 4.503.92 1.811 0 3.402-.37 4.502-.92 1.16-.58 1.498-1.196 1.498-1.58 0-.384-.337-1-1.498-1.58-1.1-.55-2.69-.92-4.502-.92-1.812 0-3.402.37-4.503.92zm-.894-1.79c1.433-.716 3.342-1.13 5.397-1.13s3.964.414 5.397 1.13c1.373.687 2.603 1.82 2.603 3.37s-1.23 2.683-2.603 3.37c-1.433.716-3.342 1.13-5.397 1.13s-3.964-.414-5.397-1.13c-1.373-.687-2.603-1.82-2.603-3.37s1.23-2.683 2.603-3.37z"
      clipRule="evenodd"
    ></path>
  </svg>
);
