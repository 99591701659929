import type { FC } from "react";

import { PaymentLoader } from "@/features/payment/ui/loader";
import { CheckStep } from "@/features/payment/withdrawal/components/form/steps/check/step";
import type { Field, PaymentMethod, TradingAccount } from "@/services/openapi";
import { useWithdrawFeeQuery } from "@/state/server/payment";

type Props = {
  submitIsloading: boolean;
  currentAccount: TradingAccount;
  currentPaymentMethod: PaymentMethod;
  additionalFieldsData: Field[] | null;
  currentAmount: string;
  onSubmit: () => void;
  back: () => void;
};

const CheckStepContainer: FC<Props> = ({
  submitIsloading,
  currentAccount,
  currentPaymentMethod,
  additionalFieldsData,
  currentAmount,
  back,
  onSubmit,
}) => {
  const { data: fee } = useWithdrawFeeQuery({
    paymentMethodId: currentPaymentMethod.id,
    accountId: currentAccount.id,
    amount: currentAmount,
  });

  return fee ? (
    <CheckStep
      fee={fee.value!}
      currentAmount={currentAmount}
      currentAccount={currentAccount}
      currentPaymentMethod={currentPaymentMethod}
      additionalFieldsData={additionalFieldsData}
      back={back}
      onSubmit={onSubmit}
      submitIsloading={submitIsloading}
    />
  ) : (
    <PaymentLoader />
  );
};

export { CheckStepContainer };
