import type { FC } from "react";
import { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { IconInfo } from "@/domains/icons";
import { PaymentFooter } from "@/features/payment/ui/footer";
import { PaymentMethodToggleGroup } from "@/features/payment/ui/method-toggle-group";
// import { HowToDialog } from "@/features/payment/withdrawal/components/dialogs/how-to-dialog/how-to-dialog";
// import type { WithdrawalCardTableProps } from "@/features/payment/withdrawal/components/dialogs/how-to-dialog/types";
import type { PaymentInfo, PaymentMethod, TradingAccount } from "@/services/openapi";
import { Button, Dialog, InfoBlock } from "@/shared/ui";

// import { getProcessingTime } from "./helpers";

type Props = {
  currentAccount: TradingAccount;
  setPaymentMethod: (method: PaymentMethod) => void;
  paymentInfo: PaymentInfo;
  paymentMethods: PaymentMethod[];
  next: () => void;
  currentPaymentMethod: PaymentMethod | null;
};

const PaymentMethodStep: FC<Props> = ({
  paymentInfo,
  currentPaymentMethod,
  currentAccount,
  paymentMethods,
  next,
  setPaymentMethod,
}) => {
  const { t } = useTranslation();

  // const bankInfo: WithdrawalCardTableProps = {
  //   limits: paymentInfo.limitsAndWithdrawTime!.bankCard!.limits!.find(
  //     ({ currency }) => currency === currentAccount.currency,
  //   )!,
  //   processingTime: getProcessingTime({
  //     paymentProcessingTime: paymentInfo.limitsAndWithdrawTime!.bankCard!.processingTime!,
  //     t,
  //   }),
  //   decimalScale: currentAccount.digits!,
  //   currency: currentAccount.currency!,
  // };
  // const cryptoInfo: WithdrawalCardTableProps = {
  //   limits: paymentInfo.limitsAndWithdrawTime!.crypto!.limits!.find(
  //     ({ currency }) => currency === currentAccount.currency,
  //   )!,
  //   processingTime: getProcessingTime({
  //     paymentProcessingTime: paymentInfo.limitsAndWithdrawTime!.crypto!.processingTime!,
  //     t,
  //   }),
  //   decimalScale: currentAccount.digits!,
  //   currency: currentAccount.currency!,
  // };
  // const otherInfo: WithdrawalCardTableProps = {
  //   limits: paymentInfo.limitsAndWithdrawTime!.other!.limits!.find(
  //     ({ currency }) => currency === currentAccount.currency,
  //   )!,
  //   processingTime: getProcessingTime({
  //     paymentProcessingTime: paymentInfo.limitsAndWithdrawTime!.other!.processingTime!,
  //     t,
  //   }),
  //   decimalScale: currentAccount.digits!,
  //   currency: currentAccount.currency!,
  // };

  const onValueChange = useCallback(
    (value: string) => {
      const method = paymentMethods.find(({ title }) => title === value)!;
      setPaymentMethod(method);
    },
    [paymentMethods, setPaymentMethod],
  );

  return (
    <>
      <div className="flex flex-col gap-6">
        {/* <Dialog>
          <InfoBlock
            variant="accent"
            icon={<IconInfo />}
            text={
              <Trans
                i18nKey="withdrawal.form.info.prev-method"
                components={{
                  // FIXME:
                  learn: <Dialog.Trigger className="underline" />,
                }}
              />
            }
          />

          <HowToDialog bankCardInfo={bankInfo} cryptoInfo={cryptoInfo} otherInfo={otherInfo} />
        </Dialog> */}

        <PaymentMethodToggleGroup value={currentPaymentMethod?.title || ""} onValueChange={onValueChange}>
          {paymentMethods.map(({ title, images, details, currency }) => {
            const limits = details!.limits?.find(limit => limit.currency === currency);

            return (
              <PaymentMethodToggleGroup.Item
                key={title}
                value={title!}
                title={title}
                images={images!}
                description={
                  limits?.platformMax ? (
                    <Trans
                      i18nKey="withdrawal.form.up-to"
                      components={{
                        amount: (
                          <NumberFormat
                            value={limits.platformMax}
                            currency={currency!}
                            decimalScale={currentAccount.digits}
                          />
                        ),
                      }}
                    />
                  ) : (
                    void 0
                  )
                }
              />
            );
          })}
        </PaymentMethodToggleGroup>
      </div>
      <PaymentFooter>
        <Button fullWidth disabled={!currentPaymentMethod} onClick={next}>
          {t("button.next")}
        </Button>
      </PaymentFooter>
    </>
  );
};

export { PaymentMethodStep };
