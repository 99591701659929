import dayjs from "dayjs";
import { useEffect, useState } from "react";

import { useInterval } from "@/hooks/interval.hook";

const useNextRequestDate = (nextRequestDate: string | null) => {
  const [remainingTime, setRemainingTime] = useState<number>(() => {
    if (nextRequestDate === null) {
      return 0;
    }

    return dayjs(nextRequestDate).diff(dayjs(), "second");
  });

  const lessThanZero = remainingTime <= 0;

  useEffect(() => {
    if (nextRequestDate) {
      setRemainingTime(dayjs(nextRequestDate).diff(dayjs(), "second"));
    }
  }, [nextRequestDate]);

  const interval = useInterval(() => setRemainingTime(prevTime => prevTime - 1), 1000);

  useEffect(() => {
    if (!lessThanZero) {
      interval.start();
    }
    return interval.stop;
  }, [lessThanZero]);

  return { remainingTime, countdownActive: !lessThanZero };
};

export { useNextRequestDate };
