import { forwardRef } from "react";

import { Text } from "../text";

type DescriptionProps = React.ComponentProps<typeof Text> & { as?: any };
/**
 * @deprecated
 */
export const DialogDescriptionText = forwardRef<React.ElementRef<typeof Text>, DescriptionProps>(
  ({ children, as = "p", ...restProps }, ref) => {
    return (
      <Text as={as} family="roboto" color="dimmed" align="center" {...restProps} ref={ref}>
        {children}
      </Text>
    );
  },
);
DialogDescriptionText.displayName = "DialogDescriptionText";
