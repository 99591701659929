import type { FC } from "react";

import { useTranslation } from "@/hooks/translator.hook";
import { CabinetHeader } from "@/layouts/cabinet/header";
import { CabinetPage } from "@/layouts/cabinet/page";

import { NotificationsTableContainer } from "./components/notifications-table.container";

const NotificationsPage: FC = () => {
  const { t } = useTranslation();

  return (
    <CabinetPage
      header={
        <CabinetHeader>
          <CabinetHeader.Title>{t("cabinet.navigation.notifications")}</CabinetHeader.Title>
        </CabinetHeader>
      }
    >
      <NotificationsTableContainer />
    </CabinetPage>
  );
};

export { NotificationsPage };
