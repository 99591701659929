import { forwardRef } from "react";

import type { CSS } from "@/styles";
import { styled } from "@/styles/stitches.config";

const StyledButton = styled("button", {
  all: "unset",
  cursor: "pointer",

  display: "flex",

  "&:disabled": {
    opacity: "0.5",
    cursor: "default",
    pointerEvents: "none",
  },
});

type Props = React.ComponentProps<typeof StyledButton> & {
  children?: React.ReactNode;
  className?: string;
  css?: CSS;
};

/**
 * @deprecated
 */
export const UnstyledButton = forwardRef<React.ElementRef<typeof StyledButton>, Props>(
  ({ children, ...restProps }, ref) => {
    return (
      <StyledButton {...restProps} ref={ref} type="button">
        {children}
      </StyledButton>
    );
  },
);
UnstyledButton.displayName = "UnstyledButton";
