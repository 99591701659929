import { cva } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

import { cn } from "@/shared/styles";

const _inputTextStyles = cva(
  "typography-S-Regular text-contrast-primary transition-colors placeholder:text-contrast-tertiary data-[readonly]:text-contrast-secondary group-data-[disabled=true]/input-wrapper:text-contrast-tertiary",
);

const _Input = forwardRef<ElementRef<"input">, ComponentPropsWithoutRef<"input">>(
  ({ autoFocus, readOnly, ...props }, ref) => (
    <input
      className={cn(_inputTextStyles(), "w-full bg-transparent outline-none")}
      readOnly={readOnly}
      data-readonly={readOnly}
      autoFocus={autoFocus} // does not exist in DOM
      data-autofocus={autoFocus}
      {...props}
      ref={ref}
    />
  ),
);

export { _Input, _inputTextStyles };
