import * as ToastPrimitives from "@radix-ui/react-toast";
import { type ComponentProps, type ElementRef, type FC, useRef } from "react";

import { useScreenSize } from "@/hooks/screen-size.hook";
import { cn } from "@/shared/styles";

import { Toast } from ".";
import { type ToasterToast, useToast } from "./toast.hook";

export function Toaster() {
  const { toasts, remove } = useToast();

  const { isMobile } = useScreenSize();

  const swipeDirection = isMobile ? "up" : "right";

  const swipeThreshold = isMobile ? 50 : 100;

  return (
    <Toast.Provider swipeDirection={swipeDirection} swipeThreshold={swipeThreshold}>
      {toasts.map(toast => (
        <ToastItem key={toast.id} swipeDirection={swipeDirection} remove={remove} {...toast} />
      ))}
      <Toast.Viewport />
    </Toast.Provider>
  );
}

const ToastItem: FC<
  ToasterToast & {
    swipeDirection: ComponentProps<typeof Toast>["swipeDirection"];
    remove: (toastId: string) => void;
  }
> = ({
  id,
  title,
  description,
  icon,
  autoClose = true,
  onClose,
  action,
  size = "md",
  accentText,
  swipeDirection,
  remove,
  onClick,
  ...props
}) => {
  const ContentTag = onClick ? ToastPrimitives.Close : "div";

  const ref = useRef<ElementRef<typeof Toast>>(null);

  return (
    <Toast
      ref={ref}
      onOpenChange={() => {
        // https://github.com/radix-ui/primitives/issues/2233#issuecomment-1821222005
        document.getElementById("radix-toast-vp")?.blur();
        setTimeout(() => {
          remove(id);
          onClose?.();
        }, 300);
      }}
      swipeDirection={swipeDirection}
      // https://github.com/radix-ui/primitives/issues/2268
      // https://github.com/radix-ui/primitives/issues/2461
      duration={autoClose ? 4000 : Infinity}
      {...props}
    >
      <div
        className={cn(
          "pointer-events-auto flex w-full items-start justify-between gap-3 rounded-lg bg-surface-elevation-1 px-4 py-4 shadow-modal transition-all",
          size === "lg" && "py-3",
        )}
      >
        <Toast.Icon icon={icon} isSmallIcon={size === "md"} />
        <ContentTag
          onClick={
            onClick
              ? e => {
                  // TODO: add on cancel logic
                  if (ref.current?.dataset?.state !== "closed") {
                    onClick(e);
                  }
                }
              : void 0
          }
          className={cn("flex grow flex-col", size === "lg" && "py-1")}
        >
          <Toast.Title>{title}</Toast.Title>
          {description && <Toast.Description>{description}</Toast.Description>}
          {action && action}
          {accentText && accentText}
        </ContentTag>
        <Toast.Close className={size === "lg" ? "mt-1" : void 0} />
      </div>
    </Toast>
  );
};
