import Big from "big.js";
import type { FC } from "react";
import { useCallback, useMemo } from "react";

import { NumberFormat } from "@/app/components";
import { HookForm } from "@/app/form";
import { Box } from "@/components/box";
import { NumberField } from "@/components/form/fields";
import { AccountSelect, getAccountOptions } from "@/components/form/fields/account-select/account-select";
import { SubmitButton } from "@/components/form/submit-button";
import { Stack } from "@/components/stack";
import { getBonusMainPercent } from "@/entities/bonuses/helpers";
import { BonusTooltip } from "@/entities/bonuses/tooltip";
import { useHookForm } from "@/hooks/form.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { type BonusUserPlatform, FundsTransferType, type TradingAccount } from "@/services/openapi";
import { useTransferMutation } from "@/state/server/funds";

import { AccountTransferCalculate } from "./account-transfer-calculate";
import { transferRules } from "./transfer.helpers";

export enum TransferFields {
  SOURCE_ID = "sourceId",
  DESTINATION_ID = "destinationId",
  AMOUNT = "amount",
}

export interface ITransferFormValues {
  [TransferFields.SOURCE_ID]: string;
  [TransferFields.DESTINATION_ID]: string;
  [TransferFields.AMOUNT]: number;
}

type Props = {
  accounts: TradingAccount[];
  bonus: BonusUserPlatform[];
};

export const TransferForm: FC<Props> = ({ accounts, bonus }) => {
  const { t } = useTranslation();
  const { mutateAsync: transfer } = useTransferMutation({ successMessage: "transfer-confirmed" });
  const form = useHookForm<ITransferFormValues>({ mode: "all" });
  const { watch, reset } = form;

  const [sourceId, destinationId, amountValue] = watch([
    TransferFields.SOURCE_ID,
    TransferFields.DESTINATION_ID,
    TransferFields.AMOUNT,
  ]);

  const sourceAccount = useMemo(() => accounts.find(({ id }) => id === sourceId), [accounts, sourceId]);
  const destinationAccount = useMemo(() => accounts.find(({ id }) => id === destinationId), [accounts, destinationId]);

  const sourceOptions = useMemo(() => {
    const filteredAccounts = accounts.filter(({ id }) => id !== destinationId);
    return getAccountOptions(filteredAccounts);
  }, [accounts, destinationId]);

  const destinationOptions = useMemo(() => {
    const filteredAccounts = accounts.filter(({ id }) => id !== sourceId);
    return getAccountOptions(filteredAccounts);
  }, [accounts, sourceId]);

  const bonusBalance = useMemo(() => {
    if (!sourceAccount?.availableToWithdraw) return 0;

    const credit = new Big(sourceAccount?.credit ?? 0);
    const amount = new Big(amountValue ?? 0);
    const availableToWithdraw = new Big(sourceAccount.availableToWithdraw);

    const result = credit.minus(amount.div(availableToWithdraw).times(credit)).toNumber();

    if (result < 0) return 0;
    else return result;
  }, [amountValue, sourceAccount?.availableToWithdraw, sourceAccount?.credit]);

  const onSubmit = useCallback(
    (values: ITransferFormValues) => {
      return transfer(
        {
          fundsTransferRequest: {
            ...values,
            sourceType: FundsTransferType.TradingAccount,
            destinationType: FundsTransferType.TradingAccount,
          },
        },
        {
          onSuccess: () => {
            reset();
          },
        },
      );
    },
    [reset, transfer],
  );

  return (
    <HookForm form={form} onSubmit={onSubmit} className="xl:flex xl:gap-8">
      <div className="w-full">
        <Stack gap="4" css={{ paddingTop: "17px", "@bp1": { padding: 0 } }}>
          <AccountSelect
            name={TransferFields.SOURCE_ID}
            options={sourceOptions}
            label={t("transfer.from-account")}
            resetFieldOnOptionsChanged={false}
            rules={{
              required: true,
            }}
          />
          <AccountSelect
            name={TransferFields.DESTINATION_ID}
            options={destinationOptions}
            label={t("transfer.to-account")}
            resetFieldOnOptionsChanged={false}
            rules={{
              required: true,
            }}
          />
          {sourceAccount && destinationAccount && (
            <NumberField
              name={TransferFields.AMOUNT}
              // TODO: change remove hardcoded decimal scale
              decimalScale={2}
              label={t("common.amount")}
              placeholder={`0.00 ${sourceAccount.currency}`}
              suffix={` ${sourceAccount.currency}`}
              rules={transferRules(t, sourceAccount.balance!)}
            />
          )}
        </Stack>

        <Box css={{ mt: "24px" }}>
          {sourceAccount && destinationAccount && (
            <AccountTransferCalculate destinationAccount={destinationAccount} sourceAccount={sourceAccount} />
          )}
          <SubmitButton>{t("button.transfer")}</SubmitButton>
        </Box>
      </div>

      {!!sourceAccount?.credit && (
        <>
          <div className="my-6 h-px w-full bg-gray xl:my-0 xl:h-auto xl:w-px xl:grow" />

          <div className="w-full">
            <div className="mb-3 flex items-start justify-between font-roboto">
              <div className="flex items-center gap-2">
                {t("transfer.bonus.text")}
                <BonusTooltip
                  bonus={bonus[0]!}
                  actionText={t("transfer.bonus.button")}
                  content={t("transfer.bonus.description", {
                    percent: getBonusMainPercent(bonus[0]!),
                  })}
                />
              </div>

              <div>
                {!amountValue ? (
                  <NumberFormat
                    className="mb-1 block text-end"
                    value={sourceAccount?.credit}
                    currency={sourceAccount.currency!}
                  />
                ) : (
                  <div className="grid gap-1">
                    <div className="text-end text-text-placeholder line-through">
                      <NumberFormat
                        className="mb-1 block text-end text-text-placeholder line-through"
                        value={sourceAccount?.credit}
                        currency={sourceAccount.currency!}
                        decimalScale={sourceAccount?.digits}
                      />
                    </div>
                    <div className="text-end">
                      <NumberFormat
                        className="block text-end"
                        value={new Big(bonusBalance).toFixed(2, 0)}
                        currency={sourceAccount?.currency!}
                        decimalScale={sourceAccount?.digits}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </HookForm>
  );
};
