import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { IconWithdraw2 } from "@/domains/icons";
import { RemoveFundsContainer } from "@/entities/accounts/remove-funds/container";
import { useDisclosure } from "@/hooks/disclosure.hook";
import type { TradingAccount } from "@/services/openapi";
import { Dialog, TileButton, toast, ToastIcons } from "@/shared/ui";

type Props = {
  account: TradingAccount;
};

const RemoveFundsButton: FC<Props> = ({ account }) => {
  const { t } = useTranslation();

  const { id, digits, currency, availableToWithdraw, attributes } = account;

  const [opened, { onOpenChange, close }] = useDisclosure();

  return (
    <Dialog
      open={opened}
      onOpenChange={value => {
        if (value && availableToWithdraw! <= 0) {
          toast({
            title: t("accounts.remove-funds.error")!,
            icon: ToastIcons.ERROR,
          });
          return;
        }
        onOpenChange(value);
      }}
    >
      <Dialog.Trigger asChild>
        <TileButton variant="secondary" icon={<IconWithdraw2 />}>
          {t("accounts.buttons.remove-funds")}
        </TileButton>
      </Dialog.Trigger>
      <Dialog.Content>
        <RemoveFundsContainer
          accountId={id!}
          accountCurrency={currency!}
          currencyDecimalScale={digits!}
          availableToWithdraw={availableToWithdraw!}
          attributes={attributes!}
          onClose={close}
        />
      </Dialog.Content>
    </Dialog>
  );
};

export { RemoveFundsButton };
