import { type FC, type ReactNode } from "react";

import { cn } from "@/shared/styles";

type Props = {
  isTableExpanded?: boolean;
  // slots
  chartSymbolsSlot: ReactNode;
  headerSlot: ReactNode;
  chartSlot: ReactNode;
  placeOrderSlot: ReactNode;
  tablesSlot: ReactNode;
  accountSummarySlot: ReactNode;
};

const TerminalDesktopFrame: FC<Props> = ({
  isTableExpanded,
  chartSymbolsSlot,
  headerSlot,
  chartSlot,
  placeOrderSlot,
  tablesSlot,
  accountSummarySlot,
}) => {
  return (
    <div className="flex h-full flex-col gap-px bg-surface-under-canvas">
      {headerSlot}
      <div className="grid min-h-0 grow grid-rows-[1fr_auto] gap-px">
        <div className={cn("grid min-h-0 grid-rows-[1fr_auto] gap-px", isTableExpanded && "grid-rows-[2fr_1fr]")}>
          <div className="grid min-h-0 grid-cols-[1fr,332px] gap-px">
            <div className="flex min-h-0 min-w-0 flex-col">
              <div className="bg-surface-canvas p-3 pb-3">{chartSymbolsSlot}</div>
              {chartSlot}
            </div>
            {placeOrderSlot}
          </div>
          {tablesSlot}
        </div>
        {accountSummarySlot}
      </div>
    </div>
  );
};

export { TerminalDesktopFrame };
