/**
 * @deprecated
 */
export const CheckIcon = () => (
  <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.47149 6.52864L10.6667 0.333374L11.6096 1.27618L4.47149 8.41426L0.666748 4.60952L1.60956 3.66671L4.47149 6.52864Z"
      fill="#E8E8E8"
    />
  </svg>
);
