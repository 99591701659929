import { type FC, useState } from "react";

import { IconWeb } from "@/domains/icons";

type Props = {
  title: string;
  images: string[];
};

const ArticleImage: FC<Props> = ({ images, title }) => {
  const [error, setError] = useState(() => !images[0]);

  if (error) {
    return (
      // FIXME: non-existing border radius
      <div className="grid size-[60px] shrink-0 place-items-center rounded-[10px] border border-contrast-quaternary bg-surface-elevation-1 text-contrast-tertiary">
        <IconWeb className="size-9" />
      </div>
    );
  }

  return (
    <div className="size-[60px] shrink-0 overflow-hidden rounded-[10px]">
      <img
        loading="lazy"
        className="h-full w-full"
        src={images[0]}
        alt={title}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          setError(true);
        }}
      />
    </div>
  );
};

export { ArticleImage };
