import { type FC, useMemo } from "react";

import { getShowBonusCondition } from "@/entities/bonuses/helpers";
import { AccountDetails } from "@/features/terminal/account-details/account-details";
import { AccountDetailsSkeleton } from "@/features/terminal/account-details/skeleton";
import { type BonusUserPlatform, type TradingAccount } from "@/services/openapi";

import { useActiveAccountContext } from "./active-account.context";

const CabinetAccountDetails: FC = () => {
  const { bonuses, activeAccount, accounts, setAccount } = useActiveAccountContext();

  if (!accounts || !activeAccount || !bonuses) {
    return <AccountDetailsSkeleton />;
  }

  return (
    <Container accounts={accounts} bonuses={bonuses.items!} activeAccount={activeAccount} setAccount={setAccount} />
  );
};

type Props = {
  activeAccount: TradingAccount;
  accounts: TradingAccount[];
  bonuses: BonusUserPlatform[];
  setAccount: (accountId: string) => void;
};

const Container: FC<Props> = ({ accounts, bonuses, activeAccount, setAccount }) => {
  const { serverAccountId, credit } = activeAccount;

  const bonus = useMemo(() => (bonuses.length > 0 ? bonuses[0]! : null), [bonuses]);

  const showBonus = useMemo(
    () =>
      getShowBonusCondition({
        allowedTradingServerAccounts: bonus?.allowedTradingServerAccounts || [],
        serverAccountId: serverAccountId!,
        credit: credit!,
      }),
    [bonus?.allowedTradingServerAccounts, serverAccountId, credit],
  );

  return (
    <AccountDetails
      accounts={accounts}
      hasOpenPositions={activeAccount.attributes!.hasOpenPositions!}
      setAccount={setAccount}
      showBonus={showBonus}
      account={activeAccount}
    />
  );
};

export { CabinetAccountDetails };
