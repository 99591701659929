import { type ComponentProps, type FC, useCallback, useState } from "react";

import { type TwoFactorRecoveryCode, UserOneTimeCodeType } from "@/services/openapi";
import { Dialog } from "@/shared/ui";
import { useCreateNewRecoveryCodesMutation } from "@/state/server/auth";
import { useRequestOneTimeCodeMutation, useVerifyOneTimeCodeMutation } from "@/state/server/ot-codes";

import { VerifyCodeForm } from "../../verify-code/form";
import { useVerifyCodeData } from "../../verify-code/helpers";
import { RecoveryCodesDialogContent } from "../recovery-codes/dialog/content";

type Props = {
  email: string;
};

const CreateRecoveryCodesContainer: FC<Props> = ({ email }) => {
  const { countdownActive, isCodeSent, remainingTime, setIsCodeSent, setNextRequestDate } = useVerifyCodeData();

  const [recoveryCodes, setRecoveryCodes] = useState<TwoFactorRecoveryCode[] | null>(null);

  const { mutateAsync: createRecoveryCodes, isLoading: recoveryCodesLoading } = useCreateNewRecoveryCodesMutation();
  const { mutate: sendCode, isLoading: requestLoading } = useRequestOneTimeCodeMutation();
  const { mutate: verifyCode, isLoading: verifyLoading } = useVerifyOneTimeCodeMutation();

  const handleCreateRecoveryCodes = useCallback(
    (token: string) => {
      createRecoveryCodes(
        { oneTimeCodeRequest: { oneTimeToken: token } },
        {
          onSuccess: res => {
            setRecoveryCodes(res.items!);
          },
        },
      );
    },
    [createRecoveryCodes, setRecoveryCodes],
  );

  const handleSend = useCallback(() => {
    sendCode(
      { type: UserOneTimeCodeType.TwoFactorRecoveryCodesGenerate },
      {
        onSuccess: ({ dateNextRequest }) => {
          setNextRequestDate(dateNextRequest!);
          setIsCodeSent(true);
        },
      },
    );
  }, [sendCode, setIsCodeSent, setNextRequestDate]);

  const handleSubmit: ComponentProps<typeof VerifyCodeForm>["onSubmit"] = useCallback(
    ({ code }) => {
      return verifyCode(
        { type: UserOneTimeCodeType.TwoFactorRecoveryCodesGenerate, stringContainer: { value: code } },
        {
          onSuccess: ({ token }) => {
            handleCreateRecoveryCodes(token!);
          },
        },
      );
    },
    [handleCreateRecoveryCodes, verifyCode],
  );

  return (
    <Dialog.Content>
      {recoveryCodes ? (
        <RecoveryCodesDialogContent recoveryCodes={recoveryCodes} />
      ) : (
        <VerifyCodeForm
          onSubmit={handleSubmit}
          onSend={handleSend}
          isLoading={recoveryCodesLoading || requestLoading || verifyLoading}
          remainingTime={remainingTime}
          email={email}
          countdownActive={countdownActive}
          isCodeSent={isCodeSent}
        />
      )}
    </Dialog.Content>
  );
};

export { CreateRecoveryCodesContainer };
