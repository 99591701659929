import { cva } from "class-variance-authority";
import { type FC, type ReactNode } from "react";

import { Text, type TextColor } from "@/shared/ui";

const defaultStyles = cva("rounded-md bg-card-bg p-4")();
const dimmedStyles = cva("rounded-lg border border-card-border bg-card-bg-accent p-4")();

type Props = {
  title: ReactNode;
  variant: "default" | "dimmed";
  subText?: ReactNode;
  subTextColor?: TextColor;
  subTextSize?: "md" | "sm";
  children?: ReactNode;
  endBlock?: ReactNode;
};

const AccountDetailsInfoBlock: FC<Props> = ({
  title,
  subText,
  subTextColor = "tertiary",
  subTextSize = "md",
  children,
  endBlock,
  variant,
}) => {
  return (
    <div className={variant === "default" ? defaultStyles : dimmedStyles}>
      <div className="flex items-start justify-between">
        <div className="flex flex-col gap-1">
          <Text as="h5" variant="S / Regular" color="secondary">
            {title}
          </Text>
          <Text variant={subTextSize === "md" ? "M / Medium" : "S / Medium"} color={subTextColor}>
            {subText}
          </Text>
        </div>
        {endBlock}
      </div>
      {children}
    </div>
  );
};

export { AccountDetailsInfoBlock };
