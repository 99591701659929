import type { FC, ReactNode } from "react";

import { TooltipQuestionIcon } from "@/components/icons";
import { Text, Tooltip } from "@/shared/ui";

import { WidgetScrollButton } from "./scroll-button";

type Props = {
  children: ReactNode;
  title: string;
  description?: ReactNode;
  customDescription?: ReactNode;
  hasScrollButton?: boolean;
};

const WidgetBlock: FC<Props> = ({ children, title, description, customDescription, hasScrollButton }) => (
  <div className="relative flex flex-col gap-4 overflow-auto rounded-lg border border-card-border bg-card-bg p-4">
    <div className="flex items-center justify-between gap-2">
      <Text as="h4" variant="M Compact / Medium" color="secondary">
        {title}
      </Text>
      {description && (
        <Tooltip content={description}>
          <TooltipQuestionIcon />
        </Tooltip>
      )}
      {customDescription}
    </div>
    {hasScrollButton ? <WidgetScrollButton>{children}</WidgetScrollButton> : children}
  </div>
);

export { WidgetBlock };
