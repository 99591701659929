import { type FC, memo } from "react";
import { useController, useFormContext } from "react-hook-form";

import { useField } from "../../field.hook";
import { NumberInput, type NumberInputExternalProps } from "../../inputs";
import { type ControllerRules } from "../../types";

type Props = NumberInputExternalProps & {
  name: string;
  rules?: ControllerRules;
};

export const _NumberField: FC<Props> = props => {
  const { name, rules, defaultValue, ...restProps } = props;

  const { control } = useFormContext();
  const { field } = useController({ name, control, rules, defaultValue });

  const { error, showError } = useField(name);

  return <NumberInput {...restProps} error={error} showError={showError} {...field} name={name} />;
};

/**
 * @deprecated
 */
export const NumberField = memo(_NumberField);
