import { type FC, useState } from "react";

import { CabinetPage } from "@/layouts/cabinet/page";
import { useKycInfoQuery } from "@/state/server/kyc";
import { usePlatformData } from "@/state/server/platform/platform.hooks";
import { useProfileData } from "@/state/server/profile/profile.hooks";

import { ChangePasswordContainer } from "./change-password/container";
import { ProfileSettingsContainer } from "./settings/container";

type ProfileStep = "settings" | "changePassword";
type ChangePasswordInfo =
  | {
      type: "verify";
      oneTimeToken: string;
    }
  | {
      type: "2fa";
      twoFactorCode: string;
    };

const ProfilePage: FC = () => {
  const profile = useProfileData();
  const { password: passwordRules } = usePlatformData();

  const [step, setStep] = useState<ProfileStep>("settings");
  const [changePasswordData, setChangePasswordData] = useState<ChangePasswordInfo | null>(null);

  const { data: kyc } = useKycInfoQuery();

  if (!kyc) {
    return <CabinetPage.Loader />;
  }

  return step === "changePassword" && changePasswordData ? (
    <ChangePasswordContainer
      setStep={setStep}
      setChangePasswordData={setChangePasswordData}
      changePasswordData={changePasswordData}
      passwordRules={passwordRules!}
    />
  ) : (
    <ProfileSettingsContainer
      kyc={kyc}
      profile={profile}
      setStep={setStep}
      setChangePasswordData={setChangePasswordData}
    />
  );
};

export { ProfilePage };
export type { ProfileStep, ChangePasswordInfo };
