import * as SheetPrimitive from "@radix-ui/react-dialog";
import { type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

import { cn } from "@/shared/styles";
import { overlayStyles, sheetStyles } from "@/shared/ui";

const Content = forwardRef<
  ElementRef<typeof SheetPrimitive.Content>,
  ComponentPropsWithoutRef<typeof SheetPrimitive.Content> & VariantProps<typeof sheetStyles>
>(({ className, side = "start", ...props }, ref) => (
  <SheetPrimitive.Portal>
    <SheetPrimitive.Overlay className={overlayStyles()} />
    <SheetPrimitive.Content
      className={cn(sheetStyles({ side }), "bg-surface-elevation-2 outline-none", className)}
      {...props}
      ref={ref}
    />
  </SheetPrimitive.Portal>
));
Content.displayName = SheetPrimitive.Content.displayName;

const Sheet = ({ ...props }: ComponentPropsWithoutRef<typeof SheetPrimitive.Root>) => (
  <SheetPrimitive.Root {...props} />
);

const Component = Object.assign(Sheet, {
  Trigger: SheetPrimitive.Trigger,
  Close: SheetPrimitive.Close,
  Content,
});

export { Component as Sheet };
