import { type FC } from "react";

import { useTerminalAccountContext } from "../contexts/account.context";
import { DepositButton } from "./button";

const DepositButtonContainer: FC = () => {
  const {
    account: { id },
  } = useTerminalAccountContext();

  return <DepositButton accountId={id!} />;
};

export { DepositButtonContainer };
