import { Portal } from "@radix-ui/react-portal";
import { type FC } from "react";

import { Button } from "@/components/button";
import { Text } from "@/components/text";
import { CookieType } from "@/constants/enums";
import { useTranslation } from "@/hooks/translator.hook";
import { styled } from "@/styles";

const TextWrapper = styled("div", {
  flex: "1",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  textAlign: "left",
  width: "100%",

  "@bp2": {
    marginTop: "16px",
    width: "auto",
  },
});

const ButtonsWrapper = styled("div", {
  marginTop: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "8px",
  width: "100%",

  "@bp2": {
    width: "auto",
  },

  button: {
    minWidth: "205px",
    paddingLeft: "24px",
    paddingRight: "24px",
    width: "100%",

    "@bp2": {
      width: "auto",
    },
  },
});

interface CookiePopupProps {
  onCookieAccept: (cookieType: CookieType) => void;
}

export const CookiePopup: FC<CookiePopupProps> = ({ onCookieAccept }) => {
  const { t } = useTranslation();
  const paragraph = { __html: t("cookie-parapgraph") };

  return (
    <Portal>
      <div className="fixed inset-0 z-50 bg-black/40 md:hidden" />
      <div className="fixed bottom-0 start-0 z-50 flex w-full max-w-[700px] flex-col items-center justify-center rounded-t-xl bg-surface-elevation-2 p-6 shadow md:bottom-[5px] md:start-1/2 md:w-[98%] md:-translate-x-1/2 md:flex-row md:rounded-xl md:px-8 md:py-6">
        <TextWrapper>
          <Text size="4" weight="bold" lineHeight="4">
            {t("cookie-title")}
          </Text>
          <Text
            size="2"
            lineHeight="4"
            css={{
              lineHeight: "150%",
              letterSpacing: "0.01em",
              marginTop: "16px",
              marginRight: "16px",

              a: {
                color: "$primary",
              },
            }}
            dangerouslySetInnerHTML={paragraph}
          ></Text>
        </TextWrapper>
        <ButtonsWrapper>
          <Button size="small" onClick={() => onCookieAccept(CookieType.ACCEPT_ALL)}>
            {t("cookie-btn-accept")}
          </Button>
          <Button size="small" variant="secondary" onClick={() => onCookieAccept(CookieType.ACCEPT_ESSENTIALS)}>
            {t("cookie-btn-essential")}
          </Button>
        </ButtonsWrapper>
      </div>
    </Portal>
  );
};
