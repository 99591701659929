/**
 * @deprecated
 */
export const ChevronRightIcon = () => (
  <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.58586 6.99995L0.292969 1.70706L1.70718 0.292847L8.41429 6.99995L1.70718 13.7071L0.292969 12.2928L5.58586 6.99995Z"
      fill="currentColor"
    />
  </svg>
);
