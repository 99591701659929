import type { FC } from "react";

import { IconClose } from "@/domains/icons";
import { IconButton, Text } from "@/shared/ui";

import { useSignalContext } from "../../signal/context";
import { SignalTextFormat } from "../../signal/text-format";
import { useCurrentSymbolContext } from "../../symbol-info/current-symbol-context";
import { PlaceOrderContainer } from "../container";
import { TerminalPlaceOrderProvider } from "../context";

const PlaceOrderSignalBlock: FC = () => {
  const { closeSignalForm } = useSignalContext();
  const { symbolInfo } = useCurrentSymbolContext();

  return (
    <div
      className="rounded-t-xl bg-surface-elevation-2 animate-in fade-in-0"
      style={{
        boxShadow: "0px -6px 8px 0px rgba(0, 0, 0, 0.03)",
      }}
    >
      <div className="grid grid-cols-[1fr_auto_1fr] items-center p-4">
        <div />
        <Text as="h5" color="primary" variant="M / Medium" align="center">
          <SignalTextFormat type={symbolInfo.signal!.type!} />
        </Text>
        <div className="-me-2 flex justify-end">
          <IconButton size="sm" variant="flat" onClick={closeSignalForm}>
            <IconClose />
          </IconButton>
        </div>
      </div>
      <TerminalPlaceOrderProvider isSignal>
        <PlaceOrderContainer />
      </TerminalPlaceOrderProvider>
    </div>
  );
};

export { PlaceOrderSignalBlock };
