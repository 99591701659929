import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { PnlFormat, PositiveNumberFormat } from "@/app/components";
import { getNumberTextColor } from "@/app/ui/colors";
import { IconChevronDown } from "@/domains/icons";
import { AccountBadge } from "@/entities/accounts/badge";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { type TradingAccount } from "@/services/openapi";
import { Dialog, DialogSelect, Popover, Text, UnstyledButton } from "@/shared/ui";

import { AccountDetailsContent } from "./content";
import { AccountDetailsSelect } from "./select/select";

type Props = {
  account: TradingAccount;
  accounts: TradingAccount[];
  showBonus: boolean;
  hasOpenPositions: boolean;
  setAccount: (accountId: string) => void;
};

const AccountDetails: FC<Props> = ({ accounts, hasOpenPositions, account, showBonus, setAccount }) => {
  const { t } = useTranslation();

  const { type, currency, digits, equity, pnL } = account;

  const [selectOpened, { onOpenChange: selectOnOpenChange, open: openSelect }] = useDisclosure();

  return (
    <Dialog open={selectOpened} onOpenChange={selectOnOpenChange}>
      <Popover modal>
        <Popover.Trigger asChild>
          <UnstyledButton className="inline-flex h-12 items-center gap-3 rounded-md px-2 tabular-nums outline-none transition-colors hover:bg-control-bg-hover active:bg-control-bg-hover data-[state=open]:bg-control-bg-hover">
            <AccountBadge type={type!} />
            <div className="flex flex-col gap-0.5">
              <div className="flex items-center gap-1">
                <Text
                  variant="S / Medium"
                  color="primary"
                  className="line-clamp-1 lg:line-clamp-none lg:whitespace-nowrap"
                >
                  <PositiveNumberFormat currency={currency!} value={equity} decimalScale={digits} />
                </Text>
                <IconChevronDown className="size-4 shrink-0" />
              </div>
              {hasOpenPositions && (
                <Text variant="XS / Regular" color={getNumberTextColor(pnL!) || "secondary"}>
                  <PnlFormat value={pnL} decimalScale={digits} currency={currency!} />
                </Text>
              )}
            </div>
          </UnstyledButton>
        </Popover.Trigger>
        <Popover.Content align="center" titleSize="sm" title={t("terminal.account-details.title")!} centered>
          <AccountDetailsContent
            accounts={accounts}
            currentAccount={account}
            setAccount={setAccount}
            showBonus={showBonus}
            openSelect={openSelect}
            hasOpenPositions={hasOpenPositions}
          />
        </Popover.Content>
      </Popover>
      <DialogSelect.Content title={t("terminal.account-details.select.title")!}>
        <AccountDetailsSelect accounts={accounts} currentAccount={account} setAccount={setAccount} />
      </DialogSelect.Content>
    </Dialog>
  );
};

export { AccountDetails };
