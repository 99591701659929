import type { VariantProps } from "@stitches/react";
import React, { forwardRef } from "react";
import ReactSelect, { type SelectComponentsConfig } from "react-select";

// import { Box } from "@/components/box";
import { useTranslation } from "@/hooks/translator.hook";

// import { useField } from "../../field.hook";
import { Description, Error, Label } from "../styles";
import { selectClassName, selectClassNamePrefix, SelectWrapper } from "./styles";

export type SelectOptionType<T = any> = {
  value: string | number;
  label: string | number;
  icon?: string;
  data?: T;
};

type SelectValueType = SelectOptionType["value"];

// this is manual select props types // TODO: extract correct types from lib itself
/**
 * @deprecated
 */
export type SelectExternalProps = VariantProps<typeof SelectWrapper> & {
  value: SelectValueType | undefined;
  onChange: (option: SelectValueType) => void;
  options: SelectOptionType[];
  name?: string;
  label?: React.ReactNode;
  description?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  placeholder?: React.ReactNode;
  components?: SelectComponentsConfig<SelectOptionType, false, any>;
};

type SelectInternalProps = {
  onBlur?: () => void;
  showError?: boolean;
  errorMessage?: string;
};

type Props = SelectExternalProps & SelectInternalProps;

const _Select = forwardRef<React.ElementRef<typeof ReactSelect<SelectOptionType>>, Props>((props, ref) => {
  const {
    value,
    onChange,
    options,
    isSearchable = false,
    placeholder,
    isDisabled,
    isLoading,
    label,
    name,
    description,
    size,
    errorMessage,
    showError,
    ...restProps
  } = props;
  const { t } = useTranslation();

  const isDisabledInner = isDisabled || options.length === 1 || isLoading;
  return (
    <SelectWrapper error={showError} disabled={isDisabledInner} size={size}>
      {label && <Label label={label} name={name} />}
      {description && <Description description={description} />}
      <ReactSelect
        {...restProps}
        ref={ref}
        name={name}
        id={name}
        isLoading={isLoading}
        isSearchable={isSearchable}
        aria-haspopup={false}
        options={options}
        autoFocus={false}
        placeholder={placeholder || t("fields.select.placeholder")}
        value={options.find(item => item.value === value) || null}
        onChange={val => val && onChange(val.value)}
        isDisabled={isDisabledInner}
        className={selectClassName}
        classNamePrefix={selectClassNamePrefix}
        noOptionsMessage={() => t("fields.select.no-options")}
      />
      {showError && errorMessage && <Error errorMessage={errorMessage} />}
    </SelectWrapper>
  );
});
_Select.displayName = "Select";

/**
 * @deprecated
 */
export const Select = React.memo(_Select);
