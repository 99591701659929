import Big from "big.js";
import { type FC, useMemo } from "react";
import { Trans } from "react-i18next";

import { NumberFormat, PnlFormat } from "@/app/components";
import { HookForm, SubmitButton, useControlledField, useHookForm } from "@/app/form";
import { getNumberTextColor } from "@/app/ui/colors";
import { formatInputNumberValue, getInputNumberValue } from "@/features/terminal/helpers/formatting";
import { calculateProfitAndLoss } from "@/features/terminal/helpers/formulas";
import type { MergedTerminalSymbol } from "@/features/terminal/helpers/symbols";
import { useTranslation } from "@/hooks/translator.hook";
import { TerminalDealType } from "@/services/openapi";
import { Button, DataList, Popover, StepperInput } from "@/shared/ui";

enum Fields {
  VOLUME = "volume",
}

type FormValues = {
  [Fields.VOLUME]: string;
};

type Props = {
  onSubmit: (values: FormValues) => Promise<unknown>;
  volume: number;
  volumeDecimalScale: number;
  volumeStep: number;
  volumeMin: number;
  currency: string;
  currentPrice: number;
  openPrice: number;
  contractSize: number;
  orderType: TerminalDealType;
  baseCurrency: string;
  quoteCurrency: string;
  currencyDecimalScale: number;
  profitRate: number | undefined;
  symbols: MergedTerminalSymbol[];
};

const PartialCloseForm: FC<Props> = ({
  onSubmit,
  volume: volumeMax,
  volumeDecimalScale,
  volumeMin,
  volumeStep,
  currency,
  orderType,
  currentPrice,
  openPrice,
  contractSize,
  baseCurrency,
  quoteCurrency,
  currencyDecimalScale,
  profitRate,
  symbols,
}) => {
  const { t } = useTranslation();

  const form = useHookForm<FormValues>({
    defaultValues: {
      [Fields.VOLUME]: formatInputNumberValue(volumeMax, volumeDecimalScale),
    },
  });
  const { watch, formState, control } = form;
  const { isValid } = formState;

  const { volume: v } = watch();
  const volume = getInputNumberValue(v);

  const [field, { invalid, pending }] = useControlledField<FormValues>({
    name: Fields.VOLUME,
    control,
    rules: { min: volumeMin, max: volumeMax, required: true },
  });

  const pnl = useMemo(() => {
    if (!volume) {
      return null;
    }

    return calculateProfitAndLoss({
      type: orderType,
      volume,
      openPrice,
      accountCurrency: currency,
      currentPrice,
      baseCurrency,
      quoteCurrency,
      contractSize,
      symbols,
      profitRate,
    });
  }, [
    orderType,
    volume,
    openPrice,
    currency,
    currentPrice,
    baseCurrency,
    quoteCurrency,
    contractSize,
    symbols,
    profitRate,
  ]);

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <StepperInput
        autoFocus
        decimalScale={volumeDecimalScale}
        pending={pending}
        invalid={invalid}
        placeholder={t("terminal.orders.partial-close.placeholder")!}
        minusDisabled={(volume || 0) <= volumeMin}
        plusDisabled={(volume || 0) >= volumeMax}
        onIncrement={() => {
          if (!volume) {
            field.onChange(formatInputNumberValue(volumeMin, volumeDecimalScale));
            return;
          }
          field.onChange(formatInputNumberValue(new Big(volume).add(volumeStep).toNumber(), volumeDecimalScale));
        }}
        onDecrement={() => {
          if ((volume || 0) > volumeMax) {
            field.onChange(formatInputNumberValue(volumeMax, volumeDecimalScale));
            return;
          }
          field.onChange(formatInputNumberValue(new Big(volume!).minus(volumeStep).toNumber(), volumeDecimalScale));
        }}
        descriptor={
          volumeMin !== volumeMax ? (
            <Trans
              i18nKey="terminal.order-settings.from-to-volume"
              components={{
                fromButton: (
                  <StepperInput.DescriptorButton
                    onClick={() => field.onChange(formatInputNumberValue(volumeMin, volumeDecimalScale))}
                  />
                ),
                fromValue: <NumberFormat value={volumeStep} decimalScale={volumeDecimalScale} />,
                toButton: (
                  <StepperInput.DescriptorButton
                    onClick={() => field.onChange(formatInputNumberValue(volumeMax, volumeDecimalScale))}
                  />
                ),
                toValue: <NumberFormat value={volumeMax} decimalScale={volumeDecimalScale} />,
              }}
            />
          ) : null
        }
        {...field}
      />
      <DataList className="mt-3">
        <DataList.Item
          label={<DataList.Label>{t("terminal.orders.partial-close.pnl")}</DataList.Label>}
          value={
            isValid && !!pnl ? (
              <DataList.Value color={getNumberTextColor(pnl)}>
                <PnlFormat value={pnl} decimalScale={currencyDecimalScale} currency={currency} />
              </DataList.Value>
            ) : (
              <DataList.Value>—</DataList.Value>
            )
          }
        />
      </DataList>
      <Popover.Footer>
        <SubmitButton size="sm">{t("terminal.orders.partial-close.action")}</SubmitButton>
        <Popover.Close asChild>
          <Button size="sm" variant="secondary">
            {t("button.cancel")}
          </Button>
        </Popover.Close>
      </Popover.Footer>
    </HookForm>
  );
};

export { PartialCloseForm };
