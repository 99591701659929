import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { Dialog, DialogSelect } from "@/shared/ui";

import { LanguagesSelect } from "../components/languages-select";
import type { LanguageDialogProps } from "../types";

const LanguageSwitcherDialog: FC<LanguageDialogProps> = ({ open, languagesInfo, onOpenChange, onLocaleChange }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogSelect.Content title={t("language-switcher.title")!}>
        <LanguagesSelect languagesInfo={languagesInfo} onChange={onLocaleChange} />
      </DialogSelect.Content>
    </Dialog>
  );
};

export { LanguageSwitcherDialog };
