import type { FC } from "react";

import { Skeleton } from "@/shared/ui";

const AccountDetailsSkeleton: FC = () => (
  <div className="flex h-12 items-center gap-3 px-2">
    <Skeleton className="h-7 w-14 rounded-sm" />
    <div className="flex flex-col gap-1.5">
      <Skeleton className="h-4 w-[102px] rounded-sm" />
      <Skeleton className="h-3 w-[102px] rounded-sm" />
    </div>
  </div>
);

export { AccountDetailsSkeleton };
