import type { FC } from "react";

import { TerminalTableState, useLayoutContext } from "@/features/terminal/layout/context";

import { AccountDetailsContainer } from "./account-details/container";
import { ChartContainer } from "./chart/container";
import { ChartSymbolsContainer } from "./chart-symbols/container";
import { DepositButtonContainer } from "./deposit/container";
import { MobilePlaceOrderBlock } from "./place-order/mobile/block";
import { TerminalMobileFrame } from "./terminal-mobile.frame";
import { MobileTradingTablesBlock } from "./trading-tables/mobile/block";

const TerminalMobile: FC = () => {
  const { isChartExpanded, table } = useLayoutContext();

  return (
    <TerminalMobileFrame
      showFullscreenChart={isChartExpanded && table === TerminalTableState.NONE}
      chartSymbolsSlot={<ChartSymbolsContainer />}
      accountDetailsSlot={<AccountDetailsContainer />}
      depositButtonSlot={<DepositButtonContainer />}
      chartSlot={<ChartContainer />}
      placeOrderSlot={<MobilePlaceOrderBlock />}
      tablesSlot={<MobileTradingTablesBlock />}
    />
  );
};

export { TerminalMobile };
