import { cva } from "class-variance-authority";
import type { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import { IconEye, IconEyeDisabled, IconInfo } from "@/domains/icons";
import { cn } from "@/shared/styles";
import { Dialog, Text, UnstyledButton } from "@/shared/ui";

import { useSignalContext } from "../../signal/context";
import { SignalDisclaimerDialog } from "./disclaimer-dialog";

const buttonStyles = cva("typography-S-Medium flex items-center gap-1 text-positive-text")();

type Props = {
  className?: string;
  dimmed?: boolean;
};

const PlaceOrderSignalsBlock: FC<Props> = ({ className, dimmed }) => {
  const { t } = useTranslation();

  const { isSignalLinesShown, closeSignalLines, showSignalLines } = useSignalContext();

  return (
    <div className={cn("rounded-lg bg-control-bg p-4", dimmed && "border-0 bg-card-bg-accent", className)}>
      <div className="flex gap-3">
        <div className="shrink-0 text-contrast-secondary">
          <IconInfo />
        </div>
        <div className="flex flex-col gap-2">
          <Text variant="S / Regular" color="primary">
            <Dialog>
              <Trans
                i18nKey="terminal.signal.disclaimer.info"
                components={{ button: <Dialog.Trigger className="underline" /> }}
              />
              <SignalDisclaimerDialog />
            </Dialog>
          </Text>
          {isSignalLinesShown ? (
            <UnstyledButton className={buttonStyles} onClick={closeSignalLines}>
              {t("terminal.signal.hide-lines")}
              <IconEyeDisabled className="size-4" />
            </UnstyledButton>
          ) : (
            <UnstyledButton className={buttonStyles} onClick={showSignalLines}>
              {t("terminal.signal.show-lines")}
              <IconEye className="size-4" />
            </UnstyledButton>
          )}
        </div>
      </div>
    </div>
  );
};

export { PlaceOrderSignalsBlock };
