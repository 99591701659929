import type { FC } from "react";

import image from "@/assets/images/DialogImage3.svg";
import { useTranslation } from "@/hooks/translator.hook";
import { Button, Dialog, Image, Text } from "@/shared/ui";

type Props = {
  onDeposit: () => void;
};

const CongratulationsDialogContent: FC<Props> = ({ onDeposit }) => {
  const { t } = useTranslation();
  return (
    <>
      <Dialog.Header
        centered
        title={t("congratulations.dialog-title")}
        image={
          <Dialog.Image type="image" size="lg">
            <Image src={image} />
          </Dialog.Image>
        }
      />
      <Text variant="S / Regular" color="primary" align="center">
        {t("congratulations.dialog-description")}
      </Text>
      <Dialog.Buttons>
        <Dialog.Close asChild>
          <Button onClick={onDeposit}>{t("button.deposit")}</Button>
        </Dialog.Close>
      </Dialog.Buttons>
    </>
  );
};

export { CongratulationsDialogContent };
