import { useNavigate } from "react-router-dom";

import { DialogName, useDialog } from "@/hooks/dialog.hook";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { Dialog } from "@/shared/ui";

import { CongratulationsDialogContent } from "./congratulations-dialog-content";

const CongratulationsDialog = () => {
  const { onClose, open } = useDialog(DialogName.CONGRATULATIONS);
  const navigate = useNavigate();

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <Dialog.Content>
        <CongratulationsDialogContent
          onDeposit={() => {
            onClose();
            navigate(cabinetRoutes.deposit);
          }}
        />
      </Dialog.Content>
    </Dialog>
  );
};

export { CongratulationsDialog };
