/**
 * @deprecated
 */
export const TransactionTransferIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.09495 23.6407C15.5243 25.2438 22.0353 21.3315 23.638 14.9029C25.2408 8.47401 21.3288 1.96182 14.8999 0.359094C8.47246 -1.24364 1.9614 2.66907 0.359421 9.09837C-1.24407 15.5266 2.66826 22.038 9.09495 23.6407ZM6.42067 9.4545H12.6649V10.909H6.42067L8.81551 13.3039L7.78699 14.3324L3.63636 10.1818L7.78699 6.03116L8.81551 7.05967L6.42067 9.4545ZM10.6078 13.8181H16.8521L14.4572 11.4233L15.4857 10.3948L19.6364 14.5454L15.4857 18.696L14.4572 17.6675L16.8521 15.2727H10.6078V13.8181Z"
      fill="currentColor"
    />
  </svg>
);
