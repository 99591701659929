import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, type ReactNode } from "react";

import { cn } from "@/shared/styles";

const groupStyles = cva("inline-flex items-center gap-2", {
  variants: {
    variant: {
      wrap: "flex-wrap",
      overflow: "overflow-x-auto scrollbar-hide",
    },
  },
});

type Props<T extends string> = VariantProps<typeof groupStyles> &
  Omit<ComponentPropsWithoutRef<typeof ToggleGroup.Root>, "defaultValue" | "value" | "onValueChange" | "type"> & {
    defaultValue?: T;
    value?: T;
    onValueChange?: (value: T) => void;
  };

const Group = <T extends string>({
  orientation = "horizontal",
  className,
  onValueChange,
  variant = "wrap",
  ...props
}: Props<T>) => (
  <ToggleGroup.Root
    className={cn(groupStyles({ variant }), className)}
    orientation={orientation}
    type="single"
    onValueChange={
      onValueChange
        ? value => {
            if (value) onValueChange(value as T);
          }
        : undefined
    }
    {...props}
  />
);

const itemStyles = cva(
  "typography-S-Regular flex h-8 items-center gap-1 rounded-lg bg-contrast-quinary px-3 text-contrast-primary outline-none transition-colors hover:bg-contrast-quaternary focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-text data-[state=on]:bg-accent-bg data-[state=on]:text-accent-over",
)();

const Chip = forwardRef<
  ElementRef<typeof ToggleGroup.Item>,
  ComponentPropsWithoutRef<typeof ToggleGroup.Item> & { endSection?: ReactNode }
>(({ className, children, endSection, ...props }, ref) => (
  <ToggleGroup.Item className={cn(itemStyles, className)} {...props} ref={ref}>
    {children}
    {endSection}
  </ToggleGroup.Item>
));

const Component = Object.assign(Chip, {
  Group,
});

export { Component as Chip };
