import { type ComponentProps, type FC } from "react";

import { Leverage } from "@/entities/accounts/leverage";

type Props = ComponentProps<typeof Leverage>;

const LeverageBadge: FC<Props> = props => (
  <div className="typography-XS-Regular rounded-2xs bg-contrast-quinary px-1 text-contrast-secondary">
    <Leverage {...props} />
  </div>
);

export { LeverageBadge };
