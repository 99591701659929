import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, type FC, forwardRef, type ReactNode } from "react";

import { SymbolIcon } from "@/features/terminal/symbols/icon";
import { cn } from "@/shared/styles";
import { Loader, ScrollArea, Text, UnstyledButton } from "@/shared/ui";

const cellStyles = cva("box-content table-cell h-10 whitespace-nowrap px-2 first:ps-0")();

const Symbol: FC<ComponentPropsWithoutRef<typeof UnstyledButton> & { symbol: string }> = ({ symbol, ...props }) => {
  return (
    <UnstyledButton className="flex items-center gap-2 outline-none" {...props}>
      <SymbolIcon symbol={symbol} />
      {symbol}
    </UnstyledButton>
  );
};

const Head = forwardRef<ElementRef<typeof Text>, ComponentPropsWithoutRef<typeof Text>>(
  ({ align = "start", color = "secondary", ...props }, ref) => (
    <Text
      as="th"
      className={cn(cellStyles, "sticky top-0 z-10 bg-surface-canvas pb-1")}
      align={align}
      color={color}
      variant="XS / Regular"
      {...props}
      ref={ref}
    />
  ),
);

const Cell = forwardRef<ElementRef<typeof Text>, ComponentPropsWithoutRef<typeof Text> & { colSpan?: number }>(
  ({ align = "start", color = "primary", ...props }, ref) => (
    <Text
      as="td"
      className={cn(cellStyles, "py-1 tabular-nums")}
      align={align}
      color={color}
      variant="S / Regular"
      {...props}
      ref={ref}
    />
  ),
);

const rowStyles = cva("border-b border-contrast-quinary bg-surface-canvas last:border-0", {
  variants: {
    showBorder: {
      true: "!border-b",
    },
  },
});

const Row = forwardRef<ElementRef<"tr">, ComponentPropsWithoutRef<"tr"> & VariantProps<typeof rowStyles>>(
  ({ className, showBorder, ...props }, ref) => (
    <tr className={cn(rowStyles({ showBorder }), className)} {...props} ref={ref} />
  ),
);

type Props<T> = {
  renderHeader: () => ReactNode;
  emptyState: ReactNode;
  renderRow: (items: T) => ReactNode;
  isLoading: boolean;
  items: T[];
  bottomRow?: ReactNode;
};

const Root = <T,>({ renderRow, items, renderHeader, emptyState, isLoading, bottomRow }: Props<T>) => {
  if (isLoading) {
    return (
      <div className="grid h-full place-items-center">
        <Loader />
      </div>
    );
  }

  if (items.length === 0) {
    return <div className="grid h-full place-items-center">{emptyState}</div>;
  }

  return (
    <ScrollArea scrollbars="both" className="px-4 pb-2 pt-0.5">
      <table className="w-full">
        <thead>{renderHeader()}</thead>
        <tbody>
          {items.map(renderRow)}
          {bottomRow}
        </tbody>
      </table>
    </ScrollArea>
  );
};

const Component = Object.assign(Root, { Row, Head, Cell, Symbol });

export { Component as TradingTable };
