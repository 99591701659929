import { Table } from "@/components/table";
import { SearchInput } from "@/shared/ui";
import { useNotificationsQuery } from "@/state/server/notifications";

import { useNotificationsTableFilters } from "./notification-table.helpers";
import { NotificationTableRow } from "./notification-table-row";

const NotificationsTableContainer = () => {
  const { changePage, pageSize, currentPage, search, updateSearch, mask } = useNotificationsTableFilters();
  const { data } = useNotificationsQuery({ pageSize, page: currentPage, mask });

  return (
    <>
      <SearchInput value={search} onChange={updateSearch} />
      {data && (
        <Table
          items={data.items!}
          renderBodyRow={notification => <NotificationTableRow key={notification.id} notification={notification} />}
          emptyMessage={<></>}
          changePage={changePage}
          currentPage={currentPage}
          pageSize={pageSize}
          total={data.total!}
        />
      )}
    </>
  );
};

export { NotificationsTableContainer };
