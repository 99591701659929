import type { VariantProps } from "@stitches/react";
import type { ReactNode } from "react";
import { forwardRef, memo } from "react";

import { NumberFormat } from "@/app/components";
import { darkThemeSelector, styled } from "@/styles";

export type TabVariantType = "tab" | "tile" | "fluid";

export interface TabProps extends StyledTabProps {
  label: ReactNode;
  value: string;
  count?: number;
  visible?: boolean;
  fluid?: boolean; // comes from parent element
  variant?: TabVariantType; // comes from parent element
  selected?: boolean; // comes from parent element
  onChange?: (value: string) => void; // comes from parent element
  numeric?: boolean;
  className?: string;
  currency?: string;
}

export type StyledTabProps = VariantProps<typeof StyledTileTab>;

const StyledTab = styled("div", {
  fontWeight: "$bold",
  lineHeight: "$1",
  color: "$textSecondary",
  cursor: "pointer",

  whiteSpace: "nowrap",

  borderBottom: "2px solid transparent",

  "&:hover": {
    color: "$textMain",
  },

  variants: {
    selected: {
      true: {
        borderColor: "$textMain",
        color: "$black",
        pointerEvents: "none",
        zIndex: 0,
      },
    },
    tabSize: {
      small: {
        p: "8px 20px",
        fontSize: "14px",

        "@bp2": {
          fontSize: "16px",
        },
      },
      middle: {
        p: "12px 30px",
        fontSize: "18px",

        "@bp2": {
          p: "16px 40px",
          fontSize: "22px",
        },
      },
    },
  },

  defaultVariants: {
    tabSize: "middle",
  },
});

const StyledTileTab = styled("div", {
  color: "$textMain",
  cursor: "pointer",
  background: "$bgPrimary",

  whiteSpace: "nowrap",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "4px",

  "&:hover": {
    elementTransition: "background",
    background: "$bgBack",
  },

  variants: {
    selected: {
      true: {
        background: "$bgSelected",
        pointerEvents: "none",
      },
    },
    stretch: {
      true: {
        flexGrow: 1,
      },
    },
    fluid: {
      true: {
        "@bp2": {
          minWidth: "222px",
        },
      },
    },
    tabSize: {
      small: {
        p: "8px 20px",
        fontFamily: "$roboto",
        fontWeight: "$normal",
        fontSize: "14px",
        lineHeight: "$3",

        "@bp2": {
          fontSize: "16px",
          p: "8px 32px",
        },

        "&:first-child": {
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
        },
        "&:last-child": {
          borderTopRightRadius: "8px",
          borderBottomRightRadius: "8px",
        },
      },
      middle: {
        p: "20px 20px",

        fontWeight: "$bolder",
        fontSize: "16px",
        lineHeight: "$1",

        "&:first-child": {
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
        },
        "&:last-child": {
          borderTopRightRadius: "8px",
          borderBottomRightRadius: "8px",
        },

        "@bp2": {
          fontSize: "18px",
          p: "21px 30px",

          "&:first-child": {
            borderTopLeftRadius: "40px",
            borderBottomLeftRadius: "40px",
          },
          "&:last-child": {
            borderTopRightRadius: "40px",
            borderBottomRightRadius: "40px",
          },
        },
      },
    },
    withDivider: {
      true: {
        position: "relative",

        "& + &": {
          "&:before": {
            content: "",
            position: "absolute",
            left: "0",
            height: "21px",
            backgroundColor: "$gray",
            width: "1px",
          },
        },

        "@bp3": {
          "& + &": {
            "&:before": {
              content: "initial",
              position: "initial",
              left: "initial",
              height: "initial",
              backgroundColor: "none",
              width: "initial",
            },
          },
        },
      },
    },
    rounded: {
      true: {
        fontSize: "14px",
        borderRadius: "16px !important",
        p: "4px 12px",
        flexGrow: "0",
        border: "1px solid $gray",
      },
    },
  },

  defaultVariants: {
    tabSize: "small",
  },
});

const Count = styled("div", {
  background: "$bgPrimary",
  [darkThemeSelector]: {
    background: "$bgDeep",
  },
  borderRadius: "$round",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  fontFamily: "$roboto",
  fontWeight: "$bold",
  fontSize: "12px",
  color: "$textMain",

  variants: {
    size: {
      small: {
        width: "17px",
        height: "17px",
      },
      middle: {
        width: "20px",
        height: "20px",
      },
      big: {
        width: "25px",
        height: "25px",
      },
    },
  },
});

const _Tab = forwardRef<React.ElementRef<typeof StyledTab>, TabProps>(
  (
    {
      label,
      value,
      selected,
      onChange,
      variant,
      fluid,
      stretch,
      visible = true,
      tabSize,
      count,
      numeric = false,
      className,
      currency,
      ...restProps
    },
    forwardRef,
  ) => {
    const TabComponent = variant === "tab" ? StyledTab : StyledTileTab;

    if (!visible) return null;

    return (
      <TabComponent
        className={className}
        fluid={fluid}
        tabSize={tabSize}
        selected={selected}
        onClick={() => onChange && onChange(value)}
        stretch={stretch}
        ref={forwardRef}
        {...restProps}
      >
        {!numeric ? (
          label
        ) : (
          <NumberFormat value={label?.toString()} currency={currency} thousandSeparator="," displayType="text" />
        )}

        {typeof count === "number" && <Count size={count > 99 ? "big" : count > 9 ? "middle" : "small"}>{count}</Count>}
      </TabComponent>
    );
  },
);

/**
 * @deprecated
 */
export const Tab = memo(_Tab);
