import type { FC } from "react";

import { HookForm, SubmitButton, useControlledField, useHookForm } from "@/app/form";
import { useTranslation } from "@/hooks/translator.hook";
import { TextNumberInput } from "@/shared/ui";

enum Fields {
  CODE = "code",
}

type FormValues = {
  [Fields.CODE]: string;
};

type Props = {
  onSubmit: (values: FormValues) => void;
};

const Confirm2FAForm: FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();

  const form = useHookForm<FormValues>({
    defaultValues: {
      [Fields.CODE]: "",
    },
  });

  const { control } = form;

  const [field, { invalid, pending, errorMessage }] = useControlledField<FormValues>({
    name: Fields.CODE,
    control,
    rules: {
      required: t("two-factor.confirm.required-error")!,
    },
  });

  return (
    <HookForm onSubmit={onSubmit} form={form}>
      <TextNumberInput
        label={t("two-factor.confirm.label")}
        pending={pending}
        invalid={invalid}
        descriptor={errorMessage}
        {...field}
      />
      <SubmitButton className="mt-4" size="sm">
        {t("two-factor.confirm.action")}
      </SubmitButton>
    </HookForm>
  );
};

export { Confirm2FAForm };
