import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { IconWarning } from "@/domains/icons";
import { TerminalTableState } from "@/features/terminal/layout/context";
import { getTerminalRoute } from "@/routes/terminal.routes";
import { Button, Dialog, Text } from "@/shared/ui";

type Props = {
  onWithdraw: () => void;
  accountId: string;
};

const OpenOrdersContainer: FC<Props> = ({ accountId, onWithdraw }) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog.Header
        centered
        title={t("withdrawal.form.amount-step.dialog.title")}
        image={
          <Dialog.Image type="icon" size="md">
            <IconWarning className="text-warning-text" />
          </Dialog.Image>
        }
      />
      <Text align="center" color="secondary" variant="S / Regular">
        {t("withdrawal.form.amount-step.dialog.text")}
      </Text>
      <Dialog.Buttons direction="column">
        <Dialog.Close asChild>
          <Button onClick={onWithdraw}>{t("withdrawal.form.amount-step.dialog.button-1")}</Button>
        </Dialog.Close>
        <Button variant="secondary" asChild>
          <Link to={getTerminalRoute(accountId, TerminalTableState.OPEN)}>
            {t("withdrawal.form.amount-step.dialog.button-2")}
          </Link>
        </Button>
      </Dialog.Buttons>
    </>
  );
};

export { OpenOrdersContainer };
