import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useCopy } from "@/app/hooks";
import { IconCopy, IconWarningCircle } from "@/domains/icons";
import type { TwoFactorRecoveryCode } from "@/services/openapi";
import { Button, Dialog, InfoBlock, Text, toast, ToastIcons } from "@/shared/ui";

const getCodesString = (codes: TwoFactorRecoveryCode[]) => codes.map(({ code }) => code).join("\n");

type Props = {
  recoveryCodes: TwoFactorRecoveryCode[];
};

const RecoveryCodesDialogContent: FC<Props> = ({ recoveryCodes }) => {
  const { t } = useTranslation();

  const copyValue = useMemo(() => getCodesString(recoveryCodes), [recoveryCodes]);

  const { copy } = useCopy();

  return (
    <>
      <Dialog.Header title={t("two-factor.recovery-codes.dialog.title")} />
      <div className="flex flex-col gap-5">
        <InfoBlock
          variant="warning"
          icon={<IconWarningCircle />}
          text={t("two-factor.recovery-codes.dialog.description")}
        />
        <div className="grid grid-cols-2 gap-2 px-2">
          {recoveryCodes.map(({ code }) => (
            <Text variant="M / Regular" color="primary" key={code}>
              {code}
            </Text>
          ))}
        </div>
      </div>

      <Dialog.Buttons>
        <Button
          startSection={<IconCopy />}
          onClick={() =>
            copy(copyValue).then(() =>
              toast({
                title: t("common.copied")!,
                icon: ToastIcons.SUCCESS,
              }),
            )
          }
        >
          {t("two-factor.recovery-codes.dialog.action")}
        </Button>
      </Dialog.Buttons>
    </>
  );
};

export { RecoveryCodesDialogContent };
