import type { ComponentProps, FC } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import { FacebookIcon, GoogleIcon } from "@/components/icons";
import { useFacebookSignIn, useGoogleSignIn } from "@/features/auth/components/signin/signin.helpers";
import { styled } from "@/styles/stitches.config";
import type { CSS } from "@/styles/types";

/**
 * @deprecated
 */
const SocialButton = styled("button", {
  all: "unset",
  cursor: "pointer",

  padding: "14px 40px",
  background: "$white",
  border: "1px solid",
  borderColor: "$gray",
  borderRadius: "40px",

  fontFamily: "$roboto",
  fontWeight: "700",
  fontSize: "18px",
  color: "$black",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "16px",
});

interface ButtonProps extends ComponentProps<typeof SocialButton> {
  css?: CSS;
}

/**
 * @deprecated
 */
export const GoogleButton: FC<ButtonProps> = props => {
  const { signIn: signInGoogle } = useGoogleSignIn();

  return (
    <SocialButton {...props} type="button" onClick={() => signInGoogle()}>
      <GoogleIcon />
      Google
    </SocialButton>
  );
};

/**
 * @deprecated
 */
export const FacebookButton: FC<ButtonProps> = props => {
  const { callback, appId } = useFacebookSignIn();
  return (
    <FacebookLogin
      appId={appId}
      disableMobileRedirect
      callback={callback}
      render={({ onClick }) => (
        <SocialButton {...props} type="button" onClick={onClick}>
          <FacebookIcon />
          Facebook
        </SocialButton>
      )}
    />
  );
};
