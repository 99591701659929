import type { FC } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ProfileIcon } from "@/components/icons";
import { useAuth } from "@/contexts/auth.context";
import { cn } from "@/shared/styles";
import { Button, IconButton, Popover, popoverContentStyles, Text } from "@/shared/ui";

type Props = {
  open: boolean;
  email: string;
  onOpenChange: (value: boolean) => void;
};

const ProfileInfo: FC<Props> = ({ onOpenChange, open, email }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { logout } = useAuth();

  const signOut = useCallback(() => {
    logout(navigate);
  }, [navigate, logout]);

  return (
    <Popover modal open={open} onOpenChange={onOpenChange}>
      <Popover.Trigger asChild>
        <IconButton size="sm" variant="secondary">
          <ProfileIcon />
        </IconButton>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.PrimitiveContent
          align="end"
          sideOffset={15}
          className={cn(
            popoverContentStyles(),
            "w-[240px] rounded-lg bg-surface-elevation-2 p-6 shadow-modal outline-none",
          )}
        >
          <Text variant="S / Medium" color="primary" className="break-words">
            {email}
          </Text>
          <Button variant="secondary" fullWidth className="mt-4" size="sm" onClick={signOut}>
            {t("button.log-out")}
          </Button>
        </Popover.PrimitiveContent>
      </Popover.Portal>
    </Popover>
  );
};

export { ProfileInfo };
