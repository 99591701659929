import { type FC } from "react";
import { Trans } from "react-i18next";

import { IconWarningCircle } from "@/domains/icons";
import { InfoBlock, UnstyledButton } from "@/shared/ui";

type Props = {
  version: "5" | "4";
  onPasswordSelect: () => void;
};

const CreateAccountResultPasswordBlock: FC<Props> = ({ version, onPasswordSelect }) => {
  return (
    <InfoBlock
      variant="warning"
      icon={<IconWarningCircle />}
      text={
        <Trans
          i18nKey="accounts.create.dialog.password-info-block"
          values={{ version }}
          components={{
            button: (
              <UnstyledButton
                className="typography-S-Medium rounded-sm text-contrast-primary underline outline-none focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-accent-text"
                onClick={onPasswordSelect}
              />
            ),
          }}
        />
      }
    />
  );
};

export { CreateAccountResultPasswordBlock };
