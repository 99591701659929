import type { FC } from "react";

import { IconGoogleAuthenticatorColor, IconTick } from "@/domains/icons";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { CabinetHeader } from "@/layouts/cabinet/header";
import { CabinetPage } from "@/layouts/cabinet/page";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { Button, Dialog, Text } from "@/shared/ui";

import { type TwoFAStep } from "../../container";
import { CreateRecoveryCodesDialog } from "../create-recovery-codes/dialog";
import { Disable2FADialogContainer } from "./dialog/container";

type Props = {
  setStep: (step: TwoFAStep) => void;
  email: string;
};

const Disable2FAContainer: FC<Props> = ({ setStep, email }) => {
  const { t } = useTranslation();

  const [disableDialogOpen, { onOpenChange: onOpenChangeDisableDialog, close: closeDisableDialog }] = useDisclosure();

  return (
    <CabinetPage
      size="sm"
      header={
        <CabinetHeader backButton={<CabinetHeader.BackLink to={cabinetRoutes.profileSettings} />}>
          <CabinetHeader.Title>{t("two-factor.title")}</CabinetHeader.Title>
        </CabinetHeader>
      }
    >
      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between gap-3 rounded-lg border border-card-border bg-card-bg p-4">
          <div className="*:size-6">
            <IconGoogleAuthenticatorColor />
          </div>
          <Text className="grow" variant="M / Medium" color="primary">
            Google Authenticator
          </Text>
          <div className="text-positive-text *:size-6">
            <IconTick />
          </div>
        </div>

        <div className="flex flex-wrap items-center gap-3">
          <Dialog open={disableDialogOpen} onOpenChange={onOpenChangeDisableDialog}>
            <Dialog.Trigger asChild>
              <Button color="negative" size="sm">
                {t("two-factor.disable-button")}
              </Button>
            </Dialog.Trigger>
            <Dialog.Content>
              <Disable2FADialogContainer onClose={closeDisableDialog} setStep={setStep} />
            </Dialog.Content>
          </Dialog>
          <CreateRecoveryCodesDialog email={email}>
            <Button variant="secondary" size="sm">
              {t("two-factor.new-codes-button")}
            </Button>
          </CreateRecoveryCodesDialog>
        </div>
      </div>
    </CabinetPage>
  );
};

export { Disable2FAContainer };
