import type { FC } from "react";

import { PaymentLoader } from "@/features/payment/ui/loader";
import type { PaymentMethod, TradingAccount } from "@/services/openapi";
import { useMaximumWithdrawAmountQuery } from "@/state/server/payment";

import { AmountStep } from "./step";

type Props = {
  currentAccount: TradingAccount;
  currentPaymentMethod: PaymentMethod;
  currentAmount: string;
  setCurrentAmount: (value: string) => void;
  next: () => void;
};

const AmountStepContainer: FC<Props> = ({
  currentAccount,
  currentPaymentMethod,
  currentAmount,
  next,
  setCurrentAmount,
}) => {
  const { data: withdrawLimit } = useMaximumWithdrawAmountQuery({
    accountId: currentAccount.id,
    paymentMethodId: currentPaymentMethod.id,
    recurringId: currentPaymentMethod.recurringId!,
  });

  return withdrawLimit ? (
    <AmountStep
      currentAmount={currentAmount}
      currentAccount={currentAccount}
      next={next}
      setCurrentAmount={setCurrentAmount}
      currentLimitBeforeKyc={withdrawLimit.extendedInfo!.withdrawCurrentLimitNoKyc!}
      safeToWithdrawAmount={withdrawLimit.safeToWithdrawAmount!}
      limits={currentPaymentMethod.details!.limits!.find(({ currency }) => currency === currentAccount.currency)!}
    />
  ) : (
    <PaymentLoader />
  );
};

export { AmountStepContainer };
