import { cva } from "class-variance-authority";
import { Fragment, type ReactNode } from "react";

import { cn } from "@/shared/styles";

const wrapper = cva("grid grid-cols-1 gap-4 md:grid-cols-2")();

type Props<TData> = {
  cards: TData[] | undefined;
  children: (card: TData) => ReactNode;
  skeleton: ReactNode;
  emptyState?: ReactNode;
  button?: ReactNode;
};

const AccountsCardsWrapper = <TData,>({ children, cards, emptyState, skeleton, button }: Props<TData>) => {
  if (!cards) {
    return (
      <div className={wrapper}>
        {[...Array(2)].map((_, i) => (
          <Fragment key={i}>{skeleton}</Fragment>
        ))}
      </div>
    );
  }

  if (emptyState && cards.length === 0) {
    return emptyState as JSX.Element;
  }

  return (
    <div className={wrapper}>
      {cards.map(children)}
      {button && (
        <div
          className={cn("flex", {
            "md:col-span-2": cards.length % 2 === 0 && cards.length > 0,
          })}
        >
          {button}
        </div>
      )}
    </div>
  );
};

export { AccountsCardsWrapper };
