import { Slot, Slottable } from "@radix-ui/react-slot";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, type ReactNode } from "react";

import { Text, UnstyledButton } from "@/shared/ui";

const ActionList = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>((props, ref) => (
  <div ref={ref} {...props} />
));

type ItemProps = ComponentPropsWithoutRef<typeof UnstyledButton> & {
  title: ReactNode;
  icon?: ReactNode;
  actionIcon?: ReactNode;
  description?: ReactNode;
  endLabel?: ReactNode;
  asChild?: boolean;
};

const Item = forwardRef<ElementRef<typeof UnstyledButton>, ItemProps>(
  ({ disabled, title, description, endLabel, icon, actionIcon, asChild, children, ...props }, ref) => {
    const Component = asChild ? Slot : UnstyledButton;

    return (
      <Component
        className="group flex w-full items-center gap-3 bg-card-bg ps-4 text-contrast-primary outline-none transition-colors first:rounded-t-lg last:rounded-b-lg hover:bg-control-bg-hover focus-visible:outline-2 focus-visible:outline-offset-0 focus-visible:outline-accent-text active:bg-control-bg-active"
        disabled={disabled}
        {...props}
        ref={ref}
      >
        <Slottable>{children}</Slottable>
        <div className="shrink-0 *:size-6">{icon}</div>
        <div className="relative flex grow items-center gap-2 py-4 pe-4">
          <div className="flex grow items-center justify-between py-1">
            <div className="flex flex-col gap-1">
              <Text as="span" variant="M / Medium" color="primary">
                {title}
              </Text>
              {description && (
                <Text variant="S / Regular" color="secondary">
                  {description}
                </Text>
              )}
            </div>
            {endLabel && (
              <Text variant="S / Regular" color="secondary">
                {endLabel}
              </Text>
            )}
          </div>
          {actionIcon && <div className="shrink-0 text-contrast-secondary *:size-6">{actionIcon}</div>}
          <div className="absolute bottom-0 h-px w-full bg-contrast-quinary group-last:hidden group-hover:hidden group-focus-visible:hidden group-active:hidden" />
        </div>
      </Component>
    );
  },
);

const Component = Object.assign(ActionList, {
  Item,
});

export { Component as ActionList };
