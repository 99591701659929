import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { SubmitButton } from "@/app/form";

import { usePlaceOrderContext } from "../context";

type Props = {
  ask: ReturnType<typeof usePlaceOrderContext>["ask"];
  bid: ReturnType<typeof usePlaceOrderContext>["bid"];
  isBuyOrder: ReturnType<typeof usePlaceOrderContext>["isBuyOrder"];
  hasNoFreeMargin: ReturnType<typeof usePlaceOrderContext>["hasNoFreeMargin"];
  priceDecimalScale: number;
  isDemoAccount: boolean;
};

const PlaceOrderSignalSubmitButton: FC<Props> = ({
  hasNoFreeMargin,
  isBuyOrder,
  isDemoAccount,
  ask,
  bid,
  priceDecimalScale,
}) => {
  const { t } = useTranslation();

  const label = useMemo(() => {
    if (hasNoFreeMargin) {
      if (isDemoAccount) {
        return t("terminal.place-order.submit-button.deposit-demo");
      }
      return t("terminal.place-order.submit-button.deposit-real");
    }
    if (isBuyOrder) {
      return t("terminal.place-order.submit-button.signal-buy");
    }
    return t("terminal.place-order.submit-button.signal-sell");
  }, [hasNoFreeMargin, isBuyOrder, isDemoAccount, t]);

  return (
    <SubmitButton
      color={hasNoFreeMargin ? "accent" : isBuyOrder ? "positive" : "negative"}
      description={!hasNoFreeMargin && <NumberFormat value={isBuyOrder ? ask : bid} decimalScale={priceDecimalScale} />}
      fullWidth
    >
      {label}
    </SubmitButton>
  );
};

export { PlaceOrderSignalSubmitButton };
