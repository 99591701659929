import { createSearchParams, type To } from "react-router-dom";

import { TerminalTableState } from "@/features/terminal/layout/context";

const terminalRoutes = {
  terminal: "/web-trading/doto",
  terminalMobile: "/web-trading/doto-mobile",
  terminalMobileChart: "/web-trading/doto-mobile-chart",
};

const terminalRoutesArray = Object.values(terminalRoutes);
const isCurrentPageTerminal = (pathname: string): boolean => terminalRoutesArray.includes(pathname);

const terminalAccountParamsName = "accountId";

const getTerminalRoute = (accountId: string, tab?: TerminalTableState): To => ({
  pathname: terminalRoutes.terminal,
  search: createSearchParams({
    [terminalAccountParamsName]: accountId,
    tab: tab ? tab : TerminalTableState.NONE,
  }).toString(),
});

export { getTerminalRoute, terminalAccountParamsName, terminalRoutes, isCurrentPageTerminal };
