import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { IconTick } from "@/domains/icons";
import { getAccountRoute, getDepositRoute } from "@/routes/cabinet.routes";
import { type CreateTradingAccountResult, TradingServerPlatform } from "@/services/openapi";
import { Button, CopyInput, Dialog, Text } from "@/shared/ui";

import { CreateAccountResultPasswordBlock } from "../password-block";

type Props = {
  accountResult: CreateTradingAccountResult;
  onPasswordSelect: () => void;
  onClose: () => void;
};

const CreateAccountMT4Details: FC<Props> = ({ accountResult, onClose, onPasswordSelect }) => {
  const { t } = useTranslation();

  const { platform, serverAddress, login, pwd, id } = accountResult;

  const version = platform === TradingServerPlatform.MetaTrader5 ? "5" : "4";

  return (
    <>
      <Dialog.Header
        centered
        title={t("accounts.create.dialog.title")}
        image={
          <Dialog.Image type="icon">
            <Dialog.Icon type="positive">
              <IconTick />
            </Dialog.Icon>
          </Dialog.Image>
        }
      />
      <div className="flex flex-col gap-4">
        <Text variant="S / Regular" color="primary" align="center">
          {t("accounts.create.dialog.mt4-description")}
        </Text>
        <CopyInput label={t("accounts.common.server")} value={serverAddress ?? ""} />
        <CopyInput label={t("accounts.common.login")} value={String(login)} />
        <CopyInput label={t("accounts.common.mt-password", { version })} value={pwd ?? ""} />
        <CreateAccountResultPasswordBlock version={version} onPasswordSelect={onPasswordSelect} />
      </div>
      <Dialog.Buttons direction="column">
        <Button onClick={onClose} asChild>
          <Link to={getDepositRoute(id!)}>{t("accounts.buttons.deposit")}</Link>
        </Button>
        <Button onClick={onClose} variant="secondary" asChild>
          <Link to={getAccountRoute(id!)}>{t("accounts.buttons.account-details")}</Link>
        </Button>
      </Dialog.Buttons>
    </>
  );
};

export { CreateAccountMT4Details };
