import { forwardRef } from "react";

import { ErrorMessage } from "../../ErrorMessage/ErrorMessage";
import * as Styled from "./InputBase.styled";
import type { InputBaseProps } from "./InputBase.types";

// TODO: использовать TextField.Input из Radix или же Input из Shadcn
/**
 * @deprecated
 */
export const InputBase = forwardRef<HTMLInputElement, InputBaseProps>(
  (
    {
      value,
      onChange,
      disabled,
      // indentStart,
      // indentEnd,
      inputSize,
      iconStart,
      iconEnd,
      width,
      className,
      errorMessage,
      placeholder,
      dataTest,
      ...otherInputProps
    },
    ref,
  ) => {
    const hasErrorMessage = Boolean(errorMessage);

    // TODO: isIcon
    return (
      <Styled.Wrapper>
        <Styled.InputWrapper>
          {iconStart && <Styled.IconStart disabled={disabled}>{iconStart}</Styled.IconStart>}
          <Styled.Input
            ref={ref}
            className={className}
            inputSize={inputSize}
            value={value}
            onChange={onChange}
            data-test={dataTest}
            error={hasErrorMessage}
            disabled={disabled}
            indentStart={!!iconStart}
            indentEnd={!!iconEnd}
            placeholder={placeholder?.toString() ?? ""}
            {...otherInputProps}
          />
          {iconEnd && (
            <Styled.IconEnd
              disabled={disabled}
              // isIcon={isIconType(iconEnd)}
            >
              {/*{loading ? <Loader /> : iconComponent(iconEnd)}*/}
              {iconEnd}
            </Styled.IconEnd>
          )}
        </Styled.InputWrapper>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Styled.Wrapper>
    );
  },
);
InputBase.displayName = "InputBase";
