import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group";
import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

import { cn } from "@/shared/styles";

const rootStyles = cva(
  "group inline-flex items-center gap-1 rounded-full border border-input-border bg-input-bg p-0.5",
  {
    variants: {
      grow: {
        true: "flex *:flex-1",
      },
    },
  },
);

type Props<T extends string> = Omit<
  ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Root>,
  "defaultValue" | "value" | "onValueChange" | "type"
> &
  VariantProps<typeof rootStyles> & {
    defaultValue?: T;
    value?: T;
    onValueChange?: (value: T) => void;
  };

const ToggleGroup = <T extends string>({
  orientation = "horizontal",
  className,
  onValueChange,
  grow = true,
  ...props
}: Props<T>) => (
  <ToggleGroupPrimitive.Root
    className={cn(rootStyles({ grow }), className)}
    orientation={orientation}
    type="single"
    onValueChange={
      onValueChange
        ? value => {
            if (value) onValueChange(value as T);
          }
        : undefined
    }
    {...props}
  />
);

const itemStyles = cva([
  "typography-S-Regular flex h-7 items-center justify-center rounded-full border border-transparent px-3 text-contrast-secondary transition-colors hover:text-contrast-primary data-[state=on]:border-control-border data-[state=on]:bg-control-bg data-[state=on]:text-contrast-primary",
  "outline-none focus-visible:outline-2 focus-visible:outline-offset-0 focus-visible:outline-accent-text",
  "data-[disabled]:pointer-events-none data-[disabled]:text-contrast-tertiary",
])();

const Item = forwardRef<
  ElementRef<typeof ToggleGroupPrimitive.Item>,
  ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Item>
>(({ className, ...props }, ref) => {
  return <ToggleGroupPrimitive.Item className={cn(itemStyles, className)} {...props} ref={ref} />;
});

const Component = Object.assign(ToggleGroup, {
  Item,
});

export { Component as ToggleGroup };
