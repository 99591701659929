import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { DialogContent } from "@/components/dialog";
import { InfoDialog } from "@/components/info-dialog";
import { onExitDialog } from "@/features/onboarding/components/exit-dialog/store";
import { getAccountRoute } from "@/routes/cabinet.routes";
import { Dialog, OldButton } from "@/shared/ui";

import img from "./assets/pending.png";

type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
};

export const ExitOnboardingDialog: FC<Props> = ({ open, onOpenChange }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <InfoDialog
          imageSrc={img}
          title={t("onboarding.dialog.title")}
          description={t("onboarding.dialog.description")}
        >
          <div className="grid grow content-end gap-3">
            <OldButton variant="tertiary" className="w-full" onClick={() => navigate(getAccountRoute(""))}>
              {t("onboarding.dialog.explore-button")}
            </OldButton>
            <OldButton variant="secondary" className="w-full" onClick={() => onExitDialog(false)}>
              {t("onboarding.dialog.continue-button")}
            </OldButton>
          </div>
        </InfoDialog>
      </DialogContent>
    </Dialog>
  );
};
