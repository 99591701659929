import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { IconMoreVertical } from "@/domains/icons";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { type TradingAccount, TradingServerPlatform } from "@/services/openapi";
import { Dialog, LabelButton } from "@/shared/ui";

import { AccountMTDetailsDialogContainer } from "./dialog-container";

type Props = {
  account: TradingAccount;
};

const AccountMTDetailsButton: FC<Props> = ({ account }) => {
  const { t } = useTranslation();

  const [opened, { onOpenChange, close }] = useDisclosure();

  return (
    <Dialog open={opened} onOpenChange={onOpenChange}>
      <Dialog.Trigger asChild>
        <LabelButton icon={<IconMoreVertical />}>
          {account.platform! === TradingServerPlatform.MetaTrader5
            ? t("accounts.common.mt5-label")
            : t("accounts.common.mt4-label")}
        </LabelButton>
      </Dialog.Trigger>
      <Dialog.Content>
        <AccountMTDetailsDialogContainer account={account} onClose={close} />
      </Dialog.Content>
    </Dialog>
  );
};

export { AccountMTDetailsButton };
