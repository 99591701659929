import type { FC, FocusEventHandler, ReactNode } from "react";
import { useId, useState } from "react";
import DatePicker, { type DatePickerProps } from "react-date-picker";
import { useFormContext } from "react-hook-form";

import { Box } from "@/components/box";
import { CloseIcon, DatePickerIcon } from "@/components/icons";
import { ErrorMessage } from "@/domains/ui-kit";
import { styled } from "@/styles";
import { convertDateToISOString } from "@/utils/dates";

import { Error, Label } from "../styles";

const DateWrapper = styled("div", {
  abbr: {
    textDecoration: "none",
  },

  // styles for input
  ".react-date-picker": {
    width: "100%",
    input: {
      "&:focus-visible": { outline: "none" },
      "&::placeholder": {
        color: "$extraLightGray",
      },
    },

    "&__inputGroup__divider": {
      color: "$black",
    },
  },

  ".react-date-picker--is-empty": {
    "&::placeholder": {
      color: "$extraLightGray",
    },

    ".react-date-picker__inputGroup__divider": {
      color: "$extraLightGray",
    },
  },

  ".react-date-picker__wrapper": {
    transition: "all 0.15s ease",
    width: "100%",

    background: "$inputBgDefault",
    border: "1px solid $gray",
    p: "16px 18px",

    fontFamily: "$aeonik",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
    borderRadius: "16px",
    color: "$black",

    "&:hover": { background: "$inputBgHover" },
    "&:has(input:focus)": { borderColor: "$black" },
  },
  ".react-date-picker__button": {
    py: "0",
    "svg path": { transition: "stroke 0.15s ease", fill: "$darkGray" },
    "&:enabled:focus": { "svg path": { fill: "$black" } },
    "&:enabled:hover": { "svg path": { fill: "$black" } },
    "&:focus-visible": { outline: "none" },
  },
  ".react-date-picker .react-date-picker__calendar": {
    maxWidth: "100%",
  },

  ".react-date-picker__calendar-button": {
    pr: "0",
  },
  ".react-date-picker__inputGroup__input:invalid": {
    backgroundColor: "transparent",
  },
});

export type DateInputExternalProps = Omit<DatePickerProps, "onChange" | "value" | "minDate" | "maxDate"> & {
  value: string | null | undefined;
  onChange: (value: string | null | undefined) => void;
  onBlur?: FocusEventHandler;
  label?: ReactNode;
  minDate?: string;
  maxDate?: string;
  showIcon?: boolean;
};

type Props = DateInputExternalProps;
/**
 * @deprecated
 */
export const DateInput: FC<Props> = ({
  value,
  onChange,
  onBlur,
  maxDate,
  name,
  minDate,
  label,
  showIcon = true,
  ...restProps
}) => {
  const [innerValue, innerOnChange] = useState<Date | null | undefined>(
    value ? (convertDateToISOString(value, true) as Date) : undefined,
  );
  const id = useId();

  const { getFieldState, formState } = useFormContext();

  const { error } = getFieldState(name!, formState);

  return (
    <DateWrapper>
      {label && <Label label={label} name={id} />}
      <Box
        onBlur={onBlur}
        css={{
          // TODO: move out of component scope
          borderRadius: "16px",
          ".react-date-picker__wrapper": {
            border: error && "solid 1px $negativeDefault",
          },
        }}
      >
        <DatePicker
          {...restProps}
          onChange={(value: Date) => {
            innerOnChange(value);
            onChange(convertDateToISOString(value) as string);
          }}
          value={innerValue}
          locale="en"
          format="dd-MM-y"
          dayPlaceholder="DD"
          monthPlaceholder="MM"
          yearPlaceholder="YYYY"
          disableCalendar
          calendarIcon={showIcon ? <DatePickerIcon /> : <></>}
          clearIcon={<></>}
          name={id}
          className={!value ? "react-date-picker--is-empty" : ""}
          minDate={minDate ? (convertDateToISOString(minDate, true) as Date) : undefined}
          maxDate={maxDate ? (convertDateToISOString(maxDate, true) as Date) : undefined}
        />
      </Box>

      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </DateWrapper>
  );
};
