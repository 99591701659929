import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { IconChevronRight, IconMT } from "@/domains/icons";
import { AccountMTDetailsDialogContainer } from "@/entities/accounts/mt-details/dialog-container";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { type TradingAccount, TradingServerPlatform } from "@/services/openapi";
import { ActionList, Dialog } from "@/shared/ui";

type Props = {
  account: TradingAccount;
};

const MTDetailsActionButton: FC<Props> = ({ account }) => {
  const { t } = useTranslation();

  const [opened, { onOpenChange, close }] = useDisclosure();

  return (
    <Dialog open={opened} onOpenChange={onOpenChange}>
      <Dialog.Trigger asChild>
        <ActionList.Item
          title={
            t("accounts.common.mt-login-details", {
              version: account.platform === TradingServerPlatform.MetaTrader5 ? "5" : "4",
            })!
          }
          icon={<IconMT />}
          actionIcon={<IconChevronRight />}
        />
      </Dialog.Trigger>
      <Dialog.Content>
        <AccountMTDetailsDialogContainer account={account} onClose={close} initialStep="details" />
      </Dialog.Content>
    </Dialog>
  );
};

export { MTDetailsActionButton };
