import type { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useControlledField } from "@/app/form";
import { TextInput } from "@/shared/ui";

import { ProfileFields } from "./profile.form";

const NameField: FC = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [firstNameField, { invalid: isNameInvalid, errorMessage }] = useControlledField({
    name: ProfileFields.FIRST_NAME,
    control,
    rules: {
      required: t("onboarding.profile.name-error")!,
    },
  });

  return (
    <TextInput
      label={t("onboarding.profile.name")}
      invalid={isNameInvalid}
      descriptor={errorMessage}
      {...firstNameField}
    />
  );
};

export { NameField };
