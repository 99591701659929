import { type ElementRef, forwardRef, type ReactNode } from "react";

import { CopyTooltip } from "@/app/components";
import { useCopy } from "@/app/hooks";
import { IconCopy } from "@/domains/icons";

import { TextInput } from ".";

type Props = {
  value: string;
  label: ReactNode;
};

const CopyInput = forwardRef<ElementRef<typeof TextInput>, Props>(({ value, label }, ref) => {
  const { copy, isCopied } = useCopy();

  return (
    <TextInput
      label={label}
      readOnly
      value={value}
      endSection={
        <CopyTooltip open={isCopied}>
          <TextInput.IconButton onClick={() => copy(value)}>
            <IconCopy />
          </TextInput.IconButton>
        </CopyTooltip>
      }
      ref={ref}
    />
  );
});

export { CopyInput };
