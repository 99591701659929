import type { FC } from "react";
import { useFormContext } from "react-hook-form";

import { useControlledField } from "@/app/form";
import { useTranslation } from "@/hooks/translator.hook";
import type { SurveyField } from "@/services/openapi";
import { TextInput } from "@/shared/ui";

import { generateSurveyFieldId, generateSurveyFieldTitle } from "../survey.helpers";

type Props = {
  field: SurveyField;
};

export const SurveyStringField: FC<Props> = ({
  field: { title, id, type, value, placeholder, errorMessage: errorMessageProps },
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const [field, { invalid, errorMessage }] = useControlledField({
    name: generateSurveyFieldId(id!, type!),
    control,
    defaultValue: value ? value : void 0,
    rules: {
      required: errorMessageProps ?? t("form-errors.required-error")!,
    },
  });

  return (
    <TextInput
      {...field}
      invalid={invalid}
      descriptor={errorMessage}
      label={generateSurveyFieldTitle(title!)}
      placeholder={placeholder ? placeholder : undefined}
    />
  );
};
