import { css, styled } from "@/styles";

const tableCellStyles = css({
  p: "16px 12px",
  fontFamily: "$roboto",
  fontWeight: "$normal",
  fontSize: "16px",
  lineHeight: "$3",
  borderBottom: "1px solid $colors$bgBorder",
  whiteSpace: "nowrap",

  "@bp2": {
    p: "24px 16px",
  },

  variants: {
    align: {
      start: {
        textAlign: "start",
      },
      center: {
        textAlign: "center",
      },
      end: {
        textAlign: "end",
      },
    },
    wide: { true: { width: "100%" } },
  },
  defaultVariants: {
    align: "start",
  },
});

/**
 * @deprecated
 */
export const StyledTable = styled("table", {
  width: "100%",
  borderSpacing: 0,
});

/**
 * @deprecated
 */
export const Thead = styled("thead", {});
/**
 * @deprecated
 */
export const Tbody = styled("tbody", {});
/**
 * @deprecated
 */
export const TableRow = styled("tr", {
  variants: {
    hoverable: {
      true: {
        cursor: "pointer",
        "&:hover": {
          elementTransition: "backgroundColor",
          backgroundColor: "$bgPrimary",
        },
      },
    },
  },
});
/**
 * @deprecated
 */
export const TableHead = styled("th", tableCellStyles, {
  color: "$textSecondary",
});
/**
 * @deprecated
 */
export const TableCell = styled("td", tableCellStyles, {
  color: "$textMain",
});
