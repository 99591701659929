import type { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import { IconGlassCheckSmall } from "@/domains/ui-kit";
import { Button, Dialog, Text } from "@/shared/ui";

type Props = {
  value: string;
  currency: string;
};

const WithdrawalSuccessDialogContent: FC<Props> = ({ currency, value }) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog.Header
        centered
        title={t("withdrawal.status-dialog.success-title")}
        image={
          <Dialog.Image size="lg">
            <IconGlassCheckSmall />
          </Dialog.Image>
        }
      />
      <Text variant="S / Regular" color="primary" align="center">
        <Trans
          i18nKey="withdrawal.status-dialog.success-description"
          values={{
            value,
            symbol: currency,
          }}
        />
      </Text>
      <Dialog.Buttons>
        <Dialog.Close asChild>
          <Button>{t("button.got-it")}</Button>
        </Dialog.Close>
      </Dialog.Buttons>
    </>
  );
};

export { WithdrawalSuccessDialogContent };
