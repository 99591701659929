import { type FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getRealDemoAccounts } from "@/entities/accounts/helpers";
import { type TradingAccount } from "@/services/openapi";
import { DialogSelect } from "@/shared/ui";

import type { useTerminalAccountContext } from "../../contexts/account.context";
import { AccountDetailsSelectItem } from "./item";

type Props = {
  accounts: TradingAccount[];
  currentAccount: TradingAccount;
  setAccount: ReturnType<typeof useTerminalAccountContext>["setAccount"];
};

const AccountDetailsSelect: FC<Props> = ({ accounts, currentAccount, setAccount }) => {
  const { t } = useTranslation();

  const { demoAccounts, realAccounts } = useMemo(() => getRealDemoAccounts(accounts), [accounts]);

  return (
    <DialogSelect value={currentAccount.id!} onValueChange={setAccount}>
      <DialogSelect.Group>
        <DialogSelect.Label>{t("terminal.account-details.select.real-label")}</DialogSelect.Label>
        {realAccounts.map(account => (
          <AccountDetailsSelectItem
            key={account.id}
            account={account.id === currentAccount.id ? currentAccount : account}
          />
        ))}
      </DialogSelect.Group>
      <DialogSelect.Group>
        <DialogSelect.Label>{t("terminal.account-details.select.demo-label")}</DialogSelect.Label>
        {demoAccounts.map(account => (
          <AccountDetailsSelectItem
            key={account.id}
            account={account.id === currentAccount.id ? currentAccount : account}
          />
        ))}
      </DialogSelect.Group>
    </DialogSelect>
  );
};

export { AccountDetailsSelect };
