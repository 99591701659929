import { type FC, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { TooltipQuestionIcon } from "@/components/icons";
import { IconHint } from "@/domains/icons";
import { LabelButton, Tooltip } from "@/shared/ui";

import { AccountDetailsInfoBlock } from "../ui/info-block";
import { getMarginLevelInfo } from "./helpers";
import { MarginLevelProgress } from "./progress";

type Props = {
  marginLevel: number;
  hasOpenPositions: boolean;
  variant: "default" | "dimmed";
};

const AccountDetailsMarginLevelBlock: FC<Props> = ({ marginLevel, hasOpenPositions, variant }) => {
  const { t } = useTranslation();

  const marginLevelInfo = useMemo(() => getMarginLevelInfo({ marginLevel }), [marginLevel]);

  return (
    <AccountDetailsInfoBlock
      variant={variant}
      title={t("terminal.account-details.margin-level.title")}
      subText={
        hasOpenPositions ? (
          <NumberFormat value={marginLevelInfo.value} suffix="%" />
        ) : (
          t("terminal.account-details.margin-level.no-trades")
        )
      }
      subTextColor={hasOpenPositions ? (marginLevelInfo.type === "safe" ? "positive" : "negative") : void 0}
      subTextSize={hasOpenPositions ? "md" : "sm"}
      endBlock={
        hasOpenPositions ? (
          <Tooltip
            content={
              marginLevelInfo.type === "safe"
                ? t("terminal.account-details.margin-level.safe-description")
                : t("terminal.account-details.margin-level.margin-call-description")
            }
          >
            <LabelButton
              type="tooltip"
              variant={
                marginLevelInfo.type === "danger"
                  ? "negative"
                  : marginLevelInfo.type === "warning"
                    ? "warning"
                    : "positive"
              }
              icon={<IconHint />}
            >
              {marginLevelInfo.type === "safe"
                ? t("terminal.account-details.margin-level.safe")
                : t("terminal.account-details.margin-level.margin-call")}
            </LabelButton>
          </Tooltip>
        ) : (
          <Tooltip content={t("terminal.account-details.margin-level.description")}>
            <TooltipQuestionIcon size="sm" />
          </Tooltip>
        )
      }
    >
      {hasOpenPositions && <MarginLevelProgress className="mt-2" marginLevelInfo={marginLevelInfo} />}
    </AccountDetailsInfoBlock>
  );
};

const Component = memo(AccountDetailsMarginLevelBlock);

export { Component as AccountDetailsMarginLevelBlock };
