import { type FC, memo } from "react";
import { useTranslation } from "react-i18next";

import { AddFundsButtonContainer } from "@/features/account/buttons/add-funds.container";
import { type TradingAccountAttributes, TradingAccountType } from "@/services/openapi";
import { Button, Popover } from "@/shared/ui";

type Props = {
  accountsCount: number;
  accountType: TradingAccountType;
  accountId: string;
  currency: string;
  currencyDecimalScale: number;
  balance: number;
  attributes: TradingAccountAttributes;
  onRealSwitch: () => void;
  onDemoSwitch: () => void;
  onSwitchAccounts: () => void;
};

const AccountDetailsFooter: FC<Props> = ({
  accountType,
  accountsCount,
  accountId,
  attributes,
  balance,
  currency,
  currencyDecimalScale,
  onRealSwitch,
  onDemoSwitch,
  onSwitchAccounts,
}) => {
  const { t } = useTranslation();

  const isDemo = accountType === TradingAccountType.Demo;

  return (
    <Popover.Footer>
      <Popover.Close asChild>
        {accountsCount > 2 ? (
          <Button onClick={onSwitchAccounts}>{t("terminal.account-details.buttons.switch-accounts")}</Button>
        ) : isDemo ? (
          <Button onClick={onRealSwitch}>{t("terminal.account-details.buttons.switch-to-real")}</Button>
        ) : (
          <Button onClick={onDemoSwitch}>{t("terminal.account-details.buttons.switch-to-demo")}</Button>
        )}
      </Popover.Close>
      {isDemo && (
        <AddFundsButtonContainer
          accountId={accountId}
          attributes={attributes}
          balance={balance}
          currency={currency}
          currencyDecimalScale={currencyDecimalScale}
        >
          <Button variant="secondary">{t("terminal.account-details.buttons.add-funds")}</Button>
        </AddFundsButtonContainer>
      )}
    </Popover.Footer>
  );
};

const Component = memo(AccountDetailsFooter);

export { Component as AccountDetailsFooter };
