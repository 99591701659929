import { type ComponentPropsWithoutRef, type ElementRef, type FC, forwardRef, type ReactNode } from "react";

import { Button as UIButton, Text } from "@/shared/ui";

const Button = forwardRef<ElementRef<typeof UIButton>, ComponentPropsWithoutRef<typeof UIButton>>((props, ref) => (
  <UIButton size="sm" variant="secondary" {...props} ref={ref} />
));

type Props = {
  title: string;
  description: string;
  action?: ReactNode;
};

const EmptyState: FC<Props> = ({ title, description, action }) => (
  <div className="flex flex-col items-center gap-4">
    <div className="flex flex-col gap-2">
      <Text as="h5" align="center" color="primary" variant="M Compact / Medium">
        {title}
      </Text>
      <Text align="center" color="secondary" variant="S / Regular">
        {description}
      </Text>
    </div>
    {action}
  </div>
);

const Component = Object.assign(EmptyState, { Button });

export { Component as TradingTableEmptyState };
