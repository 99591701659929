import { type FC, memo } from "react";
import { useController, useFormContext } from "react-hook-form";

import { DateInput, type DateInputExternalProps } from "../../inputs";
import { type ControllerRules } from "../../types";

type Props = Omit<DateInputExternalProps, "onChange" | "value"> & {
  name: string;
  rules?: ControllerRules;
  onBlurValidation?: (date: string | null | undefined) => void;
};

export const _DateField: FC<Props> = ({ name, rules, defaultValue, onBlurValidation, ...restProps }) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
  } = useController({ name, control, rules, defaultValue });

  const handleBlur = () => onBlurValidation && onBlurValidation(value);

  return <DateInput {...restProps} onChange={onChange} onBlur={handleBlur} name={name} value={value} />;
};

/**
 * @deprecated
 */
export const DateField = memo(_DateField);
