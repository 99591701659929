import { requestOneTimeCode, verifyOneTimeCode } from "@/services/ot-codes";
import type { ApiRequestParamsType, ApiResponseType } from "@/services/types";
import { useBaseMutation } from "@/state/server";
import type { MutationOptionsType } from "@/state/server/types";

export const useVerifyOneTimeCodeMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof verifyOneTimeCode>,
    ApiRequestParamsType<typeof verifyOneTimeCode>
  >,
) => {
  return useBaseMutation<ApiResponseType<typeof verifyOneTimeCode>, ApiRequestParamsType<typeof verifyOneTimeCode>>(
    verifyOneTimeCode,
    options,
  );
};

export const useRequestOneTimeCodeMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof requestOneTimeCode>,
    ApiRequestParamsType<typeof requestOneTimeCode>
  >,
) => {
  return useBaseMutation<ApiResponseType<typeof requestOneTimeCode>, ApiRequestParamsType<typeof requestOneTimeCode>>(
    requestOneTimeCode,
    options,
  );
};
