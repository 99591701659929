import type { FC, ReactNode } from "react";

import { Text } from "@/shared/ui";

type Props = {
  icon?: ReactNode;
  text: ReactNode;
};

const EmptyState: FC<Props> = ({ text, icon }) => {
  return (
    <div className="flex min-h-[156px] flex-col items-center justify-center gap-3 rounded-lg bg-card-bg px-10 py-8">
      <div className="grid size-14 place-items-center rounded-full bg-contrast-quinary text-contrast-secondary">
        {icon}
      </div>
      <Text align="center" variant="M / Medium" color="primary">
        {text}
      </Text>
    </div>
  );
};

export { EmptyState };
