import type { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import { Box } from "@/components/box";
import { ArrowLeftIcon } from "@/components/icons";
import { Text } from "@/components/text";
import { UnstyledButton } from "@/components/unstyled-button.tsx";
import { styled } from "@/styles/stitches.config";

const Container = styled(Box, {
  position: "relative",
  height: "100%",
  background: "$white",

  pt: "14px",
  pb: "30px",

  "@bp3": {
    maxWidth: "585px",
    borderRadius: "24px",
    py: "32px",
    height: "auto",
  },
});

const DesktopButton = styled(UnstyledButton, {
  display: "none",

  "@bp3": {
    background: "$white",
    boxShadow: "0px 10px 40px -20px rgba(0, 0, 0, 0.1)",

    borderRadius: "40px",
    width: "56px",
    height: "56px",
    display: "grid",
    placeItems: "center",
    position: "absolute",
    top: "32px",
    left: "-28px",
  },
});

interface Props {
  title: string;
  children: ReactNode;
  backLink?: boolean;
}

export const AuthFormContainer: FC<Props> = ({ children, title, backLink }) => {
  const navigate = useNavigate();

  return (
    <Container>
      {backLink && (
        <DesktopButton
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowLeftIcon />
        </DesktopButton>
      )}
      {backLink && (
        <button
          className="absolute start-5 top-5 outline-none lg:hidden"
          type="button"
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowLeftIcon />
        </button>
      )}
      <Text
        as="h1"
        size={{ "@initial": "5", "@bp3": "7" }}
        weight="bold"
        align="center"
        css={{ maxWidth: "70%", mx: "auto", mb: "35px", "@bp3": { maxWidth: "100%" } }}
      >
        {title}
      </Text>
      {children}
    </Container>
  );
};
