import type { ComponentPropsWithoutRef, ElementRef, FC } from "react";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import { TradingAccountType } from "@/services/openapi";
import { Label } from "@/shared/ui";

type Props = ComponentPropsWithoutRef<typeof Label> & {
  type: TradingAccountType;
};

const AccountBadge: FC<Props> = forwardRef<ElementRef<typeof Label>, Props>(({ type, ...props }, ref) => {
  const { t } = useTranslation();

  const isReal = type === TradingAccountType.Real;

  return (
    <Label variant={isReal ? "info" : "warning"} {...props} ref={ref}>
      {isReal ? t("common.real") : t("common.demo")}
    </Label>
  );
});

export { AccountBadge };
