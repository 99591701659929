import { ArchivedAccountCard } from "@/entities/accounts/archived/card";
import { AccountCard } from "@/entities/accounts/card";
import { AvailabilityStatus, TradingAccountSorting } from "@/services/openapi";
import { useAllAccountsQuery } from "@/state/server/accounts";

import { AccountsCardsWrapper } from "../cards-wrapper";
import { ArchivedAccountsEmptyState } from "./empty-state";

const ArchivedCardsContainer = () => {
  const { data } = useAllAccountsQuery({
    status: AvailabilityStatus.Archived,
    sorting: TradingAccountSorting.Oldest,
  });

  return (
    <AccountsCardsWrapper cards={data} skeleton={<AccountCard.Skeleton />} emptyState={<ArchivedAccountsEmptyState />}>
      {card => <ArchivedAccountCard key={card.id} account={card} />}
    </AccountsCardsWrapper>
  );
};

export { ArchivedCardsContainer };
