import { CookieType } from "@/constants/enums";
import { ClientPlatform, TradingServerPlatform } from "@/services/openapi";

const postponeFunc = (func?: Function) =>
  setTimeout(() => {
    func && func();
  }, 1000);

export const postponeCallback = (callback?: Function) => {
  postponeFunc(callback);
};

export const getCookie = (name: string): string | void => {
  const cookies = document.cookie.split(";");
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split("=");
    if (cookieName!.trim() === name) {
      return cookieValue;
    }
  }
  return;
};

export const isIos = () => /iPhone|iPad|iPod/i.test(navigator.userAgent);

export const getBrowserVersion = (): string => {
  const userAgent = navigator.userAgent;
  let version;

  switch (true) {
    case userAgent.indexOf("Chrome") !== -1:
      version = userAgent.match(/Chrome\/(\d+)/)?.[1];
      break;
    case userAgent.indexOf("Safari") !== -1:
      version = userAgent.match(/Safari\/(\d+)/)?.[1];
      break;
    case userAgent.indexOf("Firefox") !== -1:
      version = userAgent.match(/Firefox\/(\d+)/)?.[1];
      break;
    case userAgent.indexOf("MSIE") !== -1:
      version = userAgent.match(/MSIE (\d+)/)?.[1];
      break;
    case userAgent.indexOf("Trident") !== -1:
      version = userAgent.match(/rv:(\d+)/)?.[1];
      break;
    default:
      version = "unknown";
  }

  return version || "unknown";
};

export const getIsMobile = (): boolean => {
  const userAgent = window.navigator ? window.navigator.userAgent : "";
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
};

type OSType = "Android" | "IOS" | "MacOS" | "Windows" | "Linux" | "Unknown";

export const getUserPlatformAndOS = (): { platform: ClientPlatform | string; os: OSType } => {
  const userAgent = navigator.userAgent.toLowerCase();
  let platform = ClientPlatform.None;
  let os: OSType = "Unknown";
  const url = new URL(window.location.href);
  const isWebView = new URLSearchParams(url.search).has("isWebView");

  const platformRegex: { [key: string]: { platform: ClientPlatform; os: OSType } } = {
    android: {
      platform: ClientPlatform.Web,
      os: "Android",
    },
    iphone: {
      platform: ClientPlatform.Web,
      os: "IOS",
    },
    ipad: {
      platform: ClientPlatform.Web,
      os: "IOS",
    },
    ipod: {
      platform: ClientPlatform.Web,
      os: "IOS",
    },
    mac: {
      platform: ClientPlatform.Web,
      os: "MacOS",
    },
    win: {
      platform: ClientPlatform.Web,
      os: "Windows",
    },
    linux: {
      platform: ClientPlatform.Web,
      os: "Linux",
    },
  };

  for (const regex in platformRegex) {
    if (platformRegex.hasOwnProperty(regex) && userAgent.indexOf(regex) !== -1) {
      const { platform: lookupPlatform, os: lookupOS } = platformRegex[regex]!;
      platform = lookupPlatform;
      os = lookupOS;
      break;
    }
  }

  return {
    platform: isWebView ? os : platform,
    os,
  };
};

export const requestPushNotificationPermission = async (): Promise<boolean> => {
  if (!("Notification" in window)) {
    console.log("Permission denied");
    return false;
  }

  if (Notification.permission === "granted") {
    console.log("Permission granted");
    return true;
  }

  if (Notification.permission !== "denied") {
    const permission = await Notification.requestPermission();
    return permission === "granted";
  }

  return false;
};

export const getIsCookieAccepted = (value: CookieType) => {
  if (value === CookieType.ACCEPT_ALL) return true;
  else return false;
};
