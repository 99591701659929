import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { Link, type To } from "react-router-dom";

import type { PaymentMethod } from "@/services/openapi";

import { PaymentEmptyState } from "../../ui/empty-state";
import { PaymentNoAccounts } from "../../ui/no-accounts";

type Props = {
  paymentMethods: PaymentMethod[];
  link: To;
};

const DepositNoAccounts: FC<Props> = ({ paymentMethods, link }) => {
  const { t } = useTranslation();

  return (
    <PaymentNoAccounts paymentMethods={paymentMethods}>
      <PaymentEmptyState
        title={t("deposit.info.create-profile.title")}
        description={t("deposit.info.create-profile.text-1")}
      >
        <PaymentEmptyState.Button asChild>
          <Link to={link}>{t("deposit.info.create-profile.button")}</Link>
        </PaymentEmptyState.Button>
      </PaymentEmptyState>
    </PaymentNoAccounts>
  );
};

export { DepositNoAccounts };
