import type { ComponentProps } from "react";

import { $firebaseNotification } from "@/app/libs/firebase/store";
import { setLiveChatVisibility } from "@/app/libs/liveChat";
import { router } from "@/routes";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { getTerminalRoute } from "@/routes/terminal.routes";
import { cn } from "@/shared/styles";
import { OldButton, Toast, toast, ToastIcons } from "@/shared/ui";

import type { FcmNotification, FcmNotificationButton, FcmNotificationContent } from "./types";
import { FcmNotificationColor, FcmNotificationShowMode, FirebaseActions } from "./types";

const getIcon = (icon: FcmNotificationContent["icon"]) => {
  switch (icon) {
    case "success":
      return ToastIcons.SUCCESS;
    case "fail":
      return ToastIcons.ERROR;
    case "waiting":
      return ToastIcons.WAITING;

    default:
      return icon;
  }
};

const useFirebaseToastAction = () => {
  const navigate = router.navigate;

  return {
    action: (action: FirebaseActions) => {
      if (action === FirebaseActions.CONTACT_SUPPORT) setLiveChatVisibility("maximized");
      if (action === FirebaseActions.MAKE_DEPOSIT) navigate(cabinetRoutes.deposit);
      if (action === FirebaseActions.TRANSACTION_DETAILS) navigate(cabinetRoutes.transactionHistory);
    },
  };
};

const useFirebasePopupAction = () => {
  const navigate = router.navigate;

  return {
    action: (action: FirebaseActions) => {
      if (action === FirebaseActions.CONTACT_SUPPORT) setLiveChatVisibility("maximized");
      if (action === FirebaseActions.MAKE_DEPOSIT) navigate(cabinetRoutes.deposit);
      if (action === FirebaseActions.TRANSACTION_DETAILS) navigate(cabinetRoutes.transactionHistory);
      if (action === FirebaseActions.TERMINAL_OPEN) navigate(getTerminalRoute(""));
    },
  };
};

const ActionButton = (props: FcmNotificationButton) => {
  const { action, text, color, id, url } = props;
  const { action: actionFunction } = useFirebaseToastAction();

  return (
    <Toast.Action
      altText={text!}
      className="p-0 text-start"
      variant={color?.toLowerCase() as ComponentProps<typeof Toast.Action>["variant"]}
      onClick={() => actionFunction(action! as FirebaseActions)}
    >
      {text}
    </Toast.Action>
  );
};

const PopupActionButton = (props: FcmNotificationButton & ComponentProps<typeof OldButton>) => {
  const { color, className, size, asChild, children, action, onClick } = props;
  const { action: actionFunction } = useFirebasePopupAction();

  switch (color) {
    case FcmNotificationColor.Inverted:
      return (
        <OldButton
          onClick={e => {
            actionFunction(action! as FirebaseActions);
            onClick && onClick(e);
          }}
          asChild={asChild}
          size={size}
          className={className}
          variant="secondary"
        >
          {children}
        </OldButton>
      );
    case FcmNotificationColor.Success:
      return (
        <OldButton
          onClick={e => {
            actionFunction(action! as FirebaseActions);
            onClick && onClick(e);
          }}
          asChild={asChild}
          size={size}
          className={className}
        >
          {children}
        </OldButton>
      );
    case FcmNotificationColor.Warning:
      return (
        <OldButton
          onClick={e => {
            actionFunction(action! as FirebaseActions);
            onClick && onClick(e);
          }}
          variant="flat"
          asChild={asChild}
          size={size}
          className={cn("text-negative-bg", className)}
        >
          {children}
        </OldButton>
      );

    default:
      return (
        <OldButton
          onClick={e => {
            actionFunction(action! as FirebaseActions);
            onClick && onClick(e);
          }}
          asChild={asChild}
          size={size}
          className={className}
        >
          {children}
        </OldButton>
      );
  }
};

const showFcmNotification = (notification: FcmNotification) => {
  const { content, buttons, showMode } = notification;

  const isValidNotification = content?.title && content?.text && showMode;

  if (!isValidNotification) return;

  const { text, title, icon } = content;

  if (showMode === FcmNotificationShowMode.Default) {
    toast({
      title: title!,
      icon: getIcon(icon),
      description: text,
      action: buttons?.map(button => <ActionButton key={button.id + button.text!} {...button} />),
    });
  }
  if (showMode === FcmNotificationShowMode.PopUp) {
    $firebaseNotification.set(notification);
  }
};

export { PopupActionButton, showFcmNotification };
