import type { FC, ReactNode } from "react";

import { cn } from "@/shared/styles";

const Table: FC<{ children?: ReactNode }> = ({ children }) => {
  return <table>{children}</table>;
};

const HeadRow: FC<{ children?: ReactNode }> = ({ children }) => {
  return <tr>{children}</tr>;
};

const Head: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <th className="text-start font-roboto text-[14px] font-normal leading-[15px] text-text-secondary first:pl-5">
      {children}
    </th>
  );
};

const CellRow: FC<{ children?: ReactNode; isColored?: boolean }> = ({ children, isColored }) => {
  return (
    <tr
      className={cn("border-b-[0.5px] border-[#D6D7DB]", {
        "border-none": isColored,
      })}
      style={
        isColored
          ? {
              // FIXME:  add to tailwind
              background: "linear-gradient(277deg, #67E15D -22.88%, #F4F85A 129.61%)",
            }
          : undefined
      }
    >
      {children}
    </tr>
  );
};

const Cell: FC<{ children?: ReactNode; className?: string }> = ({ children, className }) => {
  return (
    <td
      className={cn(
        "py-3 text-left font-roboto text-[14px] font-normal leading-[15px] text-text first:rounded-s-lg first:pl-5 last:rounded-e-lg",
        className,
      )}
    >
      {children}
    </td>
  );
};

const component = Object.assign(Table, {
  HeadRow,
  Head,
  Cell,
  CellRow,
});

export { component as TournamentTable };
