import type { FC } from "react";

import { AvailabilityStatus, TradingAccountSorting } from "@/services/openapi";
import { useAllAccountsQuery } from "@/state/server/accounts";

import { useTerminalAccountContext } from "../contexts/account.context";
import { useOrdersContext } from "../orders/context";
import { AccountDetails } from "./account-details";
import { AccountDetailsSkeleton } from "./skeleton";

const AccountDetailsContainer: FC = () => {
  const { account, setAccount, showBonus } = useTerminalAccountContext();

  const { openOrders, ordersIsLoading } = useOrdersContext();

  const { data: accounts } = useAllAccountsQuery(
    {
      isTerminalEnabledOnly: true,
      status: AvailabilityStatus.Active,
      sorting: TradingAccountSorting.Newest,
    },
    { refetchOnWindowFocus: false },
  );

  const hasOpenPositions = openOrders.length > 0;

  if (!accounts || ordersIsLoading) {
    return <AccountDetailsSkeleton />;
  }

  return (
    <AccountDetails
      accounts={accounts}
      hasOpenPositions={hasOpenPositions}
      setAccount={setAccount}
      showBonus={showBonus}
      account={account}
    />
  );
};

export { AccountDetailsContainer };
