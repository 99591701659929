import { type FC, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { getRealDemoAccounts } from "@/entities/accounts/helpers";
import { getDepositRoute } from "@/routes/cabinet.routes";
import { type TradingAccount } from "@/services/openapi";

import { AccountDetailsBonusRiskBlock } from "./bonus-risk/block";
import { AccountsDetailsDataList } from "./data-list";
import { AccountDetailsFooter } from "./footer";
import { AccountDetailsMarginLevelBlock } from "./margin-level/block";

type Props = {
  accounts: TradingAccount[];
  currentAccount: TradingAccount;
  showBonus: boolean;
  hasOpenPositions: boolean;
  setAccount: (accountId: string) => void;
  openSelect: () => void;
};

const AccountDetailsContent: FC<Props> = ({
  accounts,
  currentAccount,
  showBonus,
  hasOpenPositions,
  setAccount,
  openSelect,
}) => {
  const navigate = useNavigate();

  const {
    id,
    equity,
    marginLevel,
    margin,
    pnL,
    digits,
    marginFree,
    credit,
    balance,
    type,
    login,
    attributes,
    currency,
  } = currentAccount;

  const { demoAccounts, realAccounts } = useMemo(() => getRealDemoAccounts(accounts), [accounts]);

  const onDeposit = useCallback(() => {
    navigate(getDepositRoute(id!));
  }, [id, navigate]);

  const onRealSwitch = useCallback(() => {
    const realAccount = realAccounts[0];
    if (realAccount) {
      setAccount(realAccount.id!);
    }
  }, [realAccounts, setAccount]);

  const onDemoSwitch = useCallback(() => {
    const demoAccount = demoAccounts[0];
    if (demoAccount) {
      setAccount(demoAccount.id!);
    }
  }, [demoAccounts, setAccount]);

  return (
    <>
      <div className="flex flex-col gap-4">
        {showBonus ? (
          <AccountDetailsBonusRiskBlock
            variant="dimmed"
            credit={credit!}
            currency={currency!}
            currencyDecimalScale={digits!}
            equity={equity!}
            onDeposit={onDeposit}
          />
        ) : (
          <AccountDetailsMarginLevelBlock
            variant="dimmed"
            marginLevel={marginLevel!}
            hasOpenPositions={hasOpenPositions}
          />
        )}
        <AccountsDetailsDataList
          showBonus={showBonus}
          accountCurrency={currency!}
          equity={equity!}
          balance={balance!}
          credit={credit!}
          margin={margin!}
          marginFree={marginFree!}
          pnl={pnL!}
          currencyDecimalScale={digits!}
          marginLevel={marginLevel!}
          type={type!}
          login={login!}
        />
      </div>
      <AccountDetailsFooter
        accountId={id!}
        attributes={attributes!}
        balance={balance!}
        currency={currency!}
        currencyDecimalScale={digits!}
        accountType={currentAccount.type!}
        accountsCount={accounts.length}
        onDemoSwitch={onDemoSwitch}
        onRealSwitch={onRealSwitch}
        onSwitchAccounts={openSelect}
      />
    </>
  );
};

export { AccountDetailsContent };
