import { cva, type VariantProps } from "class-variance-authority";
import { type FC, type ReactNode } from "react";

import { cn } from "@/shared/styles";
import { Spinner } from "@/shared/ui";

const Banner: FC<{ children?: ReactNode }> = ({ children }) => {
  return <div className="mb-3">{children}</div>;
};

const Loader: FC = () => {
  return (
    <div className="grid place-items-center *:size-6">
      <Spinner />
    </div>
  );
};

const cabinetContainerStyles = cva("mx-auto w-full px-4 lg:px-40", {
  variants: {
    size: {
      sm: "max-w-[552px] lg:max-w-[840px]",
      md: "max-w-[752px] lg:max-w-[1040px]",
    },
  },
});

type Props = VariantProps<typeof cabinetContainerStyles> & {
  header: ReactNode;
  banner?: ReactNode;
  children?: ReactNode;
};

const CabinetPage: FC<Props> = ({ header, children, banner, size = "md" }) => {
  return (
    <main className={cn(cabinetContainerStyles({ size }), "pb-6 lg:pb-16 lg:pt-6")}>
      {header}
      {banner}
      {children}
    </main>
  );
};

const Component = Object.assign(CabinetPage, { Banner, Loader });

export { cabinetContainerStyles, Component as CabinetPage };
