import { type FC } from "react";

import { Skeleton } from "@/shared/ui";

const ChartSymbolsSkeleton: FC = () => (
  <div className="flex gap-2 overflow-auto lg:flex-row-reverse lg:justify-end">
    <div className="flex flex-row-reverse gap-2 overflow-auto scrollbar-hide">
      <Skeleton className="h-12 w-28 rounded-md lg:h-[54px] lg:w-[176px] lg:rounded-lg" />
      <Skeleton className="h-12 w-28 rounded-md lg:h-[54px] lg:w-[176px] lg:rounded-lg" />
      <Skeleton className="h-12 w-28 rounded-md lg:h-[54px] lg:w-[176px] lg:rounded-lg" />
    </div>
    <Skeleton className="h-12 w-10 rounded-md lg:h-[54px]" />
  </div>
);

export { ChartSymbolsSkeleton };
