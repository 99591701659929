import { styled } from "@/styles";

import { mixinsCommon } from "../../mixins/common";
import { iconEnd, iconStart } from "../../mixins/input";

/**
 * @deprecated
 */
export const Wrapper = styled("div", {
  position: "relative",
  width: "100%",
});
/**
 * @deprecated
 */
export const InputWrapper = styled("div", {
  position: "relative",
  // width: "100%",
});

/**
 * @deprecated
 */
export const Input = styled("input", {
  width: "100%",
  gap: "12px",
  px: "14px",

  border: "1px solid $gray",
  borderRadius: "16px",
  boxSizing: "border-box",
  background: "$inputBgDefault",
  transition: "all 0.15s ease",
  color: "$dark",

  fontFamily: "$aeonik",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "20px",

  "&::placeholder": {
    color: "$textPlaceholder",
  },

  "&:hover": {
    background: "$inputBgHover",
  },

  "&:focus": {
    borderColor: "$black",
  },

  "&:focus-visible": {
    outline: "none",
  },

  variants: {
    inputSize: {
      s: {
        height: "46px",
      },
      m: {
        height: "54px",
      },
      l: {
        height: "62px",
      },
    },
    indentStart: {
      true: {
        paddingLeft: "50px",
      },
    },
    indentEnd: {
      true: {
        paddingRight: "50px",
      },
    },
    disabled: {
      true: mixinsCommon.disabled!,
    },
    error: {
      true: {
        borderColor: "$negativeDefault",

        "&:focus": {
          borderColor: "$negativeDefault",
          boxShadow: "0px 0px 0px 2px rgba(255, 52, 52, 0.12)",
        },
      },
    },
  },

  defaultVariants: {
    inputSize: "s",
  },
});

/**
 * @deprecated
 */
export const IconStart = styled(iconStart, {});
/**
 * @deprecated
 */
export const IconEnd = styled(iconEnd, {});
