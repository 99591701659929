import { shutdown } from "@intercom/messenger-js-sdk";
import { onMessage } from "firebase/messaging";
import type { Dispatch, SetStateAction } from "react";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { flushSync } from "react-dom";
import { useQueryClient } from "react-query";
import type { NavigateFunction } from "react-router-dom";

import { deleteFcmToken, getFirebaseToken, messaging, showFcmNotification } from "@/app/libs/firebase";
import { resetUserContext } from "@/app/libs/sentry";
import { CookieType } from "@/constants/enums";
import { useBloomreach } from "@/hooks/bloomreach.hook";
import { useLocalStorage } from "@/hooks/localstorage.hook";
import { $apiUrl, $wsUrl } from "@/services/api";
import { FcmTokenType } from "@/services/openapi";
import { getApiUrl } from "@/services/platform";
import type { ApiResponseType } from "@/services/types";
import { socketClient } from "@/services/websocket";
import { platformQueryKeys } from "@/state/server/platform";
import { useProfileHeaderQuery } from "@/state/server/profile";
import {
  useRemoveFcmTokenMutation,
  useUpdateFcmTokenMutation,
  useUpdateUserSettingMutation,
} from "@/state/server/profile/profile.mutations";
import { getIsCookieAccepted, requestPushNotificationPermission } from "@/utils/helpers";
import { token } from "@/utils/token";

type AuthContextState = {
  isAuthenticated: boolean;
  login: (token: string) => void;
  logout: (navigate: NavigateFunction) => void;
  setIsCookieAccepted: Dispatch<SetStateAction<boolean>>;
};

export const AuthContext = createContext<AuthContextState>({} as AuthContextState);

export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { mutate: updateSettings } = useUpdateUserSettingMutation();
  const [isAuthenticated, setIsAuthenticated] = useState(!!token.getToken());
  const { data: profile } = useProfileHeaderQuery({ enabled: isAuthenticated });
  const queryClient = useQueryClient();
  const [acceptedCookieType] = useLocalStorage<CookieType>("acceptedCookieType", CookieType.NOT_DEFINED);
  const [isCookieAccepted, setIsCookieAccepted] = useState(getIsCookieAccepted(acceptedCookieType));
  const { isSdkInitialized, identify, sessionStart } = useBloomreach({
    isCookieAccepted,
  });

  const [fcmToken, setFcmToken] = useState("");
  const { mutate } = useUpdateFcmTokenMutation();
  const { mutate: removeFcmTokenMutate } = useRemoveFcmTokenMutation();

  const login = useCallback(
    async (tokenData: string) => {
      flushSync(async () => {
        token.setToken(tokenData);
        const { apiUrl, wsUrl } = await queryClient.fetchQuery<ApiResponseType<typeof getApiUrl>>(
          platformQueryKeys.apiUrl(),
        );
        $apiUrl.set(apiUrl!);
        $wsUrl.set(wsUrl!);

        socketClient.updateConnectionUrl(wsUrl!);
        socketClient.updateTokenString(token.getHeaderString());

        setIsAuthenticated(true);
      });
    },
    [queryClient],
  );

  const logout = useCallback(
    (navigate: NavigateFunction) => {
      removeFcmTokenMutate(
        { fcmToken: { token: fcmToken, type: FcmTokenType.Web } },
        { onSuccess: () => deleteFcmToken() },
      );
      token.clearToken();
      // setIsAuthenticated(false);
      // // remove all queries where data may differs depending on the user
      // queryClient.removeQueries({
      //   predicate: query => {
      //     const queryMeta: RQQueryMetaType | undefined = query.options.meta;
      //     return !!queryMeta?.authSensitive;
      //   },
      // });
      // navigate(authRoutes.signIn);
      queryClient.invalidateQueries();
      resetUserContext();
      shutdown(); // clear intercom data
      window.location.reload(); // FIXME: bad code because of captcha
    },
    [queryClient],
  );

  const handleGetFirebaseToken = useCallback(() => {
    getFirebaseToken().then((firebaseToken: string | undefined) => {
      if (firebaseToken) {
        mutate(
          {
            fcmToken: {
              type: FcmTokenType.Web,
              token: firebaseToken,
            },
          },
          { onSuccess: () => setFcmToken(firebaseToken) },
        );
      }
    });
  }, [mutate]);

  const sendTimezone = useCallback(() => {
    const timezoneOffsetMinutes: number = new Date().getTimezoneOffset();
    const timezoneOffsetSeconds: number = -timezoneOffsetMinutes * 60;
    const localLang = localStorage.getItem("i18nextLng");

    updateSettings({
      profileSettingsRequest: {
        timezone: timezoneOffsetSeconds,
        languageCode: localLang || "en",
      },
    });
  }, [updateSettings]);

  useEffect(() => {
    if (!isAuthenticated) return;
    sendTimezone();

    requestPushNotificationPermission().then(permission => {
      if (!permission) return;

      // request FCM token and send it to backend
      handleGetFirebaseToken();

      // init bloomreach
      if (window.exponea) {
        window.exponea.notifications.subscribe();
        window.exponea.track("consent", {
          action: "accept",
          category: "Push",
          valid_until: "unlimited",
          identification_type: "customer_id",
          message: "This consent was tracked automatically as notifications permission granted",
        });
      }
    });
  }, [isAuthenticated]);

  useEffect(() => {
    if (!profile) return;
    if (isSdkInitialized) {
      identify({
        customer_id: profile.id!,
        email: profile.email!,
      });
    }
  }, [profile, isSdkInitialized]);

  useEffect(() => {
    if (isSdkInitialized) sessionStart({});
  }, [isSdkInitialized]);

  const onMessageListener = (async () => {
    const messagingResolve = await messaging;

    if (messagingResolve) {
      onMessage(messagingResolve, ({ data }) => {
        if (data?.result) {
          const fcmNotification = JSON.parse(data.result);

          showFcmNotification(fcmNotification);
        }
      });
    }
  })();

  const value = useMemo(
    () => ({
      isAuthenticated,
      setIsCookieAccepted,
      login,
      logout,
    }),
    [isAuthenticated, login, logout, setIsCookieAccepted],
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
