import { type FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, Link } from "react-router-dom";

import { AccountSelect } from "@/entities/accounts/select";
import { PaymentFooter } from "@/features/payment/ui/footer";
import { getDepositRoute } from "@/routes/cabinet.routes";
import type { TradingAccount } from "@/services/openapi";
import { Button } from "@/shared/ui";

type Props = {
  accounts: TradingAccount[];
  lastAccountId: string;
  path: string;
};

const DepositForm: FC<Props> = ({ accounts, lastAccountId, path }) => {
  const { t } = useTranslation();

  const [accountId, setAccountId] = useState(lastAccountId);

  return (
    <>
      <AccountSelect accounts={accounts} accountId={accountId} setAccountId={setAccountId} />
      <PaymentFooter>
        <Button fullWidth asChild>
          <Link to={{ pathname: getDepositRoute(accountId), search: createSearchParams({ from: path }).toString() }}>
            {t("button.next")}
          </Link>
        </Button>
      </PaymentFooter>
    </>
  );
};

export { DepositForm };
