import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

import { cn } from "@/shared/styles";

const labelStyles = cva("typography-S-Regular inline-grid min-w-[54px] place-items-center rounded-sm px-2 py-1", {
  variants: {
    variant: {
      accent: "bg-accent-bg text-accent-over",
      positive: "bg-positive-bg/15 text-positive-text",
      warning: "bg-warning-bg/15 text-warning-text",
      negative: "bg-negative-bg/15 text-negative-text",
      info: "bg-constant-label/15 text-constant-label",
      neutral: "bg-contrast-quinary text-contrast-primary",
    },
  },
});

const Label = forwardRef<ElementRef<"span">, ComponentPropsWithoutRef<"span"> & VariantProps<typeof labelStyles>>(
  ({ className, variant = "neutral", ...props }, ref) => {
    return <span className={cn(labelStyles({ variant }), className)} {...props} ref={ref} />;
  },
);
Label.displayName = "Label";

export { Label };
