import { type ComponentProps, type FC, useCallback } from "react";

import type { TradingAccount } from "@/services/openapi";
import { useChangeAccountMainPasswordMutation } from "@/state/server/accounts";
import { usePlatformData } from "@/state/server/platform/platform.hooks";

import { ChangeAccountPasswordForm } from "./form";
import { ChangeAccountPasswordSuccess } from "./success";

type Props = {
  account: TradingAccount;
  onApply: () => void;
};

const ChangeAccountPasswordContainer: FC<Props> = ({ account, onApply }) => {
  const { password } = usePlatformData();

  const { mutateAsync: changePassword, isSuccess } = useChangeAccountMainPasswordMutation();

  const handleSubmit: ComponentProps<typeof ChangeAccountPasswordForm>["onSubmit"] = useCallback(
    ({ password }) => {
      return changePassword({ id: account.id!, tradingAccountPwdRequest: { pwd: password } });
    },
    [changePassword, account.id],
  );

  return isSuccess ? (
    <ChangeAccountPasswordSuccess onApply={onApply} />
  ) : (
    <ChangeAccountPasswordForm passwordRules={password!} onSubmit={handleSubmit} platform={account.platform!} />
  );
};

export { ChangeAccountPasswordContainer };
