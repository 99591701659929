import { type FC } from "react";

import { useTranslation } from "@/hooks/translator.hook";
import { Button } from "@/shared/ui";
import { useRestoreAccountMutation } from "@/state/server/accounts";

type Props = {
  accountId: string;
};

const RestoreButton: FC<Props> = ({ accountId }) => {
  const { t } = useTranslation();

  const { mutate, isLoading } = useRestoreAccountMutation({ successMessage: "account-restored" });

  return (
    <Button size="sm" pending={isLoading} variant="secondary" onClick={() => mutate({ id: accountId })}>
      {t("accounts.buttons.restore")}
    </Button>
  );
};

export { RestoreButton };
