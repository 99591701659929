import { type FC, type ReactNode, useRef } from "react";

import { cn } from "@/shared/styles";
import { Popover, popoverContentStyles } from "@/shared/ui";

import { useLayoutContext } from "../../layout/context";
import { WatchlistContainer } from "../container";
import { WatchlistHint } from "../hint";

type Props = {
  children: ReactNode;
  watchlistOpen: ReturnType<typeof useLayoutContext>["watchlistOpen"];
  watchlistOnChange: ReturnType<typeof useLayoutContext>["watchlistOnChange"];
  closeWatchlist: ReturnType<typeof useLayoutContext>["closeWatchlist"];
};

const WatchlistPopover: FC<Props> = ({ closeWatchlist, watchlistOnChange, watchlistOpen, children }) => {
  const ref = useRef<any>(null);

  return (
    <>
      <Popover open={watchlistOpen} onOpenChange={watchlistOnChange} modal>
        <Popover.Trigger asChild ref={ref}>
          {children}
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.PrimitiveContent
            onOpenAutoFocus={e => e.preventDefault()}
            align="start"
            sideOffset={8}
            collisionPadding={{ right: 16, left: 16, bottom: 100 }}
            className={cn(
              popoverContentStyles(),
              "h-[--radix-popover-content-available-height] w-[368px] overflow-hidden rounded-xl bg-surface-elevation-2 pt-4 shadow-modal outline-none",
            )}
          >
            <WatchlistContainer onClose={closeWatchlist} />
          </Popover.PrimitiveContent>
        </Popover.Portal>
      </Popover>
      <WatchlistHint virtualRef={ref} />
    </>
  );
};

export { WatchlistPopover };
