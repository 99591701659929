import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, Link } from "react-router-dom";

import { IconAdd } from "@/domains/icons";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import type { TradingAccountType } from "@/services/openapi";
import { Text } from "@/shared/ui";

type Props = {
  accountType: TradingAccountType;
};

const CreateAccountButton: FC<Props> = ({ accountType }) => {
  const { t } = useTranslation();

  return (
    <Link
      to={{ pathname: cabinetRoutes.createAccount, search: createSearchParams({ type: accountType }).toString() }}
      className="flex w-full items-center justify-center rounded-xl bg-contrast-quinary p-5 text-contrast-secondary outline-none transition-colors hover:bg-contrast-quaternary focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-text active:bg-contrast-quinary"
    >
      <div className="flex flex-col items-center gap-3">
        <IconAdd className="size-6" />
        <Text variant="S / Regular" color="inherit">
          {t("accounts.buttons.create")}
        </Text>
      </div>
    </Link>
  );
};

export { CreateAccountButton };
