import Big from "big.js";
import type { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { NumberFormat } from "@/app/components";
import { HookForm, SubmitButton, useControlledField, useHookForm } from "@/app/form";
import { getVerificationRoute } from "@/features/onboarding/helpers";
import { PaymentFooter } from "@/features/payment/ui/footer";
import { formatInputNumberValue, getInputNumberValue } from "@/features/terminal/helpers/formatting";
import { useDisclosure } from "@/hooks/disclosure.hook";
import type { PaymentLimit, TradingAccount } from "@/services/openapi";
import { Dialog, NumberInput } from "@/shared/ui";
import { useProfileData } from "@/state/server/profile/profile.hooks";

import { OpenOrdersContainer } from "./dialog-container";

enum Fields {
  AMOUNT = "amount",
}

type FormValues = {
  [Fields.AMOUNT]: string;
};

type Props = {
  currentAmount: string;
  currentAccount: TradingAccount;
  currentLimitBeforeKyc: number;
  safeToWithdrawAmount: number;
  limits: PaymentLimit;
  next: () => void;
  setCurrentAmount: (value: string) => void;
};

const AmountStep: FC<Props> = ({
  currentAmount,
  currentAccount,
  currentLimitBeforeKyc,
  safeToWithdrawAmount,
  limits,
  next,
  setCurrentAmount,
}) => {
  const [opened, { close, open }] = useDisclosure();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const profile = useProfileData();
  const noKycAmountLimit = profile.featuresInfo?.withdrawScreenNoKycAmountLimit;
  const verificationRoute = getVerificationRoute(profile.options!.isSurveyCompleted!);
  const currency = currentAccount.currency!;
  const decimalScale = currentAccount.digits!;

  const form = useHookForm<FormValues>({
    defaultValues: {
      [Fields.AMOUNT]: formatInputNumberValue(currentAmount, decimalScale),
    },
  });

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = form;

  const { amount } = watch();
  const currentAmountNumber = getInputNumberValue(amount) ?? 0;
  const limitMax = limits.platformMax ? limits.platformMax : void 0;
  const limitMin = limits.platformMin ? limits.platformMin : void 0;
  const maxSafe = limitMax! > safeToWithdrawAmount ? safeToWithdrawAmount : limitMax;

  const [field, { invalid, pending }] = useControlledField<FormValues>({
    name: Fields.AMOUNT,
    control,
    rules: { required: true, min: limitMin, max: limitMax },
  });
  const errorType = errors[Fields.AMOUNT]?.type;
  const isMaxAmountError = errorType === "max";
  const isMinAmountError = errorType === "min" || errorType === "required";
  const isAmountNotSafe = !!(
    safeToWithdrawAmount !== void 0 &&
    safeToWithdrawAmount !== null &&
    currentAmountNumber >= safeToWithdrawAmount &&
    currentAmountNumber <= limitMax!
  );
  const isNeedVerify = Boolean(
    currentAmountNumber > 0 && noKycAmountLimit && new Big(currentAmountNumber).gt(currentLimitBeforeKyc),
  );

  const handleSubmit = () => {
    if (isNeedVerify) {
      navigate(verificationRoute);
      return;
    }

    if (isAmountNotSafe) {
      open();
      return;
    }

    setCurrentAmount(amount);
    next();
  };

  return (
    <HookForm form={form} onSubmit={handleSubmit}>
      <NumberInput
        placeholder={`0 ${currency}`}
        currency={currency}
        decimalScale={decimalScale}
        pending={pending}
        invalid={invalid}
        descriptor={
          isMaxAmountError ? (
            <Trans
              i18nKey="withdrawal.form.amount-step.available"
              components={{
                click: (
                  <NumberInput.DescriptorButton
                    onClick={() => setValue(Fields.AMOUNT, formatInputNumberValue(limitMax, decimalScale))}
                  />
                ),
                value: <NumberFormat value={limitMax} decimalScale={decimalScale} currency={currency} />,
              }}
            />
          ) : isMinAmountError ? (
            <Trans
              i18nKey="withdrawal.form.amount-step.min-error"
              components={{
                click: (
                  <NumberInput.DescriptorButton
                    onClick={() => setValue(Fields.AMOUNT, formatInputNumberValue(limitMin, decimalScale))}
                  />
                ),
                value: <NumberFormat value={limitMin} decimalScale={decimalScale} currency={currency} />,
              }}
            /> /* : isAmountNotSafe ? (
            <Trans
              i18nKey="withdrawal.form.amount-step.tooltip"
              values={{
                symbol: currentAccount.currency,
              }}
              components={{
                click: (
                  <NumberInput.DescriptorButton
                    onClick={() => setValue(Fields.AMOUNT, formatInputNumberValue(maxSafe, decimalScale))}
                  />
                ),
                value: <NumberFormat value={maxSafe} decimalScale={decimalScale} />,
              }}
            />
          ) */
          ) : (
            <Trans
              i18nKey="withdrawal.form.amount-step.available"
              values={{ symbol: currentAccount.currency }}
              components={{
                click: (
                  <NumberInput.DescriptorButton
                    onClick={() => setValue(Fields.AMOUNT, formatInputNumberValue(limitMax, decimalScale))}
                  />
                ),
                value: <NumberFormat value={limitMax} decimalScale={decimalScale} currency={currency} />,
              }}
            />
          )
        }
        {...field}
      />

      <PaymentFooter offset="sm">
        <SubmitButton fullWidth>{isNeedVerify ? t("button.go-to-verify") : t("button.next")}</SubmitButton>
      </PaymentFooter>

      <Dialog open={opened} onOpenChange={close}>
        <Dialog.Content>
          <OpenOrdersContainer
            accountId={currentAccount.id!}
            onWithdraw={() => {
              setCurrentAmount(amount);
              next();
            }}
          />
        </Dialog.Content>
      </Dialog>
    </HookForm>
  );
};

export { AmountStep };
