import { type FC, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { PaymentFooter } from "@/features/payment/ui/footer";
import { PaymentMethodToggleGroup } from "@/features/payment/ui/method-toggle-group";
import { useDisclosure } from "@/hooks/disclosure.hook";
import type { PaymentMethod, TradingAccount } from "@/services/openapi";
import { Button, Dialog } from "@/shared/ui";
import { useProfileData } from "@/state/server/profile/profile.hooks";

import { DialogContainer } from "./dialog-container";

type Props = {
  setPaymentMethod: (method: PaymentMethod) => void;
  paymentMethods: PaymentMethod[];
  next: () => void;
  currentPaymentMethod: PaymentMethod | null;
  currentAccount: TradingAccount;
};

const PaymentMethodStep: FC<Props> = ({
  next,
  paymentMethods,
  setPaymentMethod,
  currentPaymentMethod,
  currentAccount,
}) => {
  const [opened, { open, close }] = useDisclosure();
  const { t } = useTranslation();
  const profileData = useProfileData();
  const onValueChange = useCallback(
    (value: string) => {
      const method = paymentMethods.find(({ title }) => title === value)!;
      setPaymentMethod(method);
    },
    [paymentMethods, setPaymentMethod],
  );

  const onNext = () => {
    if (currentPaymentMethod?.mandatorySurvey) {
      open();
      return;
    }
    next();
  };

  return (
    <>
      <PaymentMethodToggleGroup onValueChange={onValueChange}>
        {paymentMethods.map(({ title, images, details }) => {
          const from = details!.limit?.from;

          return (
            <PaymentMethodToggleGroup.Item
              key={title}
              value={title!}
              title={title}
              description={
                from && (
                  <Trans
                    i18nKey="deposit.payment-methods.from"
                    values={{ currency: details!.limit?.currency }}
                    components={{ value: <NumberFormat value={from} decimalScale={currentAccount.digits} /> }}
                  />
                )
              }
              images={images!}
            />
          );
        })}
      </PaymentMethodToggleGroup>
      <PaymentFooter>
        <Button fullWidth disabled={!currentPaymentMethod} onClick={onNext}>
          {t("button.next")}
        </Button>
      </PaymentFooter>

      <Dialog open={opened} onOpenChange={close}>
        <Dialog.Content>
          <DialogContainer isSurveyCompleted={profileData.options!.isSurveyCompleted!} />
        </Dialog.Content>
      </Dialog>
    </>
  );
};

export { PaymentMethodStep };
