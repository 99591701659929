import type { FC } from "react";

import { useTranslation } from "@/hooks/translator.hook";
import { CabinetHeader } from "@/layouts/cabinet/header";
import { CabinetPage } from "@/layouts/cabinet/page";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import type { TwoFactorInfo } from "@/services/openapi";
import { Button, Dialog, Text } from "@/shared/ui";

import { type TwoFAConfirmInfo, type TwoFAStep } from "../../container";
import { GoogleAuthenticatorBlock } from "./google-authenticator-block";
import { Enable2FAVerifyContainer } from "./verify/container";

type Props = {
  email: string;
  twoFactor: TwoFactorInfo;
  setStep: (step: TwoFAStep) => void;
  setTwoFactorData: (data: TwoFAConfirmInfo) => void;
};

const Enable2FAContainer: FC<Props> = ({ setStep, setTwoFactorData, email, twoFactor }) => {
  const { t } = useTranslation();

  return (
    <CabinetPage
      size="sm"
      header={
        <CabinetHeader backButton={<CabinetHeader.BackLink to={cabinetRoutes.profileSettings} />}>
          <CabinetHeader.Title>{t("two-factor.title")}</CabinetHeader.Title>
        </CabinetHeader>
      }
    >
      <Text color="secondary" variant="M / Regular">
        {t("two-factor.description")}
      </Text>
      <Dialog>
        <Dialog.Trigger asChild>
          <Button className="mb-6 mt-4" color="positive" size="sm">
            {t("two-factor.enable-button")}
          </Button>
        </Dialog.Trigger>
        <Enable2FAVerifyContainer setTwoFactorData={setTwoFactorData} setStep={setStep} email={email} />
      </Dialog>
      <GoogleAuthenticatorBlock link={twoFactor.appUniversalUrl!} />
    </CabinetPage>
  );
};

export { Enable2FAContainer };
