import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { IconTick } from "@/domains/icons";
import { Button, Dialog } from "@/shared/ui";

type Props = {
  onApply: () => void;
};

const ChangeAccountPasswordSuccess: FC<Props> = ({ onApply }) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog.Header
        centered
        title={t("accounts.change-password.success-title")}
        image={
          <Dialog.Image type="icon">
            <Dialog.Icon type="positive">
              <IconTick />
            </Dialog.Icon>
          </Dialog.Image>
        }
      />
      <Dialog.Buttons>
        <Button onClick={onApply}>{t("accounts.change-password.success-action")}</Button>
      </Dialog.Buttons>
    </>
  );
};

export { ChangeAccountPasswordSuccess };
