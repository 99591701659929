import { useState } from "react";

import { useNextRequestDate } from "@/app/hooks";

const formatRemainingTime = (remainingTime: number) => {
  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;
  return `${minutes}:${String(seconds).padStart(2, "0")}`;
};

const useVerifyCodeData = () => {
  const [nextRequestDate, setNextRequestDate] = useState<string | null>(null);
  const [isCodeSent, setIsCodeSent] = useState(false);

  const { remainingTime, countdownActive } = useNextRequestDate(nextRequestDate);

  return { setNextRequestDate, isCodeSent, setIsCodeSent, remainingTime, countdownActive };
};

export { formatRemainingTime, useVerifyCodeData };
