import { type FC, useState } from "react";

import { type TradingAccount, TradingServerPlatform } from "@/services/openapi";

import { ChangeAccountPasswordContainer } from "../change-password/container";
import { AccountMTLoginDetails } from "./login-details";
import { AccountMT5Intro } from "./mt5-intro";

type AccountMTDetailsStep = "mt5-intro" | "details" | "change-password";

type Props = {
  account: TradingAccount;
  onClose: () => void;
  initialStep?: AccountMTDetailsStep;
};

const AccountMTDetailsDialogContainer: FC<Props> = ({ account, onClose, initialStep }) => {
  const [step, setStep] = useState<AccountMTDetailsStep>(() => {
    if (initialStep) {
      return initialStep;
    }
    if (account.platform === TradingServerPlatform.MetaTrader5) {
      return "mt5-intro";
    }
    return "details";
  });

  return (
    <>
      {step === "mt5-intro" && (
        <AccountMT5Intro accountId={account.id!} onDetailsSelect={() => setStep("details")} onClose={onClose} />
      )}
      {step === "details" && (
        <AccountMTLoginDetails account={account} onPasswordSelect={() => setStep("change-password")} type="details" />
      )}
      {step === "change-password" && <ChangeAccountPasswordContainer account={account} onApply={onClose} />}
    </>
  );
};

export { AccountMTDetailsDialogContainer };
