import { useStore } from "@nanostores/react";
import { produce } from "immer";
import type { FC } from "react";
import { createContext, useCallback, useContext, useEffect, useMemo } from "react";
import { useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";

import { logError } from "@/app/libs/sentry";
import { getShowBonusCondition } from "@/entities/bonuses/helpers";
import { terminalAccountParamsName } from "@/routes/terminal.routes";
import {
  type BonusUserPlatform,
  type TradingAccount,
  TradingAccountTradeMode,
  TradingAccountType,
} from "@/services/openapi";
import { $accountsMessage, socketClient } from "@/services/websocket";
import {
  accountsQueryKeys,
  useChangeAccountTradeModeMutation,
  useUpdateLastAccountMutation,
} from "@/state/server/accounts";

type ContextProps = {
  account: TradingAccount;
  terminalType: TradingAccountType;
  showBonus: boolean;
  setAccount: (accountId: string) => void;
  changeAccountTradeMode: (tradeMode: TradingAccountTradeMode) => void;
};

const Context = createContext<ContextProps | undefined>(undefined);

const Provider: FC<{ children: React.ReactNode; account: TradingAccount; bonuses: BonusUserPlatform[] }> = ({
  children,
  account,
  bonuses,
}) => {
  const [_, setSearchParams] = useSearchParams();
  const { mutateAsync: updateLastAccount } = useUpdateLastAccountMutation();

  const accountsMessage = useStore($accountsMessage);

  const queryClient = useQueryClient();

  const setAccount: ContextProps["setAccount"] = useCallback(
    accountId => {
      setSearchParams(prevParams => {
        prevParams.set(terminalAccountParamsName, accountId);
        prevParams.delete("symbol");
        return prevParams;
      });
      if (window["WEBVIEW_CHANNEL"]) {
        window["WEBVIEW_CHANNEL"].postMessage(`accountChanged:${accountId}`);
      }
    },
    [setSearchParams],
  );

  const { mutate } = useChangeAccountTradeModeMutation();

  const changeAccountTradeMode: ContextProps["changeAccountTradeMode"] = useCallback(
    tradeMode => {
      mutate({ id: account.id!, tradeMode });
      queryClient.setQueryData<TradingAccount>(accountsQueryKeys.account(account.id!), oldData => {
        return produce(oldData, draft => {
          draft!.tradeMode = tradeMode;
        })!;
      });
    },
    [account.id, mutate, queryClient],
  );

  useEffect(() => {
    socketClient.subscribeTrading([account.id!]);
    socketClient.subscribeAccounts();

    return () => {
      socketClient.unsubscribeTrading();
      socketClient.unsubscribeAccounts();
    };
  }, []);

  useEffect(() => {
    if (!accountsMessage) return;

    try {
      const accountUpdates = accountsMessage.a!.find(({ ai }) => ai === account.id);

      if (accountUpdates) {
        queryClient.setQueryData<TradingAccount>(accountsQueryKeys.account(account.id!), oldData => {
          return produce(oldData, draft => {
            draft!.balance = accountUpdates.b;
            draft!.credit = accountUpdates.c;
            draft!.leverage = accountUpdates.l;
          })!;
        });
      }
    } catch (error) {
      logError(error);
    }
  }, [accountsMessage]);

  useEffect(() => {
    updateLastAccount({ id: account.id! });
  }, [account.id]);

  const bonus = useMemo(() => (bonuses.length > 0 ? bonuses[0]! : null), [bonuses]);

  const showBonus = useMemo(
    () =>
      getShowBonusCondition({
        allowedTradingServerAccounts: bonus?.allowedTradingServerAccounts || [],
        serverAccountId: account.serverAccountId!,
        credit: account.credit!,
      }),
    [bonus?.allowedTradingServerAccounts, account.serverAccountId, account.credit],
  );

  const value: ContextProps = useMemo(
    () => ({
      account,
      terminalType: account.type!,
      setAccount,
      changeAccountTradeMode,
      showBonus,
    }),
    [account, setAccount, changeAccountTradeMode, showBonus],
  );
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

Provider.displayName = "TerminalAccountContextProvider";

const useTerminalAccountContext = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("useTerminalAccountContext must be used within a TerminalAccountContextProvider");
  }

  return context;
};

export { Provider as TerminalAccountContextProvider, useTerminalAccountContext };
