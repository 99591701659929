import { useQueryClient } from "react-query";

import { useAuth } from "@/contexts/auth.context";
import {
  changePasswordUniversal,
  confirmEmail,
  confirmTwoStepAuthUniversal,
  createTwoStepAuth,
  createTwoStepAuthRecoveryCodesUniversal,
  disableTwoStepAuthUniversal,
  forgotPassword,
  resendConfirmationLink,
  resetPassword,
  signIn,
  signInFacebook,
  signInGoogle,
  signUp,
  validateTwoStepAuth,
} from "@/services/auth";
import type { ApiRequestParamsType, ApiResponseType } from "@/services/types";
import { token } from "@/utils/token";

import { useBaseMutation } from "..";
import { profileQueryKeys } from "../profile";
import type { MutationOptionsType } from "../types";

export const useForgotPasswordMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof forgotPassword>, ApiRequestParamsType<typeof forgotPassword>>,
) => {
  return useBaseMutation<ApiResponseType<typeof forgotPassword>, ApiRequestParamsType<typeof forgotPassword>>(
    forgotPassword,
    options,
  );
};

export const useResetPasswordMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof resetPassword>, ApiRequestParamsType<typeof resetPassword>>,
) => {
  const { login } = useAuth();
  return useBaseMutation<ApiResponseType<typeof resetPassword>, ApiRequestParamsType<typeof resetPassword>>(
    resetPassword,
    {
      onSuccess: token => {
        login(token);
      },
      successMessage: "password-changed",
      ...options,
    },
  );
};

export const useSignInMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof signIn>, ApiRequestParamsType<typeof signIn>>,
) => {
  const { login } = useAuth();
  return useBaseMutation<ApiResponseType<typeof signIn>, ApiRequestParamsType<typeof signIn>>(signIn, {
    onSuccess: token => {
      login(token);
    },
    ...options,
  });
};

export const useSignUpMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof signUp>, ApiRequestParamsType<typeof signUp>>,
) => {
  return useBaseMutation<ApiResponseType<typeof signUp>, ApiRequestParamsType<typeof signUp>>(signUp, options);
};

export const useResendConfirmationLinkMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof resendConfirmationLink>,
    ApiRequestParamsType<typeof resendConfirmationLink>
  >,
) => {
  return useBaseMutation<
    ApiResponseType<typeof resendConfirmationLink>,
    ApiRequestParamsType<typeof resendConfirmationLink>
  >(resendConfirmationLink, options);
};

export const useConfirmEmailMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof confirmEmail>, ApiRequestParamsType<typeof confirmEmail>>,
) => {
  const { login } = useAuth();
  return useBaseMutation<ApiResponseType<typeof confirmEmail>, ApiRequestParamsType<typeof confirmEmail>>(
    confirmEmail,
    {
      onSuccess: token => {
        login(token);
      },
      ...options,
    },
  );
};

export const useChangePasswordMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof changePasswordUniversal>,
    ApiRequestParamsType<typeof changePasswordUniversal>
  >,
) => {
  const client = useQueryClient();
  return useBaseMutation<
    ApiResponseType<typeof changePasswordUniversal>,
    ApiRequestParamsType<typeof changePasswordUniversal>
  >(changePasswordUniversal, {
    onSuccess: response => {
      token.setToken(response);
      client.invalidateQueries(profileQueryKeys.header());
    },
    ...options,
  });
};

export const useConfirmTwoFAMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof confirmTwoStepAuthUniversal>,
    ApiRequestParamsType<typeof confirmTwoStepAuthUniversal>
  >,
) => {
  const client = useQueryClient();
  return useBaseMutation<
    ApiResponseType<typeof confirmTwoStepAuthUniversal>,
    ApiRequestParamsType<typeof confirmTwoStepAuthUniversal>
  >(confirmTwoStepAuthUniversal, {
    onSuccess: res => {
      token.setToken(res.authToken!);
      client.invalidateQueries(profileQueryKeys.header());
    },
    ...options,
  });
};

export const useCreateTwoFAMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof createTwoStepAuth>,
    ApiRequestParamsType<typeof createTwoStepAuth>
  >,
) => {
  return useBaseMutation<ApiResponseType<typeof createTwoStepAuth>, ApiRequestParamsType<typeof createTwoStepAuth>>(
    createTwoStepAuth,
    options,
  );
};

export const useDisableTwoFAMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof disableTwoStepAuthUniversal>,
    ApiRequestParamsType<typeof disableTwoStepAuthUniversal>
  >,
) => {
  const client = useQueryClient();
  return useBaseMutation<
    ApiResponseType<typeof disableTwoStepAuthUniversal>,
    ApiRequestParamsType<typeof disableTwoStepAuthUniversal>
  >(disableTwoStepAuthUniversal, {
    onSuccess: () => {
      client.invalidateQueries(profileQueryKeys.header());
    },
    ...options,
  });
};

export const useValidateTwoStepAuthMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof validateTwoStepAuth>,
    ApiRequestParamsType<typeof validateTwoStepAuth>
  >,
) => {
  return useBaseMutation<ApiResponseType<typeof validateTwoStepAuth>, ApiRequestParamsType<typeof validateTwoStepAuth>>(
    validateTwoStepAuth,
    options,
  );
};

export const useCreateNewRecoveryCodesMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof createTwoStepAuthRecoveryCodesUniversal>,
    ApiRequestParamsType<typeof createTwoStepAuthRecoveryCodesUniversal>
  >,
) => {
  return useBaseMutation<
    ApiResponseType<typeof createTwoStepAuthRecoveryCodesUniversal>,
    ApiRequestParamsType<typeof createTwoStepAuthRecoveryCodesUniversal>
  >(createTwoStepAuthRecoveryCodesUniversal, options);
};

export const useSignInGoogleMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof signInGoogle>, ApiRequestParamsType<typeof signInGoogle>>,
) => {
  const { login } = useAuth();
  return useBaseMutation<ApiResponseType<typeof signInGoogle>, ApiRequestParamsType<typeof signInGoogle>>(
    signInGoogle,
    {
      onSuccess: res => {
        login(res.authToken!);
      },
      ...options,
    },
  );
};

export const useSignInFacebookMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof signInFacebook>, ApiRequestParamsType<typeof signInFacebook>>,
) => {
  const { login } = useAuth();
  return useBaseMutation<ApiResponseType<typeof signInFacebook>, ApiRequestParamsType<typeof signInFacebook>>(
    signInFacebook,
    {
      onSuccess: res => {
        login(res.authToken!);
      },
      ...options,
    },
  );
};
