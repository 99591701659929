import { signal } from "@maverick-js/signals";

import { axiosInstance } from "./api.helpers";
import {
  AccountsApi,
  AuthApi,
  BannersApi,
  BonusesApi,
  FundsApi,
  KycApi,
  NotificationsApi,
  OtCodesApi,
  PaymentsApi,
  PlatformApi,
  ProfileApi,
  RaffleApi,
  RateApi,
  TerminalApi,
  TournamentsApi,
} from "./openapi";

export const $apiUrl = signal(import.meta.env.VITE_API_URL as string);
export const $wsUrl = signal(import.meta.env.VITE_WS_URL as string);

const fallbackUrlsEnv = import.meta.env.VITE_API_FALLBACK_URLS as string | undefined;
export const fallbackApiUrls = fallbackUrlsEnv ? fallbackUrlsEnv.split("|") : [];

export const accountsService = () => {
  return new AccountsApi(undefined, $apiUrl(), axiosInstance);
};

export const authService = () => {
  return new AuthApi(undefined, $apiUrl(), axiosInstance);
};

export const bannersService = () => {
  return new BannersApi(undefined, $apiUrl(), axiosInstance);
};

export const bonusesService = () => {
  return new BonusesApi(undefined, $apiUrl(), axiosInstance);
};

export const fundsService = () => {
  return new FundsApi(undefined, $apiUrl(), axiosInstance);
};

export const kycService = () => {
  return new KycApi(undefined, $apiUrl(), axiosInstance);
};

export const notificationsService = () => {
  return new NotificationsApi(undefined, $apiUrl(), axiosInstance);
};

export const otCodesService = () => {
  return new OtCodesApi(undefined, $apiUrl(), axiosInstance);
};

export const platformService = () => {
  return new PlatformApi(undefined, $apiUrl(), axiosInstance);
};

export const profileService = () => {
  return new ProfileApi(undefined, $apiUrl(), axiosInstance);
};

export const paymentService = () => {
  return new PaymentsApi(undefined, $apiUrl(), axiosInstance);
};

export const terminalService = () => {
  return new TerminalApi(undefined, $apiUrl(), axiosInstance);
};

export const raffleService = () => {
  return new RaffleApi(undefined, $apiUrl(), axiosInstance);
};

export const tournamentsService = () => {
  return new TournamentsApi(undefined, $apiUrl(), axiosInstance);
};

export const rateService = () => {
  return new RateApi(undefined, $apiUrl(), axiosInstance);
};
