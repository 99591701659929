import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { cva } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, type ReactNode, useId } from "react";

import { cn } from "@/shared/styles";

const checkboxStyles = cva([
  "size-6 shrink-0 rounded-sm border border-contrast-quaternary bg-control-bg transition-[background,border] hover:bg-control-bg-hover",
  "focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-text data-[invalid=true]:focus-visible:outline-negative-text",
  "data-[invalid=true]:border-negative-text",
  "data-[state=checked]:border-accent-bg data-[state=checked]:bg-accent-bg data-[state=checked]:hover:border-accent-bg-hover data-[state=checked]:hover:bg-accent-bg-hover data-[state=checked]:disabled:border-contrast-quaternary data-[state=checked]:disabled:bg-contrast-quaternary",
])();

const Checkbox = forwardRef<
  ElementRef<typeof CheckboxPrimitive.Root>,
  ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
    label: ReactNode;
    description?: ReactNode;
    errorMessage?: ReactNode;
    invalid?: boolean;
  }
>(({ className, label, description, errorMessage, disabled, ...props }, ref) => {
  const id = useId();

  const invalid = props.invalid || !!errorMessage;

  return (
    <div className={cn("group flex gap-3.5 has-[:disabled]:pointer-events-none", className)}>
      <CheckboxPrimitive.Root
        ref={ref}
        id={id}
        className={checkboxStyles}
        data-invalid={invalid}
        disabled={disabled}
        {...props}
      >
        <CheckboxPrimitive.Indicator className="grid place-items-center text-control-bg">
          <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.47149 6.52864L10.6667 0.333374L11.6096 1.27618L4.47149 8.41426L0.666748 4.60952L1.60956 3.66671L4.47149 6.52864Z"
              fill="currentColor"
            />
          </svg>
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
      <div className="grid gap-1">
        <label
          htmlFor={id}
          className="typography-S-Regular text-contrast-primary transition-colors group-has-[:disabled]:text-contrast-tertiary"
        >
          {label}
        </label>
        {description && (
          <p className="typography-XS-Regular text-contrast-secondary transition-colors group-has-[:disabled]:text-contrast-tertiary">
            {description}
          </p>
        )}
        {errorMessage && !disabled && <p className="typography-XS-Regular text-negative-text">{errorMessage}</p>}
      </div>
    </div>
  );
});
Checkbox.displayName = "Checkbox";

export { Checkbox };
