import dayjs from "dayjs";
import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { IconTick } from "@/domains/icons";
import type { KycInfo, ProfileHeader } from "@/services/openapi";
import { DataList } from "@/shared/ui";

type Props = {
  kyc: KycInfo;
  profile: ProfileHeader;
};

const ProfileInfoBlock: FC<Props> = ({ kyc, profile: profileProp }) => {
  const { t } = useTranslation();

  const { options, email, phoneNumber } = profileProp;

  const { profile, address } = kyc;

  return (
    <div className="rounded-lg border border-card-border bg-card-bg p-4">
      <DataList>
        {email && (
          <DataList.Item
            label={<DataList.Label>{t("profile-settings.details.email")}</DataList.Label>}
            value={
              <DataList.Value>
                <div className="flex items-center gap-1">
                  {email}
                  {options!.isEmailConfirmed && <IconTick className="size-4 text-positive-text" />}
                </div>
              </DataList.Value>
            }
          />
        )}
        {phoneNumber && (
          <DataList.Item
            label={<DataList.Label>{t("profile-settings.details.phone")}</DataList.Label>}
            value={<DataList.Value>{phoneNumber}</DataList.Value>}
          />
        )}
        {profile!.dateOfBirth && (
          <DataList.Item
            label={<DataList.Label>{t("profile-settings.details.date-of-birth")}</DataList.Label>}
            value={<DataList.Value>{dayjs(profile!.dateOfBirth).format("DD.MM.YYYY")}</DataList.Value>}
          />
        )}
        {address!.country && (
          <DataList.Item
            label={<DataList.Label>{t("profile-settings.details.country")}</DataList.Label>}
            value={<DataList.Value>{address!.country}</DataList.Value>}
          />
        )}
      </DataList>
    </div>
  );
};

export { ProfileInfoBlock };
