import { forwardRef, useCallback } from "react";
import { NumericFormat, type NumericFormatProps } from "react-number-format";

import { useScreenSize } from "@/hooks/screen-size.hook";
import { isIos } from "@/utils/helpers";

import { StyledInput } from "../styles";
import { InputWrapper, type InputWrapperExternalProps } from "../wrapper";

export type NumberInputExternalProps = NumericFormatProps &
  InputWrapperExternalProps &
  React.ComponentProps<typeof StyledInput>;

type Props = Omit<NumberInputExternalProps, "onChange"> & {
  onChange: (value: number | undefined) => void;
  value?: number;
};

/**
 * @deprecated
 */
export const NumberInput = forwardRef<React.ElementRef<typeof StyledInput>, Props>((props, ref) => {
  const {
    css,
    leftAdornment,
    rightAdornment,
    showError,
    error,
    bottomOffset,
    onChange,
    type = "text",
    allowLeadingZeros = false,
    allowNegative = false,
    thousandSeparator = " ",
    value,
    name,
    label,
    disabled,
    description,
    ...inputProps
  } = props;

  const { isMobile } = useScreenSize();

  const onFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (isMobile) e.target.scrollIntoView({ behavior: "smooth", inline: "start" });
    },
    [isMobile],
  );

  return (
    <InputWrapper
      css={css}
      leftAdornment={leftAdornment}
      rightAdornment={rightAdornment}
      showError={showError}
      error={error}
      bottomOffset={bottomOffset}
      name={name}
      label={label}
      disabled={disabled}
      description={description}
    >
      <NumericFormat
        {...inputProps}
        // Empty string value is needed for correct field reset
        value={value ?? ""}
        disabled={disabled}
        getInputRef={ref}
        onValueChange={({ floatValue }) => {
          if (floatValue === undefined) {
            onChange(null as any);
          } else {
            onChange(floatValue);
          }
        }}
        onFocus={onFocus}
        customInput={StyledInput}
        type={type}
        id={name}
        inputMode={isIos() ? "text" : "decimal"}
        allowLeadingZeros={allowLeadingZeros}
        allowNegative={allowNegative}
        thousandSeparator={thousandSeparator}
      />
    </InputWrapper>
  );
});
NumberInput.displayName = "NumberInput";
