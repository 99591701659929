import { type ComponentProps, type FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

import type { TwoFAStep } from "@/features/two-factor/container";
import { toast, ToastIcons } from "@/shared/ui";
import { useDisableTwoFAMutation } from "@/state/server/auth";

import { Disable2FADialogContainerForm } from "./form";

type Props = {
  onClose: () => void;
  setStep: (step: TwoFAStep) => void;
};

const Disable2FADialogContainer: FC<Props> = ({ onClose, setStep }) => {
  const { t } = useTranslation();

  const { mutateAsync: disable } = useDisableTwoFAMutation();

  const handleSubmit: ComponentProps<typeof Disable2FADialogContainerForm>["onSubmit"] = useCallback(
    ({ code }) => {
      return disable(
        {
          twoFactorDisableUniversalRequest: {
            twoFactorCode: code,
          },
        },
        {
          onSuccess: () => {
            onClose();
            setStep("enable");
            toast({
              title: t("two-factor.disable.dialog.success-message")!,
              icon: ToastIcons.SUCCESS,
            });
          },
        },
      );
    },
    [disable, onClose, t, setStep],
  );

  return <Disable2FADialogContainerForm onSubmit={handleSubmit} />;
};

export { Disable2FADialogContainer };
