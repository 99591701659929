import type { FC } from "react";
import { memo, useEffect, useMemo, useRef } from "react";

import { css, styled } from "@/styles";

import { Flex } from "../flex";
import { ChevronLeftIcon, ChevronRightIcon } from "../icons";
import { UnstyledButton } from "../unstyled-button.tsx";
import { calculateTotalPages, createVisiblePages, MAX_PAGINATION_ITEMS } from "./pagination.helpers";

const PaginationContainer = styled(Flex, {
  overflowX: "auto",
  overflowY: "hidden",

  hideScroll: true,
});

const paginationCommonStyles = css({
  fontWeight: "$bolder",
  fontSize: "15px",
  lineHeight: "$4",
  color: "$textMain",

  width: "40px",
  height: "40px",

  borderRadius: "24px",

  display: "grid",
  placeItems: "center",
});

const PaginationItem = styled(UnstyledButton, paginationCommonStyles, {
  "&:hover": {
    elementTransition: "background",
    background: "$bgSelected",
  },

  variants: {
    active: {
      true: {
        background: "$bgSelected",
      },
    },
  },
});

const PaginationChevronItem = styled(UnstyledButton, paginationCommonStyles, {
  "&:hover": {
    elementTransition: "background opacity",
    background: "$bgPrimary",
    opacity: "0.5",
  },

  variants: {
    disabled: {
      true: {
        opacity: "0.5",
        pointerEvents: "none",
      },
    },
  },
});

export type PaginationProps = {
  total: number;
  pageSize: number;
  currentPage: number;
  changePage: (page: number) => void;
};

const _Pagination: FC<PaginationProps> = ({ total, pageSize, currentPage, changePage }) => {
  const totalPages = useMemo(() => calculateTotalPages(total, pageSize), [total, pageSize]);
  const isInitialRender = useRef(true);

  // there may be a case while updating a table when a table row is deleted and there will be no elements on the page
  // so we should reset the pagination
  useEffect(() => {
    if (currentPage > totalPages) {
      changePage(1);
    }
  }, [currentPage, totalPages]);

  useEffect(() => {
    if (!isInitialRender.current) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    isInitialRender.current = false;
  }, [currentPage]);

  const visiblePages = useMemo(() => createVisiblePages(totalPages, currentPage), [totalPages, currentPage]);

  return totalPages > 1 ? (
    <PaginationContainer>
      {totalPages > MAX_PAGINATION_ITEMS && (
        <>
          <PaginationChevronItem disabled={currentPage === 1} onClick={() => changePage(currentPage - 1)}>
            <ChevronLeftIcon />
          </PaginationChevronItem>
        </>
      )}
      {visiblePages.map(page => (
        <PaginationItem active={currentPage === page} key={page} onClick={() => changePage(page)}>
          {page}
        </PaginationItem>
      ))}
      {totalPages > MAX_PAGINATION_ITEMS && (
        <>
          <PaginationChevronItem disabled={totalPages === currentPage} onClick={() => changePage(currentPage + 1)}>
            <ChevronRightIcon />
          </PaginationChevronItem>
        </>
      )}
    </PaginationContainer>
  ) : null;
};

/**
 * @deprecated
 */
export const Pagination = memo(_Pagination);
