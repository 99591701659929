import type { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import type { PaymentMethod } from "@/services/openapi";
import { Text } from "@/shared/ui";

import { PaymentMethodToggleGroup } from "../method-toggle-group";

type Props = {
  paymentMethods: PaymentMethod[];
  children: ReactNode;
};

const PaymentNoAccounts: FC<Props> = ({ paymentMethods, children }) => {
  const { t } = useTranslation();

  return (
    <div className="grid gap-8">
      {children}
      <div className="grid gap-4">
        <Text variant="S / Regular" color="primary">
          {t("payments.supported-methods")}
        </Text>
        <PaymentMethodToggleGroup>
          {paymentMethods.map(({ title, images }) => (
            <PaymentMethodToggleGroup.Item key={title} disabled value={title!} title={title} images={images!} />
          ))}
        </PaymentMethodToggleGroup>
      </div>
    </div>
  );
};

export { PaymentNoAccounts };
