import { type FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { createSearchParams, Link } from "react-router-dom";

import { NumberFormat } from "@/app/components";
import { useLocationPath } from "@/app/hooks";
import img from "@/domains/ui-kit/IconsGlass/shield.svg";
import { getVerificationRoute } from "@/features/onboarding/helpers";
import type { MaximumLimitDescription } from "@/services/openapi";
import { Button, Dialog, Image, Text } from "@/shared/ui";
import { useProfileData } from "@/state/server/profile/profile.hooks";

export const fromDepositState = "fromDeposit";

type Props = {
  open: boolean;
  submitDeposit: () => void;
  onClose: (open: boolean) => void;
  depositLimit: number | undefined;
  currency: string;
  decimalScale: number;
  withdrawLimit: MaximumLimitDescription;
  amount: number;
};

const VerificationContainer: FC<Props> = ({
  currency,
  decimalScale,
  depositLimit,
  onClose,
  open,
  submitDeposit,
  withdrawLimit,
  amount,
}) => {
  const { t } = useTranslation();
  const profileData = useProfileData();
  const isSurveyMandatory = profileData.featuresInfo!.mandatorySurvey;
  const isSurveyCompleted = profileData.options!.isSurveyCompleted!;
  const verificationRoute = getVerificationRoute(isSurveyCompleted);
  const isAmountMoreThanLimit = depositLimit && amount > depositLimit;
  const path = useLocationPath();

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <Dialog.Content>
        <Dialog.Header
          centered
          title={
            !isSurveyMandatory
              ? t("deposit.verification.dialog.mandatory.title")!
              : t("deposit.verification.dialog.title")!
          }
          image={
            <Dialog.Image size="lg" type="image">
              <Image src={img} />
            </Dialog.Image>
          }
        />
        <Text variant="S / Regular" color="primary" align="center">
          {!isSurveyMandatory ? (
            t("deposit.verification.dialog.mandatory.description")
          ) : (
            <Trans
              i18nKey="deposit.verification.dialog.description"
              components={{
                depositAmount: <NumberFormat value={depositLimit} decimalScale={decimalScale} currency={currency} />,
                withdrawAmount: (
                  <NumberFormat
                    value={withdrawLimit.extendedInfo!.withdrawCurrentLimitNoKyc}
                    decimalScale={decimalScale}
                    currency={currency}
                  />
                ),
              }}
            />
          )}
        </Text>
        <Dialog.Buttons>
          {isSurveyMandatory ? (
            <>
              {!isAmountMoreThanLimit && (
                <Dialog.Close asChild>
                  <Button onClick={submitDeposit}>{t("deposit.verification.dialog.button-payment")}</Button>
                </Dialog.Close>
              )}
              <Button variant="secondary" asChild>
                <Link to={{ pathname: verificationRoute, search: createSearchParams({ from: path }).toString() }}>
                  {t("deposit.verification.dialog.button-verify")}
                </Link>
              </Button>
            </>
          ) : (
            <Button asChild>
              <Link to={{ pathname: verificationRoute, search: createSearchParams({ from: path }).toString() }}>
                {t("deposit.verification.dialog.button-verify")}
              </Link>
            </Button>
          )}
        </Dialog.Buttons>
      </Dialog.Content>
    </Dialog>
  );
};

export { VerificationContainer };
