import type { ComponentProps, FC } from "react";
import { useCallback } from "react";

import { getInputNumberValue } from "@/features/terminal/helpers/formatting";
import type { TradingAccountAttributes } from "@/services/openapi";
import { useDemoDepositMutation } from "@/state/server/accounts";

import { RemoveFundsForm } from "./form";

type Props = {
  accountId: string;
  accountCurrency: string;
  currencyDecimalScale: number;
  availableToWithdraw: number;
  attributes: TradingAccountAttributes;
  onClose: () => void;
};

const RemoveFundsContainer: FC<Props> = ({
  accountId,
  accountCurrency,
  onClose,
  currencyDecimalScale,
  availableToWithdraw,
  attributes,
}) => {
  const { mutateAsync: demoDeposit } = useDemoDepositMutation();

  const submitDeposit: ComponentProps<typeof RemoveFundsForm>["onSubmit"] = useCallback(
    ({ amount }) => {
      return demoDeposit(
        {
          id: accountId,
          decimalContainer: {
            value: -getInputNumberValue(amount)!,
          },
        },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    },
    [demoDeposit, accountId, onClose],
  );

  return (
    <RemoveFundsForm
      onSubmit={submitDeposit}
      accountCurrency={accountCurrency}
      currencyDecimalScale={currencyDecimalScale}
      availableToWithdraw={availableToWithdraw}
      attributes={attributes}
    />
  );
};

export { RemoveFundsContainer };
