import Big from "big.js";
import { type FC, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import { NumberFormat, PnlFormat } from "@/app/components";
import { IconInfo, IconLock } from "@/domains/icons";
import { getBonusMainPercent } from "@/entities/bonuses/helpers";
import { BonusTooltip } from "@/entities/bonuses/tooltip";
import { calculateBonus } from "@/features/payment/deposit/helpers/helpers";
import { PaymentFooter } from "@/features/payment/ui/footer";
import type {
  BonusUserPlatform,
  PaymentMethod,
  RateResult,
  SourceOfFundsLimits,
  TradingAccount,
} from "@/services/openapi";
import { PaymentMethodGroupOptions, PaymentMethodType } from "@/services/openapi";
import { Button, DataList, ExternalLink, Image, InfoBlock, Switch, Text, UnstyledButton } from "@/shared/ui";

type Props = {
  submitIsloading: boolean;
  currentAmount: number;
  currentPaymentMethod: PaymentMethod;
  currentAccount: TradingAccount;
  feeValue: number;
  bonus: BonusUserPlatform | null;
  rate: RateResult | undefined;
  isBonusAccepted: boolean;
  paymentLink: string | undefined;
  sourceOfFundsLimits: SourceOfFundsLimits[];
  setIsBonusAccepted: (value: boolean) => void;
  onSubmit: () => void;
};

const CheckStep: FC<Props> = ({
  submitIsloading,
  currentPaymentMethod,
  currentAccount,
  currentAmount,
  isBonusAccepted,
  feeValue,
  bonus,
  rate,
  paymentLink,
  sourceOfFundsLimits,
  setIsBonusAccepted,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const { currency, digits: decimalScale } = currentAccount;

  const sourceOfFundsLimit = sourceOfFundsLimits?.find(item => item?.currency === currency);

  const isBankTransfer = currentPaymentMethod.groupOptions === PaymentMethodGroupOptions.DepositButtonBank;
  const image = currentPaymentMethod.images!.mini!;

  const bonusPercent = useMemo(() => {
    if (!bonus || !rate) {
      return 0;
    }

    const value = new Big(currentAmount).div(rate.rate!).toNumber();
    return calculateBonus(value, bonus);
  }, [currentAmount, bonus, rate]);

  const bonusAmount = useMemo(
    () => new Big(bonusPercent).div(100).mul(currentAmount).toNumber(),
    [bonusPercent, currentAmount],
  );

  const totalAmount = useMemo(() => {
    const amount = new Big(currentAmount).minus(feeValue);

    if (bonus && isBonusAccepted) {
      return amount.add(bonusAmount).toNumber();
    }

    return amount.toNumber();
  }, [bonus, bonusAmount, currentAmount, isBonusAccepted, feeValue]);

  const isLimitBeforeDisclaimer =
    sourceOfFundsLimit?.current !== void 0 &&
    sourceOfFundsLimit?.max !== void 0 &&
    sourceOfFundsLimit.current + currentAmount >= sourceOfFundsLimit.max
      ? true
      : false;

  const renderBonus = bonus && !!bonusAmount;

  return (
    <>
      <div className="flex flex-col gap-6">
        {isLimitBeforeDisclaimer && sourceOfFundsLimit && (
          <InfoBlock
            icon={<IconInfo />}
            variant="warning"
            text={
              <Trans
                i18nKey="deposit.form.check-step.disclaimer"
                components={{
                  amount: (
                    <NumberFormat value={sourceOfFundsLimit.max} currency={currency!} decimalScale={decimalScale} />
                  ),
                  underline: (
                    <ExternalLink
                      className="underline"
                      href="https://doto.com/help-center/platform-tutorials/article09"
                    />
                  ),
                }}
              />
            }
          />
        )}
        <div className="flex flex-col gap-4">
          <div className="rounded-md bg-card-bg px-4 py-2">
            <DataList>
              <DataList.Item
                label={<DataList.Label>{t("payments.check.account-id")}</DataList.Label>}
                value={<DataList.Value>{currentAccount.login}</DataList.Value>}
              />
              <DataList.Item
                label={<DataList.Label>{t("payments.payment-method")}</DataList.Label>}
                value={
                  <DataList.Value>
                    <div className="flex items-center gap-1">
                      <Image src={image} className="size-4" /> {currentPaymentMethod.title}
                    </div>
                  </DataList.Value>
                }
              />
              <DataList.Item
                label={<DataList.Label>{t("payments.check.amount.deposit")}</DataList.Label>}
                value={
                  <DataList.Value>
                    <NumberFormat value={currentAmount} decimalScale={decimalScale} currency={currency!} />
                  </DataList.Value>
                }
              />
              {renderBonus && (
                <DataList.Item
                  label={
                    <DataList.Label>
                      <NumberFormat value={bonusPercent} suffix="%" decimalScale={0} />{" "}
                      {t("payments.check.bonus.deposit")}
                    </DataList.Label>
                  }
                  value={
                    <DataList.Value
                      color={!isBonusAccepted ? "secondary" : void 0}
                      className={!isBonusAccepted ? "line-through" : void 0}
                    >
                      <PnlFormat value={bonusAmount} decimalScale={decimalScale} currency={currency!} />
                    </DataList.Value>
                  }
                />
              )}
              <DataList.Item
                label={<DataList.Label>{t("payments.check.fee")}</DataList.Label>}
                value={
                  <DataList.Value>
                    <NumberFormat value={feeValue} decimalScale={decimalScale} currency={currency!} />
                  </DataList.Value>
                }
              />
              <DataList.Item
                label={<DataList.Label variant="S / Medium">{t("payments.check.total")}</DataList.Label>}
                value={
                  <DataList.Value variant="S / Medium">
                    <NumberFormat value={totalAmount} decimalScale={decimalScale} currency={currency!} />
                  </DataList.Value>
                }
              />
            </DataList>
          </div>
        </div>

        {renderBonus && (
          <div className="rounded-lg bg-control-bg px-4 py-3">
            <div className="flex items-center justify-between gap-3">
              <div className="flex items-center gap-0.5">
                <Text variant="S / Regular" color="primary">
                  {t("deposit.form.get-bonus")}
                </Text>
                <BonusTooltip
                  bonus={bonus}
                  actionText={t("deposit.bonus.button")}
                  content={t("deposit.bonus.description", {
                    percent: getBonusMainPercent(bonus),
                  })}
                />
              </div>
              <Switch checked={isBonusAccepted} onCheckedChange={setIsBonusAccepted} />
            </div>
          </div>
        )}
      </div>

      <PaymentFooter offset="sm">
        <Button
          pending={submitIsloading}
          fullWidth
          startSection={isBankTransfer ? <Image src={image} alt={currentPaymentMethod.title!} /> : void 0}
          onClick={onSubmit}
        >
          {isBankTransfer ? t("deposit.form.check-step.submit-bank-button") : t("button.go-to-payment")}
        </Button>

        {paymentLink && (
          <Text variant="M Compact / Medium" color="secondary" className="mt-6">
            <Trans
              i18nKey={"deposit.form.open-window"}
              components={{
                button: (
                  <UnstyledButton
                    className="text-contrast-primary underline"
                    onClick={() => window.open(paymentLink, "_blank")}
                  />
                ),
              }}
            />
          </Text>
        )}

        <div className="mt-6 flex flex-col items-center gap-4 p-4 pt-6">
          <div className="grid place-items-center rounded-md bg-contrast-quinary p-2 text-positive-text *:size-6">
            <IconLock />
          </div>
          <Text align="center" variant="XS / Regular" color="secondary">
            {t("deposit.form.check-step.security")}
          </Text>
        </div>
      </PaymentFooter>
    </>
  );
};

export { CheckStep };
