import type { VariantProps } from "@stitches/react";
import type { FC, ReactNode } from "react";

import { styled } from "@/styles";

import { Flex } from "../flex";
import { Text } from "../text";

const Container = styled("div", {
  background: "$bgPrimary",
  borderRadius: "16px",
  px: "30px",

  "@bp2": {
    px: "64px",
  },

  variants: {
    verticalPaddings: {
      small: {
        py: "24px",
      },
      big: {
        py: "50px",
        "@bp2": {
          py: "104px",
        },
      },
    },
  },

  defaultVariants: {
    verticalPaddings: "big",
  },
});

type Props = VariantProps<typeof Container> & {
  title: string;
  description: string;
  imageSrc?: string;
  children?: ReactNode;
};

/**
 * @deprecated
 */
export const NoContentMessage: FC<Props> = ({ children, description, title, imageSrc, verticalPaddings }) => {
  return (
    <Container verticalPaddings={verticalPaddings}>
      {imageSrc && (
        <Flex css={{ justifyContent: "center", mb: "16px" }}>
          <img style={{ width: "158px", height: "158px" }} src={imageSrc} alt="" loading="lazy" />
        </Flex>
      )}
      <Text as="p" weight="bold" size="4" align="center" lineHeight={{ "@initial": "3", "@bp1": "1" }}>
        {title}
      </Text>
      <Text as="p" family="roboto" color="dimmed" align="center" lineHeight="3" css={{ mt: "8px" }}>
        {description}
      </Text>
      {children && <Flex css={{ justifyContent: "center", mt: "24px" }}>{children}</Flex>}
    </Container>
  );
};
