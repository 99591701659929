import type {
  ChartingLibraryWidgetOptions,
  IChartingLibraryWidget,
  ResolutionString,
} from "public/charting_library/charting_library";
import { type FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { logError } from "@/app/libs/sentry";
import { useSignal } from "@/hooks/signal.hook";
import type { WebSocketsEventTick } from "@/services/openapi";

import type { MergedTerminalSymbol, SymbolsListType } from "../helpers/symbols";
import { useLayoutContext, webviewChannel } from "../layout/context";
import { useSignalContext } from "../signal/context";
import { getChartColors } from "./config/colors";
import { getDrawingAccess } from "./config/drawings";
import { getTradingViewFeatures } from "./config/features";
import { getLoadAdapter } from "./config/load-adapter";
import { getTradingViewLocale } from "./config/localization";
import { getChartOverrides } from "./config/overrides";
import { setAutoPricescale } from "./config/pricescale";
import { useChartContext } from "./context";
import { getDatafeed } from "./datafeed";
import { getSymbolTimezone } from "./datafeed/timezone";
import { ChartSignalDialog } from "./signal-dialog";
import { ChartWidget } from "./widget";

declare global {
  interface Window {
    tvWidget: IChartingLibraryWidget;
  }
}

type Props = {
  symbolInfo: MergedTerminalSymbol;
  isMobile: boolean;
  symbolsList: SymbolsListType;
  accountId: string;
  accountCurrency: string;
  tradingServerId: string;
  tickMessage: WebSocketsEventTick | null;
  isDarkTheme: boolean;
  hasSignal: boolean;
  isChartExpanded: boolean;
  currencyDecimalScale: number;
  chartInfo: ReturnType<typeof useChartContext>["chartInfo"];
  isMobileChartPage: ReturnType<typeof useLayoutContext>["isMobileChartPage"];
  lastInterval: string | undefined;
  setIsChartExpanded: ReturnType<typeof useLayoutContext>["setIsChartExpanded"];
  setChartInfo: ReturnType<typeof useChartContext>["setChartInfo"];
  isSignalChartDialogShown: ReturnType<typeof useSignalContext>["isSignalChartDialogShown"];
  closeSignalChartDialog: ReturnType<typeof useSignalContext>["closeSignalChartDialog"];
  showSignalChartDialog: ReturnType<typeof useSignalContext>["showSignalChartDialog"];
  isTerminalTargetPricesEnabled: ReturnType<typeof useSignalContext>["isTerminalTargetPricesEnabled"];
  isSignalLinesShown: ReturnType<typeof useSignalContext>["isSignalLinesShown"];
  chartLines: ReturnType<typeof useSignalContext>["chartLines"];
  openSignalForm: ReturnType<typeof useSignalContext>["openSignalForm"];
};

const Chart: FC<Props> = ({
  symbolInfo,
  isMobile,
  symbolsList,
  accountId,
  accountCurrency,
  tradingServerId,
  chartInfo,
  isChartExpanded,
  tickMessage,
  isDarkTheme,
  lastInterval,
  setChartInfo,
  setIsChartExpanded,
  currencyDecimalScale,
  hasSignal,
  closeSignalChartDialog,
  isSignalChartDialogShown,
  openSignalForm,
  showSignalChartDialog,
  chartLines,
  isTerminalTargetPricesEnabled,
  isSignalLinesShown,
  isMobileChartPage,
}) => {
  const [widgetReady, setWidgetReady] = useState(false);

  const { i18n } = useTranslation();

  const chartColors = useMemo(() => getChartColors(isDarkTheme), [isDarkTheme]);

  const $tick = useSignal(tickMessage);
  const $symbol = useSignal(symbolInfo.symbol!);

  useEffect(() => {
    import("public/charting_library/charting_library.esm").then(TradingView => {
      try {
        // https://www.tradingview.com/charting-library-docs/latest/core_concepts/Widget-Constructor/
        const widgetOptions: ChartingLibraryWidgetOptions = {
          symbol: symbolInfo.symbol!,
          interval: (lastInterval || "60") as ResolutionString,
          container: "tv_chart_container",
          autosize: true,
          datafeed: getDatafeed({ symbolsList, tradingServerId, $tick }),
          custom_css_url: "/chart-styles.6.css", // TODO: cache busting
          library_path: "/charting_library/",
          timezone: getSymbolTimezone(),
          locale: getTradingViewLocale(i18n.resolvedLanguage),
          custom_font_family: "Roboto",
          theme: isDarkTheme ? "dark" : "light",
          disabled_features: getTradingViewFeatures({ isMobile, isChartExpanded }).disabledFeatures,
          enabled_features: getTradingViewFeatures({ isMobile, isChartExpanded }).enabledFeatures,
          loading_screen: {
            backgroundColor: chartColors.loadingBackground,
            foregroundColor: chartColors.loadingForeground,
          },
          overrides: getChartOverrides({ chartColors, isChartExpanded }),
          save_load_adapter: getLoadAdapter({ accountId, $symbol }),
          drawings_access: getDrawingAccess(),
          saved_data: chartInfo.data ? chartInfo.data : undefined,
          timeframe: chartInfo.range ? chartInfo.range : undefined,
          auto_save_delay: 5,
        };

        window.tvWidget = new TradingView.widget(widgetOptions);

        window.tvWidget.onChartReady(() => {
          setWidgetReady(true);
          if (webviewChannel) {
            webviewChannel.postMessage("chartLoaded");
          }
          setAutoPricescale(window.tvWidget);
        });
      } catch (error) {
        logError(error);
      }
    });

    return () => window.tvWidget && window.tvWidget.remove();
  }, []);

  return (
    <>
      {widgetReady && (
        <ChartWidget
          accountId={accountId}
          accountCurrency={accountCurrency}
          symbolInfo={symbolInfo}
          chartColors={chartColors}
          isMobile={isMobile}
          isDarkTheme={isDarkTheme}
          isChartExpanded={isChartExpanded}
          setChartInfo={setChartInfo}
          setIsChartExpanded={setIsChartExpanded}
          currencyDecimalScale={currencyDecimalScale}
          chartLines={chartLines}
          hasSignal={hasSignal}
          isSignalChartDialogShown={isSignalChartDialogShown}
          isSignalLinesShown={isSignalLinesShown}
          isTerminalTargetPricesEnabled={isTerminalTargetPricesEnabled}
          showSignalChartDialog={showSignalChartDialog}
          signal={symbolInfo.signal}
        />
      )}
      <div className="relative h-full">
        <div className="h-full lg:bg-surface-canvas lg:ps-[5px]" id="tv_chart_container" />
        {!isMobileChartPage && hasSignal && widgetReady && (
          <ChartSignalDialog
            signal={symbolInfo.signal!}
            symbol={symbolInfo.symbol!}
            closeSignalChartDialog={closeSignalChartDialog}
            isSignalChartDialogShown={isSignalChartDialogShown}
            openSignalForm={openSignalForm}
          />
        )}
      </div>
    </>
  );
};

Chart.displayName = "Chart";

export { Chart };
