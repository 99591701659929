import type { FC } from "react";
import { useState } from "react";

import type { TwoFactor } from "@/services/openapi";
import { usePlatformData } from "@/state/server/platform/platform.hooks";
import { useProfileData } from "@/state/server/profile/profile.hooks";

import { Confirm2FAContainer } from "./steps/confirm/container";
import { Disable2FAContainer } from "./steps/disable/container";
import { Enable2FAContainer } from "./steps/enable/container";

type TwoFAStep = "enable" | "confirm" | "disable";
type TwoFAConfirmInfo = TwoFactor & { oneTimeToken: string };

const TwoFactorContainer: FC = () => {
  const { options, email } = useProfileData();
  const { twoFactor } = usePlatformData();
  const { is2FaEnabled } = options!;

  const [step, setStep] = useState<TwoFAStep>(is2FaEnabled ? "disable" : "enable");
  const [twoFactorData, setTwoFactorData] = useState<TwoFAConfirmInfo | null>(null);

  return (
    <>
      {step === "enable" && (
        <Enable2FAContainer
          setStep={setStep}
          twoFactor={twoFactor!}
          setTwoFactorData={setTwoFactorData}
          email={email!}
        />
      )}
      {step === "confirm" && twoFactorData && (
        <Confirm2FAContainer setStep={setStep} setTwoFactorData={setTwoFactorData} twoFactorData={twoFactorData} />
      )}
      {step === "disable" && <Disable2FAContainer setStep={setStep} email={email!} />}
    </>
  );
};

export { TwoFactorContainer };
export type { TwoFAStep, TwoFAConfirmInfo };
