import { type ComponentPropsWithoutRef, type ElementRef, type FC, forwardRef, memo } from "react";
import { useFormContext } from "react-hook-form";

import { Button } from "@/shared/ui";

type Props = ComponentPropsWithoutRef<typeof Button> & {
  checkValid?: boolean;
  checkDirty?: boolean;
};

const SubmitButton: FC<Props> = forwardRef<ElementRef<typeof Button>, Props>(
  (
    {
      type = "submit",
      checkDirty = false,
      checkValid = false,
      disabled: disabledProp,
      pending: pendingProp,
      ...restProps
    },
    ref,
  ) => {
    const {
      formState: { isDirty, isValid, isSubmitting },
    } = useFormContext();

    const pending = isSubmitting || pendingProp!;

    const disabled = (checkValid && !isValid) || (checkDirty && !isDirty) || disabledProp;

    return <Button disabled={disabled} type={type} pending={pending} ref={ref} {...restProps} />;
  },
);

const Component = memo(SubmitButton);

export { Component as SubmitButton };
