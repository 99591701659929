import { otCodesService } from "@/services/api";
import type { OtCodesApiRequestOneTimeCodeRequest, OtCodesApiVerifyOneTimeCodeRequest } from "@/services/openapi";

const verifyOneTimeCode = async (values: OtCodesApiVerifyOneTimeCodeRequest) =>
  await (
    await otCodesService().verifyOneTimeCode(values)
  ).data;

const requestOneTimeCode = async (values: OtCodesApiRequestOneTimeCodeRequest) =>
  await (
    await otCodesService().requestOneTimeCode(values)
  ).data;

export { verifyOneTimeCode, requestOneTimeCode };
