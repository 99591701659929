import { QRCodeSVG } from "qrcode.react";
import { type ComponentPropsWithoutRef, type FC } from "react";

const QRCode: FC<ComponentPropsWithoutRef<typeof QRCodeSVG>> = ({
  bgColor = "transparent",
  fgColor = "currentColor",
  ...props
}) => {
  return <QRCodeSVG bgColor={bgColor} fgColor={fgColor} {...props} />;
};

export { QRCode };
