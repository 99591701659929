import { type FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Dialog, ExternalLink, Text } from "@/shared/ui";

const SignalDisclaimerDialog: FC = () => {
  const { t } = useTranslation();

  return (
    <Dialog.Content size="lg">
      <Dialog.Header title={t("terminal.signal.disclaimer.title")} />
      <Text color="primary" variant="S / Regular" className="whitespace-pre-wrap">
        <Trans
          i18nKey="terminal.signal.disclaimer.text"
          values={{ link: "terms_of_use_web_sites_en.pdf" }}
          components={{
            anchor: (
              <ExternalLink
                className="text-positive-text hover:underline"
                href="https://global.tradingcentral.com/www/pdf/terms_of_use_web_sites_en.pdf"
              />
            ),
          }}
        />
      </Text>
    </Dialog.Content>
  );
};

export { SignalDisclaimerDialog };
