import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { QRCode } from "@/app/components";
import mt4Logo from "@/assets/images/mt4-logo.png";
import mt5Logo from "@/assets/images/mt5-logo.png";
import { Text } from "@/shared/ui";

type Props = {
  version: "4" | "5";
  link: string;
};

const AccountMTDetailsQRCodeBlock: FC<Props> = ({ version, link }) => {
  const { t } = useTranslation();

  return (
    <>
      <div role="separator" className="my-6 h-px w-full bg-contrast-quinary" />
      <div className="flex justify-between gap-6">
        <div className="flex flex-col gap-1">
          <Text as="h4" variant="M / Medium" color="primary">
            {t("accounts.mt-details.qr-code.title", { version })}
          </Text>
          <Text variant="S / Regular" color="secondary">
            {t("accounts.mt-details.qr-code.description", { version })}
          </Text>
        </div>
        <div className="p-0.5 text-contrast-primary">
          <QRCode
            value={link}
            size={90}
            imageSettings={{ src: version === "5" ? mt5Logo : mt4Logo, width: 26, height: 26, excavate: true }}
          />
        </div>
      </div>
    </>
  );
};

export { AccountMTDetailsQRCodeBlock };
