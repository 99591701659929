import type { FC } from "react";
import { useEffect, useMemo } from "react";

import { HookForm, SubmitButton, useControlledField, useHookForm } from "@/app/form";
import { formatInputNumberValue, getInputNumberValue } from "@/features/terminal/helpers/formatting";
import { useTranslation } from "@/hooks/translator.hook";
import { TerminalDealType } from "@/services/openapi";
import { Button, NumberInput, Popover } from "@/shared/ui";

import { getModifyOrderRules, getOpeningPriceThresholdValue } from "../helpers";
import { ModifyMoreLessDescription } from "../more-less-description";

enum Fields {
  OPENING_PRICE = "openingPrice",
}

type FormValues = {
  [Fields.OPENING_PRICE]: string;
};

type Props = {
  onSubmit: (values: FormValues) => Promise<unknown>;
  priceDecimalScale: number;
  openPrice: number;
  ask: number;
  bid: number;
  orderType: TerminalDealType;
};

const OpenPriceForm: FC<Props> = ({ onSubmit, priceDecimalScale, ask, bid, orderType, openPrice }) => {
  const { t } = useTranslation();

  const form = useHookForm<FormValues>({
    defaultValues: {
      [Fields.OPENING_PRICE]: formatInputNumberValue(openPrice, priceDecimalScale),
    },
  });
  const { watch, formState, trigger, control } = form;
  const { touchedFields } = formState;

  const isTouched = touchedFields[Fields.OPENING_PRICE];

  const { openingPrice: op } = watch();
  const openingPrice = getInputNumberValue(op);

  const thresholdValue = useMemo(() => getOpeningPriceThresholdValue({ orderType, bid, ask }), [orderType, ask, bid]);

  useEffect(() => {
    if (isTouched) {
      trigger(Fields.OPENING_PRICE);
    }
  }, [thresholdValue, isTouched, openingPrice]);

  const [field, { invalid, pending }] = useControlledField<FormValues>({
    name: Fields.OPENING_PRICE,
    control,
    rules: getModifyOrderRules({
      thresholdValue: thresholdValue,
      type: "openPrice",
      orderType,
      value: openingPrice,
      required: true,
    }),
  });

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <NumberInput
        autoFocus
        decimalScale={priceDecimalScale}
        pending={pending}
        invalid={invalid}
        placeholder={t("terminal.orders.open-price.placeholder")!}
        descriptor={
          <ModifyMoreLessDescription
            decimalScale={priceDecimalScale}
            thresholdValue={thresholdValue}
            orderType={orderType}
            onChange={field.onChange}
            type="openPrice"
          />
        }
        {...field}
      />
      <Popover.Footer>
        <SubmitButton size="sm">{t("terminal.orders.open-price.action")}</SubmitButton>
        <Popover.Close asChild>
          <Button variant="secondary" size="sm">
            {t("button.cancel")}
          </Button>
        </Popover.Close>
      </Popover.Footer>
    </HookForm>
  );
};

export { OpenPriceForm };
