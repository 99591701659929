import { type ComponentProps, type FC, useCallback, useState } from "react";

import type { TradingAccount } from "@/services/openapi";
import { useChangeAccountLeverageMutation } from "@/state/server/accounts";

import { ChangeLeverageForm } from "./form";
import { ChangeLeverateWarningDialog } from "./warning-dialog";

type Props = {
  account: TradingAccount;
  hasOpenPositions: boolean;
  onClose: () => void;
};

const ChangeLeverageContainer: FC<Props> = ({ account, hasOpenPositions, onClose }) => {
  const [step, setStep] = useState<"warningDialog" | "form">(hasOpenPositions ? "warningDialog" : "form");

  const { mutateAsync: changeLeverage } = useChangeAccountLeverageMutation({ successMessage: "leverage-changed" });

  const handleSubmit: ComponentProps<typeof ChangeLeverageForm>["onSubmit"] = useCallback(
    ({ value }) => {
      return changeLeverage(
        { id: account.id!, integerContainer: { value: +value } },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    },
    [changeLeverage, account, onClose],
  );

  return step === "warningDialog" ? (
    <ChangeLeverateWarningDialog accountId={account.id!} onFormOpen={() => setStep("form")} />
  ) : (
    <ChangeLeverageForm
      onSubmit={handleSubmit}
      leverage={account.leverage!}
      availableLeverages={account.leveragesAvailable!}
      leverageAvailableDefault={account.leverageAvailableDefault!}
    />
  );
};

export { ChangeLeverageContainer };
