import { cva } from "class-variance-authority";
import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { ExternalLink, Text } from "@/shared/ui";
import { usePlatformTranslationsQuery } from "@/state/server/platform";

import { cabinetContainerStyles } from "./page";

const linkStyles = cva(
  "typography-S-Medium rounded-lg px-3 py-1 text-contrast-secondary outline-none transition-colors hover:text-contrast-primary focus-visible:text-contrast-primary focus-visible:outline-2 focus-visible:outline-offset-0 focus-visible:outline-accent-text",
)();

const CabinetFooter: FC = () => {
  const { i18n } = useTranslation();
  const { data } = usePlatformTranslationsQuery({ locale: i18n.resolvedLanguage });

  if (!data) {
    return null;
  }

  return (
    <footer className="border-t border-surface-under-canvas py-6">
      <div className={cabinetContainerStyles({ size: "md" })}>
        <div className="flex flex-wrap items-center justify-between gap-2">
          <ExternalLink className={linkStyles} href={data["Footer::about-us::doc"]}>
            {data["Footer::about-us::word"]}
          </ExternalLink>
          <ExternalLink className={linkStyles} href={data["Footer::legal-documents::doc"]}>
            {data["Footer::legal-documents::word"]}
          </ExternalLink>
          <ExternalLink className={linkStyles} href={data["Footer::affiliate-program::doc"]}>
            {data["Footer::affiliate-program::word"]}
          </ExternalLink>
          <ExternalLink className={linkStyles} href={data["Footer::help-center::doc"]}>
            {data["Footer::help-center::word"]}
          </ExternalLink>
        </div>
        <div className="my-5 h-px bg-contrast-quaternary" />
        <Text variant="XS / Regular" align="center" color="tertiary">
          © {new Date().getFullYear()}, {data["Footer::Copyright"]}
        </Text>
      </div>
    </footer>
  );
};

export { CabinetFooter };
