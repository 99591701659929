import { type ComponentPropsWithoutRef, type ElementRef, type FC, forwardRef, type ReactNode } from "react";
import { Link, type LinkProps } from "react-router-dom";

import { IconArrowLeft } from "@/domains/icons";
import { IconButton, Text } from "@/shared/ui";

const Title = forwardRef<ElementRef<typeof Text>, ComponentPropsWithoutRef<typeof Text>>((props, ref) => {
  return <Text as="h2" color="primary" variant="XL / Medium" className="py-1" {...props} ref={ref} />;
});

const BackButton = forwardRef<ElementRef<typeof IconButton>, ComponentPropsWithoutRef<typeof IconButton>>(
  (props, ref) => {
    return (
      <IconButton size="sm" variant="flat" className="-ms-2" {...props} ref={ref}>
        <IconArrowLeft />
      </IconButton>
    );
  },
);

const BackLink = forwardRef<ElementRef<typeof IconButton>, ComponentPropsWithoutRef<typeof IconButton> & LinkProps>(
  ({ to, ...props }, ref) => {
    return (
      <IconButton size="sm" variant="flat" className="-ms-2" asChild {...props} ref={ref}>
        <Link to={to}>
          <IconArrowLeft />
        </Link>
      </IconButton>
    );
  },
);

type Props = {
  backButton?: ReactNode;
  children?: ReactNode;
};

const CabinetHeader: FC<Props> = ({ children, backButton }) => {
  return (
    <div>
      {backButton}
      <div className="mb-1 flex flex-wrap items-center justify-between gap-4 py-4">{children}</div>
    </div>
  );
};

const Component = Object.assign(CabinetHeader, { Title, BackButton, BackLink });

export { Component as CabinetHeader };
