import { useStore } from "@nanostores/react";
import { createContext, memo, type ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { logError } from "@/app/libs/sentry";
import { TerminalEventSymbolSessionStateType, TradingCentralSignalType } from "@/services/openapi";
import {
  $symbolsHistoryPricesMessage,
  $symbolsSessionsMessage,
  $symbolsSignalsMessage,
  $symbolsWidgetsMessage,
  $tickMessage,
  socketClient,
} from "@/services/websocket";

import { useSymbolsContext } from "../contexts/symbols.context";
import { convertSocketWidget, isSymbolAvailable, type MergedTerminalSymbol } from "../helpers/symbols";

type ContextProps = {
  symbolInfo: MergedTerminalSymbol;
  currentSymbol: string;
  setSymbol: (symbol: string) => void;
  isTradingAvailable: boolean;
};

const Context = createContext<ContextProps | undefined>(undefined);

const Provider = memo(
  ({
    children,
    symbol,
    changeSymbol,
  }: {
    children: ReactNode;
    symbol: string;
    changeSymbol: (symbol: string) => void;
  }) => {
    const { symbolsList } = useSymbolsContext();
    const [symbolInfo, setSymbolInfo] = useState<MergedTerminalSymbol>(() => symbolsList[symbol]!);

    const symbolsWidgetsMessage = useStore($symbolsWidgetsMessage);
    const symbolsSignalsMessage = useStore($symbolsSignalsMessage);
    const symbolsSessionsMessage = useStore($symbolsSessionsMessage);
    const symbolsHistoryPricesMessage = useStore($symbolsHistoryPricesMessage);
    const tickMessage = useStore($tickMessage);

    const updateSymbol: ContextProps["setSymbol"] = useCallback(
      symbol => {
        const symbolInfo = symbolsList[symbol]!;
        changeSymbol(symbol);
        setSymbolInfo(symbolInfo);
        socketClient.subscribeTick([symbol]);
      },
      [changeSymbol, symbolsList],
    );

    useEffect(() => {
      socketClient.subscribeTick([symbol]);

      return () => {
        socketClient.unsubscribeTick();
      };
    }, []);

    useEffect(() => {
      if (!tickMessage) return;

      try {
        const { t } = tickMessage;
        if (symbolInfo.symbol === t!.s) {
          setSymbolInfo(prev => ({
            ...prev,
            priceAsk: t!.a,
            priceBid: t!.b,
          }));
        }
      } catch (error) {
        logError(error);
      }
    }, [tickMessage]);

    useEffect(() => {
      try {
        if (!symbolsHistoryPricesMessage) return;

        const symbolUpdate = symbolsHistoryPricesMessage.s!.find(({ s }) => s === symbolInfo.symbol);

        if (symbolUpdate) {
          setSymbolInfo(prev => ({
            ...prev,
            priceLast24H: symbolUpdate.p!,
            priceHigh24H: symbolUpdate.h!,
            priceLow24H: symbolUpdate.l!,
          }));
        }
      } catch (error) {
        logError(error);
      }
    }, [symbolsHistoryPricesMessage]);

    useEffect(() => {
      try {
        if (!symbolsSignalsMessage) return;

        const symbolUpdate = symbolsSignalsMessage.s!.find(({ s }) => s === symbolInfo.symbol)!;

        if (symbolUpdate) {
          const { pr1, pr2, ps1, ps2, pt, t } = symbolUpdate;
          setSymbolInfo(prev => ({
            ...prev,
            signal: {
              type: t as any as TradingCentralSignalType,
              priceTarget: pt,
              priceResistance1: pr1,
              priceResistance2: pr2,
              priceSupport1: ps1,
              priceSupport2: ps2,
            },
          }));
        }
      } catch (error) {
        logError(error);
      }
    }, [symbolsSignalsMessage]);

    useEffect(() => {
      try {
        if (!symbolsWidgetsMessage) return;

        const symbolUpdate = symbolsWidgetsMessage.s!.find(({ s }) => s === symbolInfo.symbol)!;

        if (symbolUpdate) {
          const { b, sr, ss, t } = symbolUpdate;
          setSymbolInfo(prev => ({
            ...prev,
            widget: convertSocketWidget({ b, sr, ss, t }),
          }));
        }
      } catch (error) {
        logError(error);
      }
    }, [symbolsWidgetsMessage]);

    useEffect(() => {
      try {
        if (!symbolsSessionsMessage) return;

        const symbolUpdate = symbolsSessionsMessage.s!.find(({ s }) => s === symbolInfo.symbol)!;

        if (symbolUpdate) {
          const { d, e, t } = symbolUpdate;
          setSymbolInfo(prev => ({
            ...prev,
            state: t as any as TerminalEventSymbolSessionStateType,
            periodDateEnd: e,
            periodDuration: d,
          }));
        }
      } catch (error) {
        logError(error);
      }
    }, [symbolsSessionsMessage]);

    const isTradingAvailable: ContextProps["isTradingAvailable"] = useMemo(
      () => isSymbolAvailable(symbolInfo.state!),
      [symbolInfo.state],
    );

    const value: ContextProps = useMemo(
      () => ({
        currentSymbol: symbol,
        symbolInfo,
        setSymbol: updateSymbol,
        isTradingAvailable,
      }),
      [symbolInfo, symbol, isTradingAvailable, updateSymbol],
    );

    return <Context.Provider value={value}>{children}</Context.Provider>;
  },
);

Provider.displayName = "TerminalCurrentSymbolContextProvider";

const useCurrentSymbolContext = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("useCurrentSymbolContext must be used within a TerminalCurrentSymbolContextProvider");
  }

  return context;
};

export { Provider as TerminalCurrentSymbolContextProvider, useCurrentSymbolContext };
