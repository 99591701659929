import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { PnlFormat } from "@/app/components";
import { getNumberTextColor } from "@/app/ui/colors";
import { getAccountRoute } from "@/routes/cabinet.routes";
import { type TradingAccount, TradingServerPlatform } from "@/services/openapi";
import { Button, Text } from "@/shared/ui";

import { AccountCard } from "../card";
import { AccountMTDetailsButton } from "../mt-details/button";
import { TradeButton } from "../trade/button";

type Props = {
  account: TradingAccount;
  isActive?: boolean;
};

const RealAccountCard: FC<Props> = ({ account, isActive }) => {
  const { t } = useTranslation();

  const { currency, digits, pnL, platform, id } = account;

  return (
    <AccountCard
      account={account}
      pnlSlot={
        !!pnL && (
          <Text variant="M / Medium" color={getNumberTextColor(pnL)}>
            <PnlFormat value={pnL} decimalScale={digits} currency={currency!} />
          </Text>
        )
      }
      actionSlot={<AccountMTDetailsButton account={account} />}
      isActive={isActive}
    >
      <AccountCard.Buttons>
        {platform === TradingServerPlatform.MetaTrader5 && <TradeButton account={account} />}
        <Button size="sm" variant="secondary" asChild>
          <Link to={getAccountRoute(id!)}>{t("accounts.buttons.manage")}</Link>
        </Button>
      </AccountCard.Buttons>
    </AccountCard>
  );
};

export { RealAccountCard };
