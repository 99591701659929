import { type ComponentProps, type FC, useCallback } from "react";

import type { ChangePasswordInfo, ProfileStep } from "@/features/profile/page";
import { useValidateTwoStepAuthMutation } from "@/state/server/auth";

import { ChangePasswordVerify2FAForm } from "./form";

type Props = {
  setStep: (step: ProfileStep) => void;
  setChangePasswordData: (data: ChangePasswordInfo) => void;
};

const ChangePasswordVerify2FAContainer: FC<Props> = ({ setStep, setChangePasswordData }) => {
  const { mutateAsync: validate2FA } = useValidateTwoStepAuthMutation();

  const handleSubmit: ComponentProps<typeof ChangePasswordVerify2FAForm>["onSubmit"] = useCallback(
    ({ code }) => {
      return validate2FA(
        {
          twoFactorUniversalRequest: {
            twoFactorCode: code,
          },
        },
        {
          onSuccess: ({ token }) => {
            setStep("changePassword");
            setChangePasswordData({ type: "2fa", twoFactorCode: token! });
          },
        },
      );
    },
    [validate2FA, setStep, setChangePasswordData],
  );

  return <ChangePasswordVerify2FAForm onSubmit={handleSubmit} />;
};

export { ChangePasswordVerify2FAContainer };
