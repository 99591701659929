import { OTPInput, type OTPInputProps, REGEXP_ONLY_DIGITS, type SlotProps } from "input-otp";
import { type ElementRef, forwardRef, type ReactNode } from "react";

import { cn } from "@/shared/styles";

import { Text } from "..";

const Slot = forwardRef<ElementRef<"div">, SlotProps & { invalid?: boolean }>(
  ({ char, hasFakeCaret, isActive, invalid }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          "typography-S-Regular relative flex h-12 w-10 items-center justify-center rounded-md border border-input-border bg-input-bg text-contrast-primary transition-all",
          invalid && "border-negative-text",
          isActive && "ring-offset-background z-10 border-input-border ring-2 ring-accent-text",
          invalid && "ring-negative-text",
        )}
      >
        {char}
        {hasFakeCaret && (
          <div className="pointer-events-none absolute inset-0 flex items-center justify-center">
            <div className="h-5 w-px animate-caret-blink bg-contrast-primary" />
          </div>
        )}
      </div>
    );
  },
);
Slot.displayName = "InputOTPSlot";

type Props = Omit<OTPInputProps, "children"> & {
  invalid?: boolean;
  errorMessage?: ReactNode;
};

const InputOTP = forwardRef<ElementRef<typeof OTPInput>, Props>(
  ({ className, invalid, errorMessage, ...props }, ref) => (
    <div className={cn("flex flex-col items-center gap-3", className)}>
      <OTPInput
        ref={ref}
        containerClassName="flex items-center gap-2"
        pattern={REGEXP_ONLY_DIGITS}
        render={({ slots }) => slots.map((slot, i) => <Slot key={i} invalid={invalid} {...slot} />)}
        {...props}
      />
      {invalid && errorMessage && (
        <Text color="negative" variant="S / Regular">
          {errorMessage}
        </Text>
      )}
    </div>
  ),
);
InputOTP.displayName = "InputOTP";

export { InputOTP };
