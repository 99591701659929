import type { ComponentProps, FC } from "react";
import { useCallback } from "react";

import { useTranslation } from "@/hooks/translator.hook";
import { CabinetHeader } from "@/layouts/cabinet/header";
import { CabinetPage } from "@/layouts/cabinet/page";
import type { PasswordInfo } from "@/services/openapi";
import { toast, ToastIcons } from "@/shared/ui";
import { useChangePasswordMutation } from "@/state/server/auth";

import type { ChangePasswordInfo, ProfileStep } from "../page";
import { ChangePasswordForm } from "./form";

type Props = {
  setStep: (step: ProfileStep) => void;
  setChangePasswordData: (value: null) => void;
  changePasswordData: ChangePasswordInfo;
  passwordRules: PasswordInfo;
};

const ChangePasswordContainer: FC<Props> = ({ setStep, setChangePasswordData, changePasswordData, passwordRules }) => {
  const { t } = useTranslation();

  const { mutateAsync: changePassword } = useChangePasswordMutation();

  const handleSubmit: ComponentProps<typeof ChangePasswordForm>["onSubmit"] = useCallback(
    ({ password }) => {
      return changePassword(
        {
          passwordChangeUniversalRequest: {
            password,
            oneTimeToken: changePasswordData.type === "verify" ? changePasswordData.oneTimeToken : void 0,
            twoFactorCode: changePasswordData.type === "2fa" ? changePasswordData.twoFactorCode : void 0,
          },
        },
        {
          onSuccess: () => {
            setStep("settings");
            toast({
              icon: ToastIcons.SUCCESS,
              title: t("profile-settings.change-password.success-message")!,
            });
          },
        },
      );
    },
    [changePassword, setStep, t, changePasswordData],
  );

  return (
    <CabinetPage
      size="sm"
      header={
        <CabinetHeader
          backButton={
            <CabinetHeader.BackButton
              onClick={() => {
                setStep("settings");
                setChangePasswordData(null);
              }}
            />
          }
        >
          <CabinetHeader.Title>{t("profile-settings.change-password.title")}</CabinetHeader.Title>
        </CabinetHeader>
      }
    >
      <ChangePasswordForm onSubmit={handleSubmit} passwordRules={passwordRules} />
    </CabinetPage>
  );
};

export { ChangePasswordContainer };
