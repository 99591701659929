import { type FC, type ReactNode } from "react";

type Props = {
  children?: ReactNode;
};

const SymbolInfoBlock: FC<Props> = ({ children }) => {
  return <div className="rounded-lg bg-card-bg-accent p-4">{children}</div>;
};

export { SymbolInfoBlock };
