import type { FC } from "react";
import { Outlet } from "react-router-dom";

import AuthBg from "@/assets/images/AuthBg.png";
import { Footer } from "@/components/footer";
import { Grid } from "@/components/grid";
import { Header } from "@/components/header";
import { styled } from "@/styles/stitches.config";

const Wrapper = styled(Grid, {
  minHeight: "100%",
  gridTemplateRows: "auto 1fr auto",
  background:
    "radial-gradient(311.11% 100% at 50% 100%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.2) 100%), linear-gradient(335.74deg, rgba(255, 255, 255, 0.8) 9.02%, rgba(169, 169, 169, 0.8) 84.49%)",
});

const Content = styled("main", {
  "@bp3": {
    pt: "40px",
    pb: "80px",
    maxWidth: "$appContainer",
    px: "$containerXPadding",
    m: "0 auto",
    width: "100%",
  },
});

const ContentWrapper = styled("div", {
  backgroundImage: `url(${AuthBg})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
});

export const AuthLayout: FC = () => {
  return (
    <>
      <Wrapper>
        <Header />
        <ContentWrapper>
          <Content>
            <Outlet />
          </Content>
        </ContentWrapper>
        <Footer />
      </Wrapper>
    </>
  );
};
