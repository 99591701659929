import { type FC } from "react";

import { Skeleton } from "@/shared/ui";

type Props = {
  hasTabs?: boolean;
};

const PlaceOrderSkeleton: FC<Props> = ({ hasTabs }) => (
  <div className="flex grow flex-col overflow-auto bg-surface-canvas p-4 scrollbar-hide">
    {hasTabs && (
      <div className="mb-[34px] mt-3 flex justify-between px-7">
        <Skeleton className="h-[18px] w-[80px] rounded-lg" />
        <Skeleton className="h-[18px] w-[80px] rounded-lg" />
      </div>
    )}

    <Skeleton className="h-14 w-full rounded-lg" />
    <Skeleton className="mb-4 mt-3 h-14 w-full rounded-lg" />
    <Skeleton className="h-2 w-full rounded-lg" />

    <div className="mb-7 mt-5 flex justify-between">
      <Skeleton className="h-3 w-10 rounded-lg" />
      <Skeleton className="h-3 w-10 rounded-lg" />
    </div>

    <div className="mb-6 flex flex-col gap-[18px]">
      <Skeleton className="h-3.5 w-full rounded-lg" />
      <Skeleton className="h-3.5 w-full rounded-lg" />
      <Skeleton className="h-3.5 w-full rounded-lg" />
    </div>

    <div className="mb-4 flex flex-col gap-2">
      <Skeleton className="h-14 w-full rounded-lg lg:h-12" />
      <Skeleton className="h-14 w-full rounded-lg lg:h-12" />
      <Skeleton className="h-14 w-full rounded-lg lg:h-12" />
    </div>

    <Skeleton className="h-14 w-full rounded-full" />
  </div>
);

export { PlaceOrderSkeleton };
