import { authService } from "../api";
import type {
  AuthApiAuthorizeRequest,
  AuthApiAuthorizeUsingFacebookRequest,
  AuthApiAuthorizeUsingGoogleCodeRequest,
  AuthApiChangePasswordUniversalRequest,
  AuthApiConfirmEmailRequest,
  AuthApiConfirmTwoStepAuthUniversalRequest,
  AuthApiCreateTwoStepAuthRecoveryCodesUniversalRequest,
  AuthApiDisableTwoStepAuthUniversalRequest,
  AuthApiRegisterRequest,
  AuthApiResetPasswordByLinkRequest,
  AuthApiSendRecoveryPasswordLinkRequest,
  AuthApiValidateTwoStepAuthRequest,
} from "../openapi";

export const signIn = async (values: AuthApiAuthorizeRequest) => await (await authService().authorize(values)).data;

export const validateTwoStepAuth = async (values: AuthApiValidateTwoStepAuthRequest) =>
  await (
    await authService().validateTwoStepAuth(values)
  ).data;

export const forgotPassword = async (values: AuthApiSendRecoveryPasswordLinkRequest) =>
  await (
    await authService().sendRecoveryPasswordLink(values)
  ).data;

export const resetPassword = async (values: AuthApiResetPasswordByLinkRequest) =>
  await (
    await authService().resetPasswordByLink(values)
  ).data;

export const signUp = async (values: AuthApiRegisterRequest) => await (await authService().register(values)).data;

export const resendConfirmationLink = async () => await (await authService().resendConfirmationLink()).data;

export const confirmEmail = async (values: AuthApiConfirmEmailRequest) =>
  await (
    await authService().confirmEmail(values)
  ).data;

export const changePasswordUniversal = async (values: AuthApiChangePasswordUniversalRequest) =>
  await (
    await authService().changePasswordUniversal(values)
  ).data;

export const confirmTwoStepAuthUniversal = async (values: AuthApiConfirmTwoStepAuthUniversalRequest) =>
  await (
    await authService().confirmTwoStepAuthUniversal(values)
  ).data;

export const createTwoStepAuth = async () => await (await authService().createTwoStepAuth()).data;

export const disableTwoStepAuthUniversal = async (values: AuthApiDisableTwoStepAuthUniversalRequest) =>
  await (
    await authService().disableTwoStepAuthUniversal(values)
  ).data;

export const createTwoStepAuthRecoveryCodesUniversal = async (
  values: AuthApiCreateTwoStepAuthRecoveryCodesUniversalRequest,
) => await (await authService().createTwoStepAuthRecoveryCodesUniversal(values)).data;

export const signInGoogle = async (values: AuthApiAuthorizeUsingGoogleCodeRequest) =>
  await (
    await authService().authorizeUsingGoogleCode(values)
  ).data;

export const signInFacebook = async (values: AuthApiAuthorizeUsingFacebookRequest) =>
  await (
    await authService().authorizeUsingFacebook(values)
  ).data;
