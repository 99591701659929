import type { FC } from "react";
import { memo } from "react";
import { useController, useFormContext } from "react-hook-form";

import { useField } from "../../field.hook";
import { TextNumberInput, type TextNumberInputExternalProps } from "../../inputs";
import type { ControllerRules } from "../../types";

type Props = TextNumberInputExternalProps & {
  name: string;
  rules?: ControllerRules;
};

export const _TextNumberField: FC<Props> = props => {
  const { name, rules, ...restProps } = props;

  const { control } = useFormContext();
  const { field } = useController({ name, control, rules });

  const { error, showError } = useField(name);

  return <TextNumberInput {...restProps} error={error} showError={showError} {...field} />;
};

/**
 * @deprecated
 */
export const TextNumberField = memo(_TextNumberField);
