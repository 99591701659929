import Big from "big.js";
import { type FC, memo } from "react";
import { Trans, useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { HookForm, SubmitButton, useControlledField, useHookForm } from "@/app/form";
import { getInputNumberValue } from "@/features/terminal/helpers/formatting";
import type { TradingAccountAttributes } from "@/services/openapi";
import { DataList, Dialog, NumberInput } from "@/shared/ui";

enum Fields {
  AMOUNT = "amount",
}

type FormValues = {
  [Fields.AMOUNT]: string;
};

type Props = {
  onSubmit: (values: FormValues) => void;
  accountCurrency: string;
  currencyDecimalScale: number;
  balance: number;
  attributes: TradingAccountAttributes;
};

const AddFundsForm: FC<Props> = ({ onSubmit, accountCurrency, currencyDecimalScale, balance, attributes }) => {
  const { t } = useTranslation();

  const { demoDepositMax, demoDepositMin } = attributes;

  const form = useHookForm<FormValues>({
    mode: "all",
    defaultValues: {
      [Fields.AMOUNT]: "",
    },
  });

  const { control } = form;

  const max = new Big(demoDepositMax || 0).minus(balance).toNumber();

  const [field, { invalid, pending }] = useControlledField<FormValues>({
    name: Fields.AMOUNT,
    control,
    rules: { min: demoDepositMin || 0, max, required: true },
  });

  const amount = getInputNumberValue(field.value);

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <Dialog.Header title={t("accounts.add-funds.title")} />
      <div className="flex flex-col gap-4">
        <NumberInput
          label={t("accounts.common.amount")}
          pending={pending}
          invalid={invalid}
          decimalScale={currencyDecimalScale}
          descriptor={
            <Trans
              i18nKey="accounts.add-funds.range-descriptor"
              values={{ currency: accountCurrency }}
              components={{
                minValue: <NumberFormat value={demoDepositMin} decimalScale={currencyDecimalScale} />,
                maxValue: <NumberFormat value={max} decimalScale={currencyDecimalScale} />,
              }}
            />
          }
          currency={accountCurrency}
          {...field}
        />
        <DataList>
          <DataList.Item
            label={<DataList.Label>{t("accounts.add-funds.demo-balance")}</DataList.Label>}
            value={
              <DataList.Value>
                <NumberFormat value={balance} currency={accountCurrency} decimalScale={currencyDecimalScale} />
              </DataList.Value>
            }
          />
          <DataList.Item
            label={<DataList.Label>{t("accounts.add-funds.total-balance")}</DataList.Label>}
            value={
              <DataList.Value>
                {invalid || amount === null ? (
                  "—"
                ) : (
                  <NumberFormat
                    value={new Big(balance).plus(amount).toNumber()}
                    currency={accountCurrency}
                    decimalScale={currencyDecimalScale}
                  />
                )}
              </DataList.Value>
            }
          />
        </DataList>
      </div>
      <Dialog.Buttons>
        <SubmitButton>{t("accounts.add-funds.action")}</SubmitButton>
      </Dialog.Buttons>
    </HookForm>
  );
};

const Component = memo(AddFundsForm);

export { Component as AddFundsForm };
