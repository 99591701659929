import type { FC } from "react";

import { Header } from "@/widgets/header";

import { AccountDetailsSkeleton } from "../account-details/skeleton";
import { AccountSummarySkeleton } from "../account-summary/skeleton";
import { ChartLoader } from "../chart/loader";
import { ChartSymbolsSkeleton } from "../chart-symbols/skeleton";
import { PlaceOrderSkeleton } from "../place-order/skeleton";
import { TerminalDesktopFrame } from "../terminal-desktop.frame";
import { DesktopTradingTablesSkeleton } from "../trading-tables/desktop/skeleton";

const TerminalDesktopSkeleton: FC = () => {
  return (
    <TerminalDesktopFrame
      headerSlot={
        <Header>
          <AccountDetailsSkeleton />
        </Header>
      }
      chartSymbolsSlot={<ChartSymbolsSkeleton />}
      chartSlot={<ChartLoader />}
      placeOrderSlot={<PlaceOrderSkeleton hasTabs />}
      tablesSlot={<DesktopTradingTablesSkeleton />}
      accountSummarySlot={<AccountSummarySkeleton />}
    />
  );
};

export { TerminalDesktopSkeleton };
