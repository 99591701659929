import { useQueryClient } from "react-query";

import {
  archiveAccount,
  changeAccountLeverage,
  changeAccountMainPassword,
  changeAccountTradeMode,
  createTradingAccount,
  getLastAccount,
  makeDemoDeposit,
  restoreAccount,
  updateLastAccount,
} from "@/services/accounts";
import type { ApiRequestParamsType, ApiResponseType } from "@/services/types";

import { useBaseMutation } from "..";
import { profileQueryKeys } from "../profile";
import type { MutationOptionsType } from "../types";
import { accountsQueryKeys } from "./accounts.keys";

export const useRestoreAccountMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof restoreAccount>, ApiRequestParamsType<typeof restoreAccount>>,
) => {
  const client = useQueryClient();
  return useBaseMutation<ApiResponseType<typeof restoreAccount>, ApiRequestParamsType<typeof restoreAccount>>(
    restoreAccount,
    {
      onSuccess: () => {
        client.invalidateQueries(accountsQueryKeys.base);
      },
      ...options,
    },
  );
};

export const useDemoDepositMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof makeDemoDeposit>, ApiRequestParamsType<typeof makeDemoDeposit>>,
) => {
  const client = useQueryClient();
  return useBaseMutation<ApiResponseType<typeof makeDemoDeposit>, ApiRequestParamsType<typeof makeDemoDeposit>>(
    makeDemoDeposit,
    {
      onSuccess: () => {
        client.invalidateQueries(accountsQueryKeys.base);
      },
      ...options,
    },
  );
};

export const useCreateTradingAccountMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof createTradingAccount>,
    ApiRequestParamsType<typeof createTradingAccount>
  >,
) => {
  const client = useQueryClient();
  return useBaseMutation<
    ApiResponseType<typeof createTradingAccount>,
    ApiRequestParamsType<typeof createTradingAccount>
  >(createTradingAccount, {
    onSuccess: () => {
      client.invalidateQueries(accountsQueryKeys.base);
      client.invalidateQueries(profileQueryKeys.header());
    },
    ...options,
  });
};

export const useChangeAccountLeverageMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof changeAccountLeverage>,
    ApiRequestParamsType<typeof changeAccountLeverage>
  >,
) => {
  const client = useQueryClient();
  return useBaseMutation<
    ApiResponseType<typeof changeAccountLeverage>,
    ApiRequestParamsType<typeof changeAccountLeverage>
  >(changeAccountLeverage, {
    onSuccess: () => {
      client.invalidateQueries(accountsQueryKeys.base);
    },
    ...options,
  });
};

export const useArchiveAccountMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof archiveAccount>, ApiRequestParamsType<typeof archiveAccount>>,
) => {
  const client = useQueryClient();
  return useBaseMutation<ApiResponseType<typeof archiveAccount>, ApiRequestParamsType<typeof archiveAccount>>(
    archiveAccount,
    {
      onSuccess: () => {
        client.invalidateQueries(accountsQueryKeys.base);
      },
      ...options,
    },
  );
};

export const useChangeAccountMainPasswordMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof changeAccountMainPassword>,
    ApiRequestParamsType<typeof changeAccountMainPassword>
  >,
) => {
  const client = useQueryClient();
  return useBaseMutation<
    ApiResponseType<typeof changeAccountMainPassword>,
    ApiRequestParamsType<typeof changeAccountMainPassword>
  >(changeAccountMainPassword, {
    onSuccess: () => {
      client.invalidateQueries(accountsQueryKeys.base);
    },
    ...options,
  });
};

export const useChangeAccountTradeModeMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof changeAccountTradeMode>,
    ApiRequestParamsType<typeof changeAccountTradeMode>
  >,
) => {
  return useBaseMutation<
    ApiResponseType<typeof changeAccountTradeMode>,
    ApiRequestParamsType<typeof changeAccountTradeMode>
  >(changeAccountTradeMode, options);
};

export const useUpdateLastAccountMutation = (
  options?: MutationOptionsType<
    ApiResponseType<typeof updateLastAccount>,
    ApiRequestParamsType<typeof updateLastAccount>
  >,
) => {
  const client = useQueryClient();
  return useBaseMutation<ApiResponseType<typeof updateLastAccount>, ApiRequestParamsType<typeof updateLastAccount>>(
    updateLastAccount,
    {
      onSuccess: () => {
        client.invalidateQueries(accountsQueryKeys.lastBase());
      },
      ...options,
    },
  );
};

export const useLastAccountMutation = (
  options?: MutationOptionsType<ApiResponseType<typeof getLastAccount>, ApiRequestParamsType<typeof getLastAccount>>,
) => {
  return useBaseMutation<ApiResponseType<typeof getLastAccount>, ApiRequestParamsType<typeof getLastAccount>>(
    getLastAccount,
    {
      ...options,
    },
  );
};
