import { type FC, memo } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat, PnlFormat } from "@/app/components";
import { getNumberTextColor } from "@/app/ui/colors";
import { useAddChartSymbol } from "@/features/terminal/hooks/add-chart-symbol.hook";
import { terminalLocators } from "@/features/terminal/locators";
import type { OpenOrder } from "@/features/terminal/orders/extended-orders.context";
import { terminalFormatDate } from "@/features/terminal/place-order/place-order.helpers";
import { useCurrentSymbolContext } from "@/features/terminal/symbol-info/current-symbol-context";
import { Button } from "@/shared/ui";

import { PartialClosePopoverContent } from "../../modify-order/partial-close/popover-content";
import { StopLossPopoverContent } from "../../modify-order/stop-loss/popover-content";
import { TakeProfitPopoverContent } from "../../modify-order/take-profit/popover-content";
import { CloseOpenOrderButton } from "../../open-orders/close/close-button";
import { ModifyOrderButton } from "../../ui/modify-order/button";
import { OrderTypeFormat } from "../../ui/order-type-format";
import { TradingTable } from "../table";

type Props = {
  order: OpenOrder;
  accountId: string;
  currency: string;
  currencyDecimalScale: number;
  setSymbol: ReturnType<typeof useCurrentSymbolContext>["setSymbol"];
};

const OpenOrdersBodyRow: FC<Props> = ({ order, accountId, currency, setSymbol, currencyDecimalScale }) => {
  const { t } = useTranslation();

  const {
    symbol,
    type,
    volume,
    price,
    stopLoss,
    takeProfit,
    id,
    date,
    swap,
    contractSize,
    volumeStep,
    volumeMin,
    volumeDecimalScale,
    baseCurrency,
    quoteCurrency,
    ask,
    bid,
    currentPrice,
    pnl,
    priceDecimalScale,
    margin,
    calculatedProfitRate,
  } = order;

  const { addChartSymbol } = useAddChartSymbol({ setSymbol, accountId });

  return (
    <TradingTable.Row>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.open.symbol}>
        <TradingTable.Symbol symbol={symbol} onClick={() => addChartSymbol(symbol)} />
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.open.type}>
        <OrderTypeFormat type={type} />
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.open.volume}>
        <ModifyOrderButton value={<NumberFormat value={volume} decimalScale={volumeDecimalScale} />}>
          {onClose => (
            <PartialClosePopoverContent
              onClose={onClose}
              volume={volume}
              volumeStep={volumeStep}
              volumeMin={volumeMin}
              volumeDecimalScale={volumeDecimalScale}
              currentPrice={currentPrice!}
              orderType={type}
              orderId={id}
              contractSize={contractSize}
              baseCurrency={baseCurrency}
              quoteCurrency={quoteCurrency}
              accountId={accountId}
              currency={currency}
              openPrice={price}
              currencyDecimalScale={currencyDecimalScale}
              profitRate={calculatedProfitRate}
            />
          )}
        </ModifyOrderButton>
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.open.margin}>
        <NumberFormat value={margin} decimalScale={currencyDecimalScale} />
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.open.openPrice}>
        <NumberFormat value={price} decimalScale={priceDecimalScale} />
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.open.currentPrice}>
        {currentPrice ? <NumberFormat value={currentPrice} decimalScale={priceDecimalScale} /> : "—"}
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.open.takeProfit}>
        <ModifyOrderButton
          value={takeProfit ? <NumberFormat value={takeProfit} decimalScale={priceDecimalScale} /> : null}
        >
          {onClose => (
            <TakeProfitPopoverContent
              onClose={onClose}
              volume={volume}
              orderType={type}
              orderId={id}
              priceDecimalScale={priceDecimalScale}
              stopLoss={stopLoss}
              takeProfit={takeProfit}
              ask={ask}
              bid={bid}
              accountId={accountId}
              baseCurrency={baseCurrency}
              quoteCurrency={quoteCurrency}
              contractSize={contractSize}
              currency={currency}
              openPrice={price}
              currencyDecimalScale={currencyDecimalScale}
              profitRate={calculatedProfitRate}
            />
          )}
        </ModifyOrderButton>
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.open.stopLoss}>
        <ModifyOrderButton value={stopLoss ? <NumberFormat value={stopLoss} decimalScale={priceDecimalScale} /> : null}>
          {onClose => (
            <StopLossPopoverContent
              onClose={onClose}
              volume={volume}
              orderType={type}
              orderId={id}
              priceDecimalScale={priceDecimalScale}
              stopLoss={stopLoss}
              takeProfit={takeProfit}
              ask={ask}
              bid={bid}
              accountId={accountId}
              baseCurrency={baseCurrency}
              quoteCurrency={quoteCurrency}
              contractSize={contractSize}
              currency={currency}
              openPrice={price}
              currencyDecimalScale={currencyDecimalScale}
              profitRate={calculatedProfitRate}
            />
          )}
        </ModifyOrderButton>
      </TradingTable.Cell>
      <TradingTable.Cell data-test={terminalLocators.tradingTables.open.timeOpened}>
        {terminalFormatDate(date)}
      </TradingTable.Cell>
      <TradingTable.Cell align="end" data-test={terminalLocators.tradingTables.open.swap}>
        <NumberFormat value={swap} decimalScale={currencyDecimalScale} />
      </TradingTable.Cell>
      <TradingTable.Cell
        align="end"
        color={getNumberTextColor(pnl)}
        data-test={terminalLocators.tradingTables.open.pnl}
      >
        <PnlFormat value={pnl} decimalScale={currencyDecimalScale} />
      </TradingTable.Cell>
      <TradingTable.Cell align="end" data-test={terminalLocators.tradingTables.open.close}>
        <CloseOpenOrderButton orderId={id} accountId={accountId}>
          {({ closeOrder, isLoading }) => (
            <Button onClick={closeOrder} pending={isLoading} variant="flat" size="sm">
              {t("button.close")}
            </Button>
          )}
        </CloseOpenOrderButton>
      </TradingTable.Cell>
    </TradingTable.Row>
  );
};

const Component = memo(OpenOrdersBodyRow);

export { Component as OpenOrdersBodyRow };
