import { type FC, useState } from "react";

import { HookForm, SubmitButton, useControlledField, useHookForm } from "@/app/form";
import { useTranslation } from "@/hooks/translator.hook";
import { type PasswordInfo, TradingServerPlatform } from "@/services/openapi";
import { Dialog, PasswordInput } from "@/shared/ui";

enum Fields {
  PASSWORD = "password",
  CONFIRM_PASSWORD = "confirmPassword",
}

type FormValues = {
  [Fields.PASSWORD]: string;
  [Fields.CONFIRM_PASSWORD]: string;
};

type Props = {
  onSubmit: (values: FormValues) => void;
  passwordRules: PasswordInfo;
  platform: TradingServerPlatform;
};

// TODO: !!! Almost the same as in src/features/profile/change-password/form.tsx
const ChangeAccountPasswordForm: FC<Props> = ({ onSubmit, passwordRules, platform }) => {
  const { t } = useTranslation();

  const [focused, setFocused] = useState(false);

  const form = useHookForm<FormValues>({
    defaultValues: {
      [Fields.PASSWORD]: "",
      [Fields.CONFIRM_PASSWORD]: "",
    },
  });
  const { watch, control } = form;

  const password = watch(Fields.PASSWORD);

  const { strong, maxLength } = passwordRules;

  const [
    confirmField,
    {
      invalid: confirmInvalid,
      pending: confirmPassword,
      errorMessage: errorMessageConfirm,
      isTouched: confirmIsTouched,
    },
  ] = useControlledField<FormValues>({
    name: Fields.CONFIRM_PASSWORD,
    control,
    rules: {
      required: t("accounts.change-password.confirm-required-error")!,
      validate: {
        matchesPreviousPassword: (value: string) =>
          password === value || t("profile-settings.change-password.not-match-error")!,
      },
    },
  });

  const [passwordField, { invalid: passwordInvalid, pending: pendingPassword, errorMessage: errorMessagePassword }] =
    useControlledField<FormValues>({
      name: Fields.PASSWORD,
      control,
      rules: {
        required: t("accounts.change-password.required-error")!,
        pattern: {
          value: new RegExp(strong!.mask!),
          message: t("profile-settings.change-password.regexp-error")!,
        },
        deps: confirmIsTouched ? [Fields.CONFIRM_PASSWORD] : void 0,
      },
    });

  const version = platform === TradingServerPlatform.MetaTrader5 ? "5" : "4";

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <Dialog.Header title={t("accounts.change-password.title", { version })} />
      <div className="flex flex-col gap-4">
        <PasswordInput
          autoComplete="new-password"
          label={t("accounts.change-password.password", { version })}
          pending={pendingPassword}
          invalid={passwordInvalid}
          descriptor={
            focused && !passwordInvalid ? t("profile-settings.change-password.regexp-error")! : errorMessagePassword
          }
          maxLength={maxLength}
          {...passwordField}
          onFocus={() => setFocused(true)}
          onBlur={() => {
            passwordField.onBlur();
            setFocused(false);
          }}
        />
        <PasswordInput
          autoComplete="new-password"
          label={t("accounts.change-password.confirm-password", { version })}
          pending={confirmPassword}
          invalid={confirmInvalid}
          descriptor={errorMessageConfirm}
          maxLength={maxLength}
          {...confirmField}
        />
      </div>
      <Dialog.Buttons>
        <SubmitButton>{t("accounts.change-password.action")}</SubmitButton>
      </Dialog.Buttons>
    </HookForm>
  );
};

export { ChangeAccountPasswordForm };
