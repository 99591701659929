import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, memo, useId } from "react";

import { cn } from "@/shared/styles";

const createUrlRef = (name: string): string => `url(#${name})`;

const Spinner = forwardRef<ElementRef<"svg">, ComponentPropsWithoutRef<"svg">>(({ className, ...props }, ref) => {
  const id = useId();
  const gradient1 = `gradient-1-${id}`;
  const gradient2 = `gradient-2-${id}`;

  return (
    <svg
      fill="none"
      height="200"
      width="200"
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("animate-spin", className)}
      {...props}
      ref={ref}
    >
      <defs>
        <linearGradient id={gradient1}>
          <stop offset="0%" stopOpacity={0} stopColor="currentColor" />
          <stop offset="100%" stopOpacity={0.5} stopColor="currentColor" />
        </linearGradient>
        <linearGradient id={gradient2}>
          <stop offset="0%" stopColor="currentColor" />
          <stop offset="100%" stopOpacity={0.5} stopColor="currentColor" />
        </linearGradient>
      </defs>
      <g strokeWidth={17.5}>
        <path stroke={createUrlRef(gradient1)} d="M8.75 100a91.25 91.25 0 01182.5 0" />
        <path stroke={createUrlRef(gradient2)} d="M191.25 100a91.25 91.25 0 01-182.5 0" />
        <path stroke="currentColor" strokeLinecap="round" d="M8.75 100a91.25 96 0 010-2" />
      </g>
    </svg>
  );
});

const Component = memo(Spinner);

Component.displayName = "Spinner";

export { Component as Spinner };
