import { type FC, useMemo } from "react";
import { ScrollRestoration, useSearchParams } from "react-router-dom";

import { CreateAccountContainer } from "@/features/create-account/container";
import { useTranslation } from "@/hooks/translator.hook";
import { CabinetHeader } from "@/layouts/cabinet/header";
import { CabinetPage } from "@/layouts/cabinet/page";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { TradingAccountType } from "@/services/openapi";
import { useServersQuery } from "@/state/server/accounts";

const CreateAccountPage: FC = () => {
  const { t } = useTranslation();
  const { data: servers } = useServersQuery();

  const [searchParams] = useSearchParams();

  const accountType = useMemo(() => {
    const urlType = searchParams.get("type");

    if (urlType === TradingAccountType.Demo || urlType === TradingAccountType.Real) {
      return urlType;
    }

    return TradingAccountType.Real;
  }, [searchParams]);

  if (!servers) {
    return <CabinetPage.Loader />;
  }

  return (
    <>
      <ScrollRestoration />
      <CabinetPage
        size="sm"
        header={
          <CabinetHeader
            backButton={<CabinetHeader.BackLink to={searchParams.get("from") || cabinetRoutes.dashboard} />}
          >
            <CabinetHeader.Title>{t("cabinet.new-account")}</CabinetHeader.Title>
          </CabinetHeader>
        }
      >
        <CreateAccountContainer servers={servers} type={accountType} />
      </CabinetPage>
    </>
  );
};

export { CreateAccountPage };
