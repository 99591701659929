import type { FC } from "react";

import { NoContentMessage } from "@/components/no-content-message";
import { useTranslation } from "@/hooks/translator.hook";
import { CabinetHeader } from "@/layouts/cabinet/header";
import { CabinetPage } from "@/layouts/cabinet/page";
import { AvailabilityStatus, TradingAccountSorting, TradingAccountType } from "@/services/openapi";
import { useAllAccountsQuery } from "@/state/server/accounts";

import { TransferContainer } from "./transfer.container";

const TransferPage: FC = () => {
  const { t } = useTranslation();

  const { data: accounts } = useAllAccountsQuery({
    type: TradingAccountType.Real,
    sorting: TradingAccountSorting.Newest,
    status: AvailabilityStatus.Active,
  });

  return (
    <CabinetPage
      size="sm"
      header={
        <CabinetHeader>
          <CabinetHeader.Title>{t("cabinet.navigation.transfer")}</CabinetHeader.Title>
        </CabinetHeader>
      }
    >
      {accounts && (
        <>
          {accounts.length < 2 ? (
            <NoContentMessage title={t("transfer.no-items.title")} description={t("transfer.no-items.description")} />
          ) : (
            <TransferContainer accounts={accounts} />
          )}
        </>
      )}
    </CabinetPage>
  );
};

export { TransferPage };
