import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { type ElementRef, forwardRef, type ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { cn } from "@/shared/styles";
import { tooltipContentStyles } from "@/shared/ui";

type Props = {
  children: ReactElement;
  open: boolean;
};

const CopyTooltip = forwardRef<ElementRef<"div">, Props>(({ children, open }, ref) => {
  const { t } = useTranslation();

  return (
    <TooltipPrimitive.Root open={open}>
      <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
      <TooltipPrimitive.Portal>
        <TooltipPrimitive.Content
          side="top"
          align="center"
          sideOffset={5}
          collisionPadding={10}
          className={cn(
            tooltipContentStyles(),
            "typography-S-Regular rounded-sm bg-accent-bg px-4 py-2 text-accent-over shadow-modal",
          )}
          ref={ref}
        >
          {t("common.copied")}
          <TooltipPrimitive.Arrow width={16} height={8} className="fill-accent-bg" />
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Portal>
    </TooltipPrimitive.Root>
  );
});

export { CopyTooltip };
