export const terminalQueryKeys = {
  base: ["terminal"],
  tradingCentralWidgets: () => [...terminalQueryKeys.base, "tradingCentral", "widgets"],
  tradingCentralCalendar: (filters: any) => [...terminalQueryKeys.base, "tradingCentral", "calendar", { filters }],
  symbols: (serverId: string) => [...terminalQueryKeys.base, "symbols", serverId],
  symbolsUpdates: (filters: any) => [...terminalQueryKeys.base, "symbolsUpdates", { filters }],
  symbolCharts: (filters: any) => [...terminalQueryKeys.base, "symbolChart", { filters }],
  positionsInfinite: (filters: any) => [...terminalQueryKeys.base, "positions", "infinite", { filters }],
  newsInfinite: (filters: any) => [...terminalQueryKeys.base, "news", "infinite", { filters }],
  symbolsFavorites: (accountId: string) => [...terminalQueryKeys.base, "symbolsFavorites", accountId],
  lastIndicator: (accountId: string) => [...terminalQueryKeys.base, "lastIndicator", accountId],
};
