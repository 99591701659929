import { useCallback, useState } from "react";

import type { Field, PaymentMethod } from "@/services/openapi";

type WithdrawalInfoProps = {
  currentStep: "paymentMethod" | "amount" | "additionalFields" | "check";
  currentAmount: string;
  currentPaymentMethod: PaymentMethod | null;
  additionalFieldsData: Field[] | null;
  nextFromPaymentMethod: () => void;
  backFromAdditionalFields: () => void;
  nextFromAdditionalFields: () => void;
  backFromAmount: () => void;
  nextFromAmount: () => void;
  backFromCheck: () => void;
  setCurrentAmount: SetState<string>;
  setCurrentPaymentMethod: SetState<PaymentMethod | null>;
  setAdditionalFieldsData: SetState<Field[] | null>;
};

const useWithdrawalInfo = (): WithdrawalInfoProps => {
  const [currentStep, setCurrentStep] = useState<WithdrawalInfoProps["currentStep"]>("paymentMethod");
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<WithdrawalInfoProps["currentPaymentMethod"]>(null);
  const [currentAmount, setCurrentAmount] = useState<WithdrawalInfoProps["currentAmount"]>("");
  const [additionalFieldsData, setAdditionalFieldsData] = useState<WithdrawalInfoProps["additionalFieldsData"]>(null);

  // Payment method step
  const nextFromPaymentMethod = useCallback(() => {
    setCurrentStep("amount");
    setAdditionalFieldsData(null);
  }, []);

  // Amount step
  const backFromAmount = useCallback(() => {
    setCurrentStep("paymentMethod");
    setCurrentAmount("");
  }, []);
  const nextFromAmount = useCallback(() => {
    if (currentPaymentMethod?.fields?.length) {
      setCurrentStep("additionalFields");
      return;
    }
    setCurrentStep("check");
  }, [currentPaymentMethod]);

  // Additional fields step
  const backFromAdditionalFields = useCallback(() => {
    setCurrentStep("amount");
    setAdditionalFieldsData(null);
  }, []);

  const nextFromAdditionalFields = useCallback(() => {
    setCurrentStep("check");
  }, []);

  // Check step
  const backFromCheck = useCallback(() => {
    setCurrentStep("amount");
  }, []);

  return {
    currentAmount,
    currentStep,
    currentPaymentMethod,
    additionalFieldsData,
    nextFromPaymentMethod,
    backFromAdditionalFields,
    nextFromAdditionalFields,
    backFromAmount,
    nextFromAmount,
    backFromCheck,
    setCurrentAmount,
    setCurrentPaymentMethod,
    setAdditionalFieldsData,
  };
};

export { useWithdrawalInfo, type WithdrawalInfoProps };
