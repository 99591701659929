import type { FC } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { HookForm, SubmitButton, useHookForm } from "@/app/form";
import { IconWarningCircle } from "@/domains/icons";
import { PaymentFooter } from "@/features/payment/ui/footer";
import type { WithdrawalInfoProps } from "@/features/payment/withdrawal/withdrawal-info.hook";
import type { PaymentMethod } from "@/services/openapi";
import { InfoBlock } from "@/shared/ui";

import { AdditionalField } from "./additional-field";

type Props = {
  next: () => void;
  currentPaymentMethod: PaymentMethod;
  setAdditionalFieldsData: WithdrawalInfoProps["setAdditionalFieldsData"];
};

const AdditionalFieldsStep: FC<Props> = ({ next, currentPaymentMethod, setAdditionalFieldsData }) => {
  const { t } = useTranslation();
  const additionalFields = currentPaymentMethod.fields!;

  const form = useHookForm();

  const onSubmit = useCallback(
    (values: any) => {
      const userData = additionalFields.map(({ id, title, valueDisplayRegex }) => ({
        id,
        title,
        value: values[id!],
        valueDisplayRegex,
      }));

      setAdditionalFieldsData(userData);
      next();
    },
    [additionalFields, next, setAdditionalFieldsData],
  );

  return (
    <div className="flex flex-col gap-4">
      {currentPaymentMethod.details?.widget && (
        <InfoBlock
          icon={<IconWarningCircle />}
          variant="warning"
          // FIXME:
          text={
            <>
              <div>{currentPaymentMethod.details?.widget?.text?.title}</div>
              <div>{currentPaymentMethod.details?.widget?.text?.subtitle}</div>
              <div>{currentPaymentMethod.details?.widget?.text?.paragraph}</div>
            </>
          }
        />
      )}
      <HookForm form={form} onSubmit={onSubmit}>
        <div className="flex flex-col gap-4">
          {additionalFields.map(field => (
            <AdditionalField key={field.id} {...field} />
          ))}
        </div>

        <PaymentFooter>
          <SubmitButton fullWidth>{t("button.next")}</SubmitButton>
        </PaymentFooter>
      </HookForm>
    </div>
  );
};

export { AdditionalFieldsStep };
