import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { IconMail } from "@/domains/icons";
import { formatRemainingTime } from "@/features/two-factor/verify-code/helpers";
import { Button, Dialog, Text } from "@/shared/ui";

type Props = {
  onSend: () => void;
  email: string;
  isLoading: boolean;
  remainingTime: number;
  countdownActive: boolean;
};

const VerifyEmailDialogContent: FC<Props> = ({ onSend, email, isLoading, remainingTime, countdownActive }) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog.Header
        title={t("profile-settings.verify-email.title")}
        centered
        image={
          <Dialog.Image type="icon">
            <Dialog.Icon type="positive">
              <IconMail />
            </Dialog.Icon>
          </Dialog.Image>
        }
      />
      <Text color="primary" variant="S / Regular" align="center">
        {t("profile-settings.verify-email.description", { email })}
      </Text>
      <Dialog.Buttons direction="column">
        <Dialog.Close asChild>
          <Button>{t("profile-settings.verify-email.close-button")}</Button>
        </Dialog.Close>
        <Button variant="secondary" pending={isLoading} onClick={onSend} disabled={countdownActive}>
          {t("profile-settings.verify-email.action")}
        </Button>
      </Dialog.Buttons>
      {remainingTime > 0 && (
        <Text color="secondary" align="center" variant="S / Regular" className="mt-4">
          {t("profile-settings.verify-email.send-again", { time: formatRemainingTime(remainingTime) })}
        </Text>
      )}
    </>
  );
};

export { VerifyEmailDialogContent };
