import { type FC } from "react";

import { NumberInput } from "@/shared/ui";

import { usePlaceOrderContext } from "../context";

type Props = {
  changeOpenPrice: ReturnType<typeof usePlaceOrderContext>["changeOpenPrice"];
  openPriceOnBlur: ReturnType<typeof usePlaceOrderContext>["openPriceOnBlur"];
  openPriceFormValue: ReturnType<typeof usePlaceOrderContext>["openPriceFormValue"];
  priceDecimalScale: number;
  isSubmitting: boolean;
};

const OpenPriceField: FC<Props> = ({
  changeOpenPrice,
  isSubmitting,
  openPriceFormValue,
  priceDecimalScale,
  openPriceOnBlur,
}) => {
  return (
    <NumberInput
      pending={isSubmitting}
      value={openPriceFormValue}
      onChange={value => changeOpenPrice(value, { format: false })}
      onBlur={openPriceOnBlur}
      decimalScale={priceDecimalScale}
    />
  );
};

export { OpenPriceField };
