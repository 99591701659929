import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { type TradingAccount, TradingServerPlatform } from "@/services/openapi";
import { LabelButton } from "@/shared/ui";

import { AccountCard } from "../card";
import { RestoreButton } from "./restore-button";

type Props = {
  account: TradingAccount;
};

const ArchivedAccountCard: FC<Props> = ({ account }) => {
  const { t } = useTranslation();

  const { id, actions, platform } = account;

  return (
    <AccountCard
      account={account}
      actionSlot={
        <LabelButton disabled>
          {platform! === TradingServerPlatform.MetaTrader5
            ? t("accounts.common.mt5-label")
            : t("accounts.common.mt4-label")}
        </LabelButton>
      }
    >
      {actions!.canRestore && (
        <AccountCard.Buttons>
          <RestoreButton accountId={id!} />
        </AccountCard.Buttons>
      )}
    </AccountCard>
  );
};

export { ArchivedAccountCard };
