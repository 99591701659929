import { type FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import { DetailsTable as Table } from "@/components/details-table";

const WithdrawalInfo: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <p className="mb-6 text-center font-roboto text-[14px] leading-normal text-text-secondary md:text-[16px]">
        {t("bonus.dialog.terms.withdrawal.description")}
      </p>
      <Table.Card>
        <Table>
          <Table.Row>
            <Table.Label>{t("bonus.dialog.terms.withdrawal.available-balance")}</Table.Label>
            <Table.Value>$1000</Table.Value>
          </Table.Row>
          <Table.Row>
            <Table.Label>{t("bonus.dialog.terms.withdrawal.bonus-balance")}</Table.Label>
            <Table.Value>$500</Table.Value>
          </Table.Row>
          <Table.Row>
            <Table.Label>{t("bonus.dialog.terms.withdrawal.withdrawal-amount")}</Table.Label>
            <Table.Value>
              <Trans
                i18nKey="bonus.dialog.terms.withdrawal.withdrawal-amount-value"
                values={{ symbol: "$", amount: 200, percentage: 20 }}
                components={{ colorText: <span className="text-action" /> }}
              />
            </Table.Value>
          </Table.Row>
          <Table.Row>
            <Table.Label>{t("bonus.dialog.terms.withdrawal.bonus-reduced")}</Table.Label>
            <Table.Value>
              <Trans
                i18nKey="bonus.dialog.terms.withdrawal.bonus-reduced-value"
                values={{ symbol: "$", amount: 100, percentage: 20 }}
                components={{ colorText: <span className="text-action" /> }}
              />
            </Table.Value>
          </Table.Row>
        </Table>
      </Table.Card>
      <Table.Card className="mt-6 md:mt-8">
        <p className="font-roboto text-[14px] leading-normal text-text md:text-[16px]">
          {t("bonus.dialog.terms.withdrawal.reduced-info.title")}
        </p>
        <ul className="mt-3 flex list-disc flex-col gap-2 ps-5">
          <li className="font-roboto text-[14px] leading-normal text-text-secondary md:text-[16px]">
            {t("bonus.dialog.terms.withdrawal.reduced-info.info-1")}
          </li>
          <li className="font-roboto text-[14px] leading-normal text-text-secondary md:text-[16px]">
            {t("bonus.dialog.terms.withdrawal.reduced-info.info-2")}
          </li>
        </ul>
      </Table.Card>
    </>
  );
};

export { WithdrawalInfo };
