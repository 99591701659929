import { type FC, type ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { AddFundsContainer } from "@/entities/accounts/add-funds/container";
import { useDisclosure } from "@/hooks/disclosure.hook";
import type { TradingAccountAttributes } from "@/services/openapi";
import { Dialog, toast, ToastIcons } from "@/shared/ui";

type Props = {
  accountId: string;
  currency: string;
  currencyDecimalScale: number;
  balance: number;
  attributes: TradingAccountAttributes;
  children: ReactElement;
};

const AddFundsButtonContainer: FC<Props> = ({
  accountId,
  currency,
  currencyDecimalScale,
  balance,
  attributes,
  children,
}) => {
  const { t } = useTranslation();

  const [opened, { onOpenChange, close }] = useDisclosure();

  return (
    <Dialog
      open={opened}
      onOpenChange={value => {
        if (value && balance >= (attributes.demoDepositMax || 0)) {
          toast({
            title: t("accounts.add-funds.error")!,
            icon: ToastIcons.ERROR,
          });
          return;
        }
        onOpenChange(value);
      }}
    >
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Content>
        <AddFundsContainer
          accountId={accountId}
          accountCurrency={currency}
          currencyDecimalScale={currencyDecimalScale}
          balance={balance}
          attributes={attributes}
          onClose={close}
        />
      </Dialog.Content>
    </Dialog>
  );
};

export { AddFundsButtonContainer };
