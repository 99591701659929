import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { IconWarning } from "@/domains/icons";
import { TerminalTableState } from "@/features/terminal/layout/context";
import { getTerminalRoute } from "@/routes/terminal.routes";
import { Button, Dialog, Text } from "@/shared/ui";

type Props = {
  accountId: string;
  onFormOpen: () => void;
};

const ChangeLeverateWarningDialog: FC<Props> = ({ accountId, onFormOpen }) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog.Header
        centered
        title={t("accounts.change-leverage.warning.title")}
        image={
          <Dialog.Image type="icon" size="md">
            <IconWarning className="text-warning-text" />
          </Dialog.Image>
        }
      />
      <Text align="center" color="primary" variant="S / Regular">
        {t("accounts.change-leverage.warning.description")}
      </Text>
      <Dialog.Buttons direction="column">
        <Dialog.Close asChild>
          <Button asChild>
            <Link to={getTerminalRoute(accountId, TerminalTableState.OPEN)}>
              {t("accounts.change-leverage.warning.button-trades")}
            </Link>
          </Button>
        </Dialog.Close>
        <Button variant="secondary" onClick={onFormOpen}>
          {t("accounts.change-leverage.warning.button-leverage")}
        </Button>
      </Dialog.Buttons>
    </>
  );
};

export { ChangeLeverateWarningDialog };
