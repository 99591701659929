import { useComposedRefs } from "@radix-ui/react-compose-refs";
import { type ElementRef, forwardRef, useRef } from "react";

import { IconCross } from "@/domains/icons";

import {
  _Button,
  _DescriptorButton,
  type _ExternalNumericInputProps,
  type _ExternalWrapperProps,
  _Input,
  _NumericInput,
  _Wrapper,
} from "../_base";

type Props = _ExternalNumericInputProps &
  Omit<_ExternalWrapperProps, "hasInputValue"> & {
    value: string;
    onChange: (value: string) => void;
    clearable?: boolean;
  };

const TextNumberInput = forwardRef<ElementRef<typeof _NumericInput>, Props>(
  (
    {
      value,
      onChange,
      className,
      label,
      startSection,
      disabled,
      invalid,
      pending,
      clearable,
      descriptor,
      endSection,
      floatingLabel = true,
      type = "text",
      allowLeadingZeros = true,
      decimalScale = 0,
      thousandSeparator = "",
      inputMode = "numeric",
      ...props
    },
    forwardedRef,
  ) => {
    const showClearButton = clearable && value !== "";

    const hasEndSection = !!(showClearButton || endSection);

    const ref = useRef<ElementRef<typeof _NumericInput>>(null);
    const composedRefs = useComposedRefs(ref, forwardedRef);

    return (
      <_Wrapper
        type="input"
        className={className}
        startSection={startSection}
        invalid={invalid}
        pending={pending}
        label={label}
        disabled={disabled}
        descriptor={descriptor}
        floatingLabel={floatingLabel}
        hasInputValue={value !== ""}
        endSection={
          hasEndSection && (
            <>
              {showClearButton && (
                <_Button disabled={disabled} onClick={() => onChange("")}>
                  <IconCross />
                </_Button>
              )}
              {endSection}
            </>
          )
        }
      >
        <_NumericInput
          value={value}
          onValueChange={({ value }) => onChange(value)}
          customInput={_Input}
          disabled={disabled}
          type={type}
          allowLeadingZeros={allowLeadingZeros}
          decimalScale={decimalScale}
          thousandSeparator={thousandSeparator}
          inputMode={inputMode}
          {...props}
          ref={composedRefs}
        />
      </_Wrapper>
    );
  },
);

const Component = Object.assign(TextNumberInput, {
  DescriptorButton: _DescriptorButton,
  IconButton: _Button,
});

export { Component as TextNumberInput };
