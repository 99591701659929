import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { IconDeposit2 } from "@/domains/icons";
import type { TradingAccount } from "@/services/openapi";
import { TileButton } from "@/shared/ui";

import { AddFundsButtonContainer } from "./add-funds.container";

type Props = {
  account: TradingAccount;
};

const AddFundsButton: FC<Props> = ({ account }) => {
  const { t } = useTranslation();

  const { id, digits, currency, balance, attributes } = account;

  return (
    <AddFundsButtonContainer
      accountId={id!}
      attributes={attributes!}
      balance={balance!}
      currency={currency!}
      currencyDecimalScale={digits!}
    >
      <TileButton variant="secondary" icon={<IconDeposit2 />}>
        {t("accounts.buttons.add-funds")}
      </TileButton>
    </AddFundsButtonContainer>
  );
};

export { AddFundsButton };
