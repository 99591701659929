import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { HookForm, SubmitButton, useControlledField, useHookForm } from "@/app/form";
import { IconShieldCross } from "@/domains/icons";
import { Dialog, Text, TextNumberInput } from "@/shared/ui";

enum Fields {
  CODE = "code",
}

type FormValues = {
  [Fields.CODE]: string;
};

type Props = {
  onSubmit: (values: FormValues) => Promise<unknown>;
};

const Disable2FADialogContainerForm: FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation();

  const form = useHookForm({
    defaultValues: {
      [Fields.CODE]: "",
    },
  });

  const { control } = form;

  const [field, { invalid, pending, errorMessage }] = useControlledField<FormValues>({
    name: Fields.CODE,
    control,
    rules: {
      required: t("two-factor.disable.dialog.required-error")!,
    },
  });

  return (
    <HookForm form={form} onSubmit={onSubmit}>
      <Dialog.Header
        centered
        title={t("two-factor.disable.dialog.title")}
        image={
          <Dialog.Image type="icon">
            <Dialog.Icon type="negative">
              <IconShieldCross />
            </Dialog.Icon>
          </Dialog.Image>
        }
      />
      <Text color="secondary" className="mb-6" align="center" variant="S Compact / Regular">
        {t("two-factor.disable.dialog.description")}
      </Text>
      <TextNumberInput
        label={t("two-factor.disable.dialog.label")}
        pending={pending}
        invalid={invalid}
        descriptor={errorMessage}
        {...field}
      />
      <Dialog.Buttons>
        <SubmitButton>{t("two-factor.disable.dialog.action")}</SubmitButton>
      </Dialog.Buttons>
    </HookForm>
  );
};

export { Disable2FADialogContainerForm };
