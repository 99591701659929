import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { IconArrowSort, IconChevronRight } from "@/domains/icons";
import { ChangeLeverageContainer } from "@/entities/accounts/change-leverage/container";
import { Leverage } from "@/entities/accounts/leverage";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { type TradingAccount } from "@/services/openapi";
import { ActionList, Dialog } from "@/shared/ui";

type Props = {
  account: TradingAccount;
  hasOpenPositions: boolean;
};

const ChangeLeverageActionButton: FC<Props> = ({ account, hasOpenPositions }) => {
  const { t } = useTranslation();

  const [opened, { onOpenChange, close }] = useDisclosure();

  return (
    <Dialog open={opened} onOpenChange={onOpenChange}>
      <Dialog.Trigger asChild>
        <ActionList.Item
          title={t("accounts.buttons.change-leverage")!}
          icon={<IconArrowSort />}
          actionIcon={<IconChevronRight />}
          endLabel={<Leverage leverage={account.leverage!} />}
        />
      </Dialog.Trigger>
      <Dialog.Content>
        <ChangeLeverageContainer account={account} hasOpenPositions={hasOpenPositions} onClose={close} />
      </Dialog.Content>
    </Dialog>
  );
};

export { ChangeLeverageActionButton };
