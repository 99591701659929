import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { cva } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, type ReactNode, useId } from "react";

import { cn } from "@/shared/styles";

const Group = forwardRef<
  ElementRef<typeof RadioGroupPrimitive.Root>,
  ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return <RadioGroupPrimitive.Root className={cn("grid gap-3", className)} name={props.name} {...props} ref={ref} />;
});
Group.displayName = "RadioGroup";

const radioItemStyles = cva([
  "size-6 shrink-0 rounded-full border border-contrast-tertiary bg-control-bg transition-[background] hover:bg-control-bg-hover",
  "focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-text",
  "data-[state=checked]:border-2 data-[state=checked]:border-accent-bg data-[state=checked]:hover:bg-control-bg data-[state=checked]:disabled:border-contrast-tertiary",
])();

const Item = forwardRef<
  ElementRef<typeof RadioGroupPrimitive.Item>,
  ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> & { label: ReactNode; description?: ReactNode }
>(({ label, description, ...props }, ref) => {
  const id = useId();

  return (
    <div className="group flex gap-3 has-[:disabled]:pointer-events-none">
      <RadioGroupPrimitive.Item ref={ref} id={id} className={radioItemStyles} {...props}>
        <RadioGroupPrimitive.Indicator className="grid place-items-center">
          <div className="size-3.5 rounded-full bg-accent-bg group-has-[:disabled]:bg-contrast-quaternary" />
        </RadioGroupPrimitive.Indicator>
      </RadioGroupPrimitive.Item>
      <div className="grid gap-1 py-0.5">
        <label
          htmlFor={id}
          className="typography-S-Regular text-contrast-primary transition-colors group-has-[:disabled]:text-contrast-tertiary"
        >
          {label}
        </label>
        {description && (
          <p className="typography-XS-Regular text-contrast-secondary transition-colors group-has-[:disabled]:text-contrast-tertiary">
            {description}
          </p>
        )}
      </div>
    </div>
  );
});
Item.displayName = "RadioGroupItem";

const Component = Object.assign(Group, { Item });

export { Component as RadioGroup };
