import { cva } from "class-variance-authority";
import { type FC, type ReactNode } from "react";

import { cn } from "@/shared/styles";

const cellStyles = cva("px-2 py-2.5 first:ps-0 last:pe-0")();

const Row: FC<{ children: ReactNode }> = ({ children }) => (
  <tr className="border-b border-contrast-quaternary last:border-none">{children}</tr>
);

const Label: FC<{ children?: ReactNode; accentText?: boolean }> = ({ children, accentText }) => (
  <td
    className={cn(
      "typography-S-Regular text-start text-contrast-secondary",
      accentText && "typography-S-Medium",
      cellStyles,
    )}
  >
    {children}
  </td>
);

const Value: FC<{ children: ReactNode; className?: string; accentText?: boolean }> = ({
  children,
  className,
  accentText,
}) => (
  <td
    className={cn(
      "typography-S-Regular text-end text-contrast-primary",
      accentText && "typography-S-Medium",
      cellStyles,
      className,
    )}
  >
    {children}
  </td>
);

const Table: FC<{ children: ReactNode }> = ({ children }) => (
  <table className="w-full">
    <tbody>{children}</tbody>
  </table>
);

const Card: FC<{
  children: ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return <div className={cn("rounded-xl bg-bg-back px-6 py-5", className)}>{children}</div>;
};

/**
 * @deprecated
 */
const Component = Object.assign(Table, {
  Row,
  Label,
  Value,
  Card,
});

export { Component as DetailsTable };
