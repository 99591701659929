import { type CSSProperties, type FC } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";

import { useTerminalAccountContext } from "../../contexts/account.context";
import type { MergedTerminalSymbol } from "../../helpers/symbols";
import { useLayoutContext } from "../../layout/context";
import { useCurrentSymbolContext } from "../../symbol-info/current-symbol-context";
import { WatchlistSymbolRow } from "../symbol-row";

const ListItem: FC<{
  style: CSSProperties;
  index: number;
  data: {
    symbols: MergedTerminalSymbol[];
    accountId: string;
    leverage: number;
    isMobile: boolean;
    currentSymbol: ReturnType<typeof useCurrentSymbolContext>["currentSymbol"];
    watchlistSelectedSymbol: ReturnType<typeof useLayoutContext>["watchlistSelectedSymbol"];
    setSymbol: ReturnType<typeof useCurrentSymbolContext>["setSymbol"];
    onClose: () => void;
  };
}> = ({ index, data, style }) => {
  const { symbols, accountId, onClose, setSymbol, currentSymbol, watchlistSelectedSymbol, isMobile, leverage } = data;

  return (
    <div
      style={style}
      className="*:first:rounded-t-lg *:last:rounded-b-lg lg:*:first:rounded-t-none lg:*:last:rounded-b-none"
    >
      <WatchlistSymbolRow
        leverage={leverage}
        symbolInfo={symbols[index]!}
        accountId={accountId}
        onClose={onClose}
        setSymbol={setSymbol}
        currentSymbol={currentSymbol}
        watchlistSelectedSymbol={watchlistSelectedSymbol}
        isMobile={isMobile}
      />
    </div>
  );
};

type Props = {
  symbols: MergedTerminalSymbol[];
  onClose: () => void;
};

const WatchlistSymbolsList: FC<Props> = ({ symbols, onClose }) => {
  const { account } = useTerminalAccountContext();
  const { watchlistSelectedSymbol, isMobile } = useLayoutContext();

  const { setSymbol, currentSymbol } = useCurrentSymbolContext();

  return (
    <div className="h-full lg:-mx-4 lg:-mt-2">
      {/* TODO: is's hard to integrate scroll area component */}
      <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList
            className="scrollbar-hide"
            height={height}
            width={width}
            itemData={{
              symbols,
              onClose,
              accountId: account.id!,
              setSymbol,
              currentSymbol,
              watchlistSelectedSymbol,
              isMobile,
              leverage: account.leverage!,
            }}
            itemCount={symbols.length}
            itemSize={76}
            itemKey={(index, data) => data.symbols[index]!.symbol!}
          >
            {ListItem}
          </FixedSizeList>
        )}
      </AutoSizer>
    </div>
  );
};
export { WatchlistSymbolsList };
