import type { ComponentProps, FC } from "react";
import { useCallback, useState } from "react";

import { CopyTooltip, QRCode } from "@/app/components";
import { useCopy } from "@/app/hooks";
import { IconCopy } from "@/domains/icons";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { CabinetHeader } from "@/layouts/cabinet/header";
import { CabinetPage } from "@/layouts/cabinet/page";
import type { TwoFactorRecoveryCode } from "@/services/openapi";
import { Dialog, Text, toast, ToastIcons, UnstyledButton } from "@/shared/ui";
import { useConfirmTwoFAMutation } from "@/state/server/auth";

import type { TwoFAConfirmInfo, TwoFAStep } from "../../container";
import { RecoveryCodesDialogContent } from "../recovery-codes/dialog/content";
import { Confirm2FAForm } from "./form";

type Props = {
  twoFactorData: TwoFAConfirmInfo;
  setStep: (step: TwoFAStep) => void;
  setTwoFactorData: (data: TwoFAConfirmInfo | null) => void;
};

const Confirm2FAContainer: FC<Props> = ({ setStep, setTwoFactorData, twoFactorData }) => {
  const { t } = useTranslation();

  const [dialogOpened, { open, close }] = useDisclosure();

  const [recoveryCodes, setRecoveryCodes] = useState<TwoFactorRecoveryCode[] | null>(null);

  const { mutateAsync: confirmTwoFa } = useConfirmTwoFAMutation();

  const { copy, isCopied } = useCopy();

  const handleSubmit: ComponentProps<typeof Confirm2FAForm>["onSubmit"] = useCallback(
    ({ code }) => {
      return confirmTwoFa(
        {
          twoFactorUniversalConfirm: {
            code,
            sharedKey: twoFactorData.sharedKey,
            oneTimeToken: twoFactorData.oneTimeToken,
          },
        },
        {
          onSuccess: res => {
            setRecoveryCodes(res.codes!);
            open();
          },
        },
      );
    },
    [twoFactorData, confirmTwoFa, open],
  );

  return (
    <>
      <CabinetPage
        size="sm"
        header={
          <CabinetHeader
            backButton={
              <CabinetHeader.BackButton
                onClick={() => {
                  setStep("enable");
                  setTwoFactorData(null);
                }}
              />
            }
          >
            <CabinetHeader.Title>{t("two-factor.enable.title")}</CabinetHeader.Title>
          </CabinetHeader>
        }
      >
        <div className="flex flex-col gap-6">
          <Text color="secondary" variant="M / Regular">
            {t("two-factor.enable.description")}
          </Text>
          <div className="flex flex-col items-center gap-4 self-start rounded-lg bg-card-bg px-6 pb-6 pt-8">
            <QRCode value={twoFactorData.authenticatorUri!} size={178} />
            <UnstyledButton
              onClick={() => copy(twoFactorData.sharedKey!)}
              className="typography-M-Compact-Medium flex items-center gap-1 rounded-md px-2 outline-none transition-colors hover:text-contrast-secondary focus-visible:outline-2 focus-visible:outline-offset-0 focus-visible:outline-accent-text"
            >
              {twoFactorData.sharedKey}
              <CopyTooltip open={isCopied}>
                <div className="shrink-0 *:size-4">
                  <IconCopy />
                </div>
              </CopyTooltip>
            </UnstyledButton>
          </div>
          <Confirm2FAForm onSubmit={handleSubmit} />
        </div>
      </CabinetPage>
      <Dialog
        open={dialogOpened}
        onOpenChange={() => {
          close();
          setStep("disable");
          toast({ icon: ToastIcons.SUCCESS, title: t("two-factor.confirm.success-message")! });
        }}
      >
        {recoveryCodes && (
          <Dialog.Content>
            <RecoveryCodesDialogContent recoveryCodes={recoveryCodes} />
          </Dialog.Content>
        )}
      </Dialog>
    </>
  );
};

export { Confirm2FAContainer };
