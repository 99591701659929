import { type ComponentProps, type FC, useCallback } from "react";

import type { ChangePasswordInfo, ProfileStep } from "@/features/profile/page";
import { VerifyCodeForm } from "@/features/two-factor/verify-code/form";
import { useVerifyCodeData } from "@/features/two-factor/verify-code/helpers";
import { UserOneTimeCodeType } from "@/services/openapi";
import { Dialog } from "@/shared/ui";
import { useRequestOneTimeCodeMutation, useVerifyOneTimeCodeMutation } from "@/state/server/ot-codes";

type Props = {
  email: string;
  setStep: (step: ProfileStep) => void;
  setChangePasswordData: (data: ChangePasswordInfo) => void;
};

const ChangePasswordVerifyCodeContainer: FC<Props> = ({ email, setStep, setChangePasswordData }) => {
  const { countdownActive, isCodeSent, remainingTime, setIsCodeSent, setNextRequestDate } = useVerifyCodeData();

  const { mutate: sendCode, isLoading: requestLoading } = useRequestOneTimeCodeMutation();
  const { mutate: verifyCode, isLoading: verifyLoading } = useVerifyOneTimeCodeMutation();

  const handleSend = useCallback(() => {
    sendCode(
      { type: UserOneTimeCodeType.PasswordChange },
      {
        onSuccess: ({ dateNextRequest }) => {
          setNextRequestDate(dateNextRequest!);
          setIsCodeSent(true);
        },
      },
    );
  }, [sendCode, setIsCodeSent, setNextRequestDate]);

  const handleSubmit: ComponentProps<typeof VerifyCodeForm>["onSubmit"] = useCallback(
    ({ code }) => {
      return verifyCode(
        { type: UserOneTimeCodeType.PasswordChange, stringContainer: { value: code } },
        {
          onSuccess: ({ token }) => {
            setChangePasswordData({ type: "verify", oneTimeToken: token! });
            setStep("changePassword");
          },
        },
      );
    },
    [verifyCode, setStep, setChangePasswordData],
  );

  return (
    <Dialog.Content>
      <VerifyCodeForm
        onSubmit={handleSubmit}
        onSend={handleSend}
        isLoading={requestLoading || verifyLoading}
        remainingTime={remainingTime}
        email={email}
        countdownActive={countdownActive}
        isCodeSent={isCodeSent}
      />
    </Dialog.Content>
  );
};

export { ChangePasswordVerifyCodeContainer };
