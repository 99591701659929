import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";

import { cn } from "@/shared/styles";
import { Popover, popoverContentStyles } from "@/shared/ui";

/**
 * @deprecated
 */
const PopoverContent = forwardRef<
  ElementRef<typeof Popover.PrimitiveContent>,
  ComponentPropsWithoutRef<typeof Popover.PrimitiveContent>
>(({ className, align = "center", sideOffset = 4, ...props }, ref) => (
  <Popover.Portal>
    <Popover.PrimitiveContent
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cn(popoverContentStyles(), className)}
      {...props}
    />
  </Popover.Portal>
));
PopoverContent.displayName = Popover.PrimitiveContent.displayName;

export { PopoverContent };
