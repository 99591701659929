import { type FC, type ReactElement } from "react";

import { Dialog } from "@/shared/ui";

import { CreateRecoveryCodesContainer } from "./container";

type Props = {
  email: string;
  children: ReactElement;
};

const CreateRecoveryCodesDialog: FC<Props> = ({ children, email }) => {
  return (
    <Dialog>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <CreateRecoveryCodesContainer email={email} />
    </Dialog>
  );
};

export { CreateRecoveryCodesDialog };
