import dayjs from "dayjs";
import type { FC } from "react";

import { IconChevronRight } from "@/domains/icons";
import type { TerminalTradingCentralArticle } from "@/services/openapi";
import { Dialog, ExternalLink, Text } from "@/shared/ui";

type Props = {
  article: TerminalTradingCentralArticle;
};

const NewsDialog: FC<Props> = ({ article }) => {
  const { publisher, content, title, date, url } = article;

  return (
    <>
      <Dialog.Header title={title} />
      <div className="mb-4 flex justify-between">
        <div className="flex flex-col gap-0.5">
          <Text color="secondary" variant="S / Regular">
            Published by
          </Text>
          <Text color="positive" variant="S / Regular">
            {publisher}
          </Text>
        </div>
        <Text color="secondary" variant="S / Regular">
          {dayjs(date).format("YYYY MMMM DD, HH:mm")}
        </Text>
      </div>
      <Text color="primary" variant="S / Regular" className="whitespace-pre-wrap">
        {content}
      </Text>
      <div className="mt-6 flex flex-col gap-2">
        <ExternalLink href={url!} className="typography-M-Medium flex items-center gap-1 text-positive-text">
          Story continues
          <IconChevronRight className="size-4" />
        </ExternalLink>
        <Text color="secondary" variant="S / Regular">
          According to relevant copyright regulations, some news cannot be fully displayed
        </Text>
      </div>
    </>
  );
};

export { NewsDialog };
