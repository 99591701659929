import type { FC } from "react";

import { PaymentLoader } from "@/features/payment/ui/loader";
import type { PaymentMethod, PaymentMethods, TradingAccount } from "@/services/openapi";
import { usePaymentInfo } from "@/state/server/payment";

import { PaymentMethodStep } from "./step";

type Props = {
  account: TradingAccount;
  currentPaymentMethod: PaymentMethod | null;
  paymentMethodsData: PaymentMethods;
  setPaymentMethod: (method: PaymentMethod) => void;
  next: () => void;
};

const PaymentMethodStepContainer: FC<Props> = ({
  account,
  currentPaymentMethod,
  setPaymentMethod,
  next,
  paymentMethodsData,
}) => {
  const { data: paymentInfo } = usePaymentInfo();

  return paymentInfo ? (
    <PaymentMethodStep
      currentPaymentMethod={currentPaymentMethod}
      next={next}
      paymentInfo={paymentInfo}
      paymentMethods={paymentMethodsData.items!.filter(({ isAvailable }) => isAvailable)}
      setPaymentMethod={setPaymentMethod}
      currentAccount={account}
    />
  ) : (
    <PaymentLoader />
  );
};

export { PaymentMethodStepContainer };
