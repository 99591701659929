import type { ComponentProps, FC } from "react";
import { useCallback } from "react";

import { getInputNumberValue } from "@/features/terminal/helpers/formatting";
import type { TradingAccountAttributes } from "@/services/openapi";
import { useDemoDepositMutation } from "@/state/server/accounts";

import { AddFundsForm } from "./form";

type Props = {
  accountId: string;
  accountCurrency: string;
  currencyDecimalScale: number;
  balance: number;
  attributes: TradingAccountAttributes;
  onClose: () => void;
};

const AddFundsContainer: FC<Props> = ({
  accountId,
  accountCurrency,
  onClose,
  currencyDecimalScale,
  balance,
  attributes,
}) => {
  const { mutateAsync: demoDeposit } = useDemoDepositMutation({
    successMessage: "demo-balance-added",
  });

  const submitDeposit: ComponentProps<typeof AddFundsForm>["onSubmit"] = useCallback(
    ({ amount }) => {
      return demoDeposit(
        {
          id: accountId,
          decimalContainer: {
            value: getInputNumberValue(amount)!,
          },
        },
        {
          onSuccess: () => {
            onClose();
          },
        },
      );
    },
    [demoDeposit, accountId, onClose],
  );

  return (
    <AddFundsForm
      onSubmit={submitDeposit}
      accountCurrency={accountCurrency}
      currencyDecimalScale={currencyDecimalScale}
      balance={balance}
      attributes={attributes}
    />
  );
};

export { AddFundsContainer };
