import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, type ReactNode } from "react";

import { cn } from "@/shared/styles";

import { UnstyledButton } from "..";

const labelButtonStyles = cva(
  "typography-S-Regular inline-flex h-7 items-center justify-center gap-0.5 rounded-sm px-2 outline-none transition-colors focus-visible:outline-2 focus-visible:outline-offset-1 disabled:pointer-events-none disabled:bg-contrast-quinary disabled:text-contrast-tertiary",
  {
    variants: {
      variant: {
        neutral:
          "bg-secondary-button-bg text-contrast-secondary hover:bg-secondary-button-bg-hover focus-visible:outline-accent-text active:bg-secondary-button-bg-active",
        positive:
          "bg-positive-bg/10 text-positive-text hover:bg-positive-bg/20 focus-visible:outline-positive-bg-active active:bg-positive-bg/25",
        warning:
          "bg-warning-bg/10 text-warning-text hover:bg-warning-bg/20 focus-visible:outline-warning-bg-active active:bg-warning-bg/25",
        negative:
          "bg-negative-bg/10 text-negative-text hover:bg-negative-bg/20 focus-visible:outline-negative-bg-active active:bg-negative-bg/25",
      },
      type: {
        button: "",
        tooltip: "",
      },
    },
    compoundVariants: [
      {
        type: "tooltip",
        variant: "neutral",
        className:
          "data-[state=delayed-open]:bg-secondary-button-bg-hover data-[state=instant-open]:bg-secondary-button-bg-hover data-[state=open]:bg-secondary-button-bg-hover",
      },
      {
        type: "tooltip",
        variant: "positive",
        className:
          "data-[state=delayed-open]:bg-positive-bg/25 data-[state=instant-open]:bg-positive-bg/25 data-[state=open]:bg-positive-bg/25",
      },
      {
        type: "tooltip",
        variant: "warning",
        className:
          "data-[state=delayed-open]:bg-warning-bg/25 data-[state=instant-open]:bg-warning-bg/25 data-[state=open]:bg-warning-bg/25",
      },
      {
        type: "tooltip",
        variant: "negative",
        className:
          "data-[state=delayed-open]:bg-negative-bg/25 data-[state=instant-open]:bg-negative-bg/25 data-[state=open]:bg-negative-bg/25",
      },
    ],
  },
);

type Props = OverrideProps<ComponentPropsWithoutRef<typeof UnstyledButton>, VariantProps<typeof labelButtonStyles>> & {
  icon?: ReactNode;
};

const LabelButton = forwardRef<ElementRef<typeof UnstyledButton>, Props>(
  ({ className, variant = "neutral", children, icon, type = "button", ...props }, ref) => {
    return (
      <UnstyledButton className={cn(labelButtonStyles({ variant, type }), className)} {...props} ref={ref}>
        {children}
        {icon && <div className="shrink-0 *:size-4">{icon}</div>}
      </UnstyledButton>
    );
  },
);
LabelButton.displayName = "LabelButton";

export { LabelButton };
