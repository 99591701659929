import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { TooltipQuestionIcon } from "@/components/icons";
import { Leverage } from "@/entities/accounts/leverage";
import { cn } from "@/shared/styles";
import { DataList, Tooltip } from "@/shared/ui";

import { usePlaceOrderDetails } from "./use-details";

type Props = Parameters<typeof usePlaceOrderDetails>[0] & {
  className?: string;
};

const PlaceOrderDetails: FC<Props> = ({ className, ...props }) => {
  const { t } = useTranslation();

  const { currency } = props;

  const { leverage, margin, tradeAmount } = usePlaceOrderDetails(props);

  return (
    <DataList className={cn("px-2", className)}>
      <DataList.Item
        label={
          <DataList.Label
            tooltip={
              <Tooltip content={t("terminal.place-order.details.margin.description")}>
                <TooltipQuestionIcon size="sm" />
              </Tooltip>
            }
          >
            {t("terminal.place-order.details.margin.title")}
          </DataList.Label>
        }
        value={
          <DataList.Value>
            {margin ? (
              <NumberFormat value={margin.value} decimalScale={margin.decimalScale} currency={currency} />
            ) : (
              "–"
            )}
          </DataList.Value>
        }
      />
      <DataList.Item
        label={<DataList.Label>{t("terminal.place-order.details.leverage")}</DataList.Label>}
        value={
          <DataList.Value>
            <Leverage leverage={leverage} />
          </DataList.Value>
        }
      />
      <DataList.Item
        label={<DataList.Label>{t("terminal.place-order.details.trade-amount")}</DataList.Label>}
        value={
          <DataList.Value>
            {tradeAmount ? (
              <NumberFormat value={tradeAmount.value} decimalScale={tradeAmount.decimalScale} currency={currency} />
            ) : (
              "–"
            )}
          </DataList.Value>
        }
      />
    </DataList>
  );
};

export { PlaceOrderDetails };
