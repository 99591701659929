import type { FC } from "react";
import { useNavigate } from "react-router-dom";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { LanguageSwitcherButton, LanguageSwitcherContainer } from "@/features/language-switcher";
import { useDisclosure } from "@/hooks/disclosure.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { getTerminalRoute } from "@/routes/terminal.routes";
import { Button } from "@/shared/ui";

import { ProfileInfo } from "../profile-info/profile-info";
import { Logo } from "./logo";

type Props = {
  isSurveyCompleted: boolean;
  isSurveyMandatory: boolean;
  email: string;
  activeStepCount?: number;
  from: string | null;
};

const OnboardingHeader: FC<Props> = ({ isSurveyCompleted, isSurveyMandatory, email, activeStepCount, from }) => {
  const [languageSwitcherOpened, { open: openLanguageSwitcher, onOpenChange: onOpenChangeLanguageSwitcher }] =
    useDisclosure();
  const [profielInfoOpened, { onOpenChange: onOpenChangeProfileInfo }] = useDisclosure();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const isCloseButtonVisible = !isSurveyMandatory || isSurveyCompleted;

  const closeButtonAction = () => {
    activeStepCount && track(amplitudeEvents.kyc.close(activeStepCount));

    if (from) {
      navigate(from);
      return;
    }
    navigate(getTerminalRoute(""));
  };

  return (
    <div className="sticky top-0 z-10 mx-auto flex w-full max-w-[980px] items-center justify-between bg-white px-4 py-5">
      <div>
        <Logo asLink={!(isSurveyMandatory && !isSurveyCompleted)} />
      </div>

      <div className="flex items-center gap-6">
        <LanguageSwitcherButton onOpen={openLanguageSwitcher} />
        <LanguageSwitcherContainer onOpenChange={onOpenChangeLanguageSwitcher} open={languageSwitcherOpened} />

        {!isCloseButtonVisible && (
          <ProfileInfo email={email} onOpenChange={onOpenChangeProfileInfo} open={profielInfoOpened} />
        )}

        {isCloseButtonVisible && (
          <div className="w-[102px]">
            <Button variant="secondary" fullWidth size="sm" onClick={closeButtonAction}>
              {t("button.skip")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export { OnboardingHeader };
