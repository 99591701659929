import { type FC, useState } from "react";

import { ChangeAccountPasswordContainer } from "@/entities/accounts/change-password/container";
import { type CreateTradingAccountResult } from "@/services/openapi";

import { CreateAccountMT4Details } from "./details";

type Step = "details" | "change-password";

type Props = {
  accountResult: CreateTradingAccountResult;
  onClose: () => void;
  onCloseWithRedirect: () => void;
};

const CreateAccountResultMT4Container: FC<Props> = ({ accountResult, onClose, onCloseWithRedirect }) => {
  const [step, setStep] = useState<Step>("details");

  return (
    <>
      {step === "details" && (
        <CreateAccountMT4Details
          accountResult={accountResult}
          onClose={onClose}
          onPasswordSelect={() => setStep("change-password")}
        />
      )}
      {step === "change-password" && (
        <ChangeAccountPasswordContainer account={accountResult} onApply={onCloseWithRedirect} />
      )}
    </>
  );
};

export { CreateAccountResultMT4Container };
