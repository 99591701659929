import { DialogName, useDialog } from "@/hooks/dialog.hook";
import { Dialog } from "@/shared/ui";

import { VerificationDialogContent } from "./verification-dialog-content";

const VerificationDialog = () => {
  const { onClose, open } = useDialog(DialogName.VERIFICATION);

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <Dialog.Content>
        <VerificationDialogContent />
      </Dialog.Content>
    </Dialog>
  );
};

export { VerificationDialog };
