import type { FC } from "react";

import { AccountDetailsSkeleton } from "../account-details/skeleton";
import { ChartLoader } from "../chart/loader";
import { ChartSymbolsSkeleton } from "../chart-symbols/skeleton";
import { DepositButtonSkeleton } from "../deposit/skeleton";
import { PlaceOrderSkeleton } from "../place-order/skeleton";
import { TerminalMobileFrame } from "../terminal-mobile.frame";
import { MobileTradingTablesSkeleton } from "../trading-tables/mobile/skeleton";

const TerminalMobileSkeleton: FC = () => {
  return (
    <TerminalMobileFrame
      chartSymbolsSlot={<ChartSymbolsSkeleton />}
      accountDetailsSlot={<AccountDetailsSkeleton />}
      depositButtonSlot={<DepositButtonSkeleton />}
      chartSlot={<ChartLoader />}
      placeOrderSlot={<PlaceOrderSkeleton />}
      tablesSlot={<MobileTradingTablesSkeleton />}
    />
  );
};

export { TerminalMobileSkeleton };
