import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, Link } from "react-router-dom";

import { useLocationPath } from "@/app/hooks";
import { IconArrowTransfer, IconChart, IconDeposit2, IconWithdraw2 } from "@/domains/icons";
import { cabinetRoutes, getDepositRoute, getWithdrawalRoute } from "@/routes/cabinet.routes";
import { getTerminalRoute } from "@/routes/terminal.routes";
import { type TradingAccount, TradingServerPlatform } from "@/services/openapi";
import { TileButton } from "@/shared/ui";

type Props = {
  account: TradingAccount;
};

const AccountRealButtons: FC<Props> = ({ account }) => {
  const { t } = useTranslation();

  const { id, platform, actions } = account;

  const path = useLocationPath();

  const { canTransferMoney } = actions!;

  return (
    <>
      <TileButton variant="secondary" icon={<IconDeposit2 />} asChild>
        <Link to={{ pathname: getDepositRoute(id!), search: createSearchParams({ from: path }).toString() }}>
          {t("accounts.buttons.deposit")}
        </Link>
      </TileButton>
      <TileButton variant="secondary" icon={<IconWithdraw2 />} asChild>
        <Link to={{ pathname: getWithdrawalRoute(id!), search: createSearchParams({ from: path }).toString() }}>
          {t("accounts.buttons.withdraw")}
        </Link>
      </TileButton>
      {canTransferMoney && (
        <TileButton variant="secondary" icon={<IconArrowTransfer />} asChild>
          <Link to={cabinetRoutes.transfer}>{t("accounts.buttons.transfer")}</Link>
        </TileButton>
      )}
      {platform == TradingServerPlatform.MetaTrader5 && (
        <TileButton variant="secondary" icon={<IconChart />} asChild>
          <Link to={getTerminalRoute(id!)}>{t("accounts.buttons.trade")}</Link>
        </TileButton>
      )}
    </>
  );
};

export { AccountRealButtons };
