import type { ChangeEvent } from "react";
import { forwardRef, useCallback } from "react";
import { PatternFormat } from "react-number-format";

import { InputBase } from "../InputBase/InputBase";
import type { InputPhoneProps } from "./InputPhone.types";

/**
 * @deprecated
 */
export const InputPhone = forwardRef<HTMLInputElement, InputPhoneProps>(
  (
    {
      format,
      className,
      children,
      onChange: originalOnChange,
      // loading,
      iconStart,
      iconEnd,
      value,
      inputSize,
      dataTest = "InputText",
      ...otherProps
    },
    ref,
  ) => {
    const onChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        originalOnChange && originalOnChange(newValue, e);
      },
      [originalOnChange],
    );

    return (
      <PatternFormat
        format={format}
        getInputRef={ref}
        className={className}
        dataTest={dataTest}
        onChange={onChange}
        value={value}
        inputSize={inputSize}
        {...otherProps}
        type="text"
        customInput={InputBase}
      />
    );
  },
);
InputPhone.displayName = "InputPhone";
