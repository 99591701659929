import type { FC } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useControlledField } from "@/app/form";
import { TextInput } from "@/shared/ui";

import { ProfileFields } from "./profile.form";

const LastnameField: FC = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const [lastNameField, { invalid: isLastNameInvalid, errorMessage }] = useControlledField({
    name: ProfileFields.LAST_NAME,
    control,
    rules: {
      required: t("onboarding.profile.last-name-error")!,
    },
  });

  return (
    <TextInput
      {...lastNameField}
      label={t("onboarding.profile.last-name-placeholder")}
      descriptor={errorMessage}
      invalid={isLastNameInvalid}
    />
  );
};

export { LastnameField };
